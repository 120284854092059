import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { tableProductsTraslate } from '../../constants/TablesOptimize';
import { packageEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { OptimizeTemplateModalSelectComponent } from '../optimize-template-modal-select/optimize-template-modal-select.component';
import { OPTIMIZE_LEVELS_FILTER_URL } from '../../constants/OptimizeConstants';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { Store } from '@ngrx/store';
import { selectCloseModals } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { closeModalsDone, fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';

@Component({
  selector: 'app-optimize-template-modal-new-optimize',
  templateUrl: './optimize-template-modal-new-optimize.component.html',
  styleUrls: ['./optimize-template-modal-new-optimize.component.scss']
})
export class OptimizeTemplateModalNewOptimizeComponent implements OnInit {

  public tableHead = tableProductsTraslate
  public termConstants = termConstants
  products:any
  bus:any
  optimize:any[] = []
  slotOptId:number = 0
  alert:boolean = false
  constructor( 
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public store:Store<AppState>
    ) { 
      this.store.select(selectCloseModals).subscribe(close => {
        if(close){
          this.activeModal.close()
          this.store.dispatch(fetchList())
          this.store.dispatch(closeModalsDone())
        }
      })
    }

  ngOnInit(): void {
  }
  showProducts(item:any){
    this.products = item.appStock
  }
  sendProduct(data:packageEntity[], index:number, amount:number){
    if(amount > 0){
      let cos = this.optimize.find(el => el.id ===data[index].id)
      this.bus = this.optimize.includes(data[index])
      if(cos===undefined){
        this.products[index].active = true
        this.optimize.push(data[index])
      }else{
        let pos = this.optimize.indexOf(data[index])
        this.products[index].active = false
        this.optimize.splice(pos)
      }
    }
  }
  addSlotId(id:number){
    this.slotOptId = id
    this.optimize.push({slot_id:id})  
  }
  openModalSelectOpers(){
    if(this.optimize.length===0){
      this.alert = true
    }else{
      const modalRef = this.modalService.open(OptimizeTemplateModalSelectComponent,{                                                                
        size: 'sm'
      });
      modalRef.componentInstance.slotsOptimize = this.optimize
    }
  }
  openModalConfirm(){
    
  }
}
