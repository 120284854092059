import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit {

  @Input() nameDefault: string = '';
  @Input() list: any[] = [];
  @Input() colorText: string = '';
  model={
    id:''
  }
  @Output() filterByKeyEvent = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }
  onChance(){
    this.filterByKeyEvent.emit(this.model.id)
  }

}
