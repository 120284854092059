export const tableProvider = [
    {
        title: 'Nombre',
        col: 'col'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
]
export const tableProduct = [
    {
        title: 'Nombre',
        col: 'col'
    },
    {
        title: 'Codigo',
        col: 'col'

    },
    {
        title: 'Acciones',
        col: 'col'
    },
    
]
export const tablePacking = [
    {
        title: 'Nombre',
        col: 'col'
    },
    {
        title: 'Código',
        col: 'col'

    },
    
    {
        title: 'Cantidad',
        col: 'col'

    },
    {
        title: 'Acciones',
        col: 'col'
    },
]
export const tableFamilies = [
    {
        title: 'Nombre',
        col: 'col'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
]
export const tableClients = [
  {
      title: 'Nombre',
      col: 'col'
  },
  {
    title: 'Acciones',
    col: 'col'
},
]
export const tableHeadquarters = [
  {
      title: 'Nombre',
      col: 'col'
  },
  {
    title: 'Acciones',
    col: 'col'
},
]
export const tableCellar = [
    {
        title: 'Nombre',
        col: 'col'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
  ]
  export const tableType = [
    {
        title: 'Nombre',
        col: 'col'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
  ]
  export const tableZones = [
    {
        title: 'Nombre',
        col: 'col'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
  ]
  export const tableVehicle = [
    {
        title: 'Nombre',
        col: 'col'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
  ]
  export const tableDistribution = [
    {
        title: 'Nombre',
        col: 'col'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
  ]


export const HEAD_CONST = {
    provider: tableProvider,
    product: tableProduct,
    packing: tablePacking,
    family: tableFamilies,
    client: tableClients,
    headquarter: tableHeadquarters,
    cellar: tableCellar,
    type: tableType,
    zone:tableZones,
    vehicle: tableVehicle,
    distribution:tableDistribution

  }