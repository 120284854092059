<div class="card p-3 bg-light">
    <div class="d-flex justify-content-between">
        <app-title [title]="'Operarios'" [class]="'fw-bolder'" ></app-title>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
   
    <app-title [title]="'Selecciona el operario'" class="mb-1"></app-title>
    <div class="col-3">
        <app-input-search (search)="updateList($event)"></app-input-search>
    </div>
    <app-card-operator (sendUsers)="setUsers($event)"></app-card-operator>  
    <div class="d-flex justify-content-center mt-4">
        <button class="btn button-blue btn-sm  me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="sendOperators()">{{termConstants.modal.select}}</button>
    </div> 
</div>
