import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { STOCK_UPDATE_FILE_URL } from '../../constants/ApiConstants';

@Component({
  selector: 'app-stock-template-modal-stock-file-update',
  templateUrl: './stock-template-modal-stock-file-update.component.html',
  styleUrls: ['./stock-template-modal-stock-file-update.component.scss']
})
export class StockTemplateModalStockFileUpdateComponent implements OnInit {
  fileUpload: any
  title: string = 'Actualizar slots'
  loading: boolean = false

  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService: ApiService,
    public store: Store<AppState>,
  ) { 
    this.fileUpload = null
  }

  ngOnInit(): void {
    
  }

  setFile($event: any){
    this.fileUpload = $event.target.files[0]
  }

  updateFile(){
    let formData = new FormData()
    formData.append('fileUpload', this.fileUpload)

    this.loading = true
    this.apiService.post(STOCK_UPDATE_FILE_URL, formData).subscribe(() => {
      this.activeModal.dismiss()
      this.loading = false
    }, err => {
      this.loading = false
    })
  }
}
