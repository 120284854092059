<nav class="navbar navbar-expand-lg bg-white border-bottom border-danger shadow">
  <div class="container-fluid">
    <a class="navbar-brand">
      <img src="./assets/master_logo.png" class="w-75">
    </a>
    <div class="d-flex align-items-center">
      <div class="">
        <div class="form-group">
          <select class="form-select form-select-sm">
            <option value="" selected="selected">Bodega</option>
              <option *ngFor="let item of listBod" value="{{item.id}}">
                {{item.name}}
              </option>
          </select>
        </div>
      </div>
      <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
        <button type="button" class="btn btn-white me-2 border-0" (click)="$event.stopPropagation(); myDrop.open();">
          <div class="d-flex">
            <div>
              <img class="rounded-circle p-1" src="{{dateUser?.imageUrl}}" width="50" height="50">
            </div>              
            <div>
              <app-title [title]="dateUser?.name + dateUser?.lastname" [class]="'fw-bolder'"></app-title>
              <app-subtitle [title]="dateUser?.userProfiles?.name"></app-subtitle>
            </div>
          </div> 
        </button>
        <div class="shadow text-center p-4 mt-4" ngbDropdownMenu aria-labelledby="dropdownManual">
          <img class="rounded-circle p-1" src="{{dateUser?.imageUrl}}" width="80" height="80">
          <div class="ms-3">
            <app-title [title]="dateUser?.name + dateUser?.lastname" [class]="'fw-bolder'"></app-title>
            <app-subtitle [title]="dateUser?.userProfiles?.name"></app-subtitle>
          </div>
          <div class="mb-3 mt-3">
            <button type="button" class="btn btn-outline-danger" (click)="openModalEditUser(dateUser, dateUser.id)" (click)="myDrop.close();">Editar mi perfil</button>
          </div>
          <div>
            <button type="button" class="btn btn-outline-danger" (click)="closeProfile()" (click)="myDrop.close();">Cerrar sesión</button>
          </div>         
        </div>
      </div>
    </div>
  </div>
</nav>

