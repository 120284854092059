import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StockRoutingModule } from './stock-routing.module';
import { FeatureListProductComponent } from './feature-list-product/feature-list-product.component';
import { AppModule } from 'src/app/app.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { FeatureAdminSlotComponent } from './feature-admin-slot/feature-admin-slot.component';
import { StockTemplateFilterAdminComponent } from './domain/templates/stock-template-filter-admin/stock-template-filter-admin.component';
import { StockTemplateListSlotsComponent } from './domain/templates/stock-template-list-slots/stock-template-list-slots.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StockTemplateFilterListProductsComponent } from './domain/templates/stock-template-filter-list-products/stock-template-filter-list-products.component';
import { ProductCardComponent } from './domain/organisms/product-card/product-card.component';
import { StockTemplateListProductsComponent } from './domain/templates/stock-template-list-products/stock-template-list-products.component';
import { SubSlotCardComponent } from './domain/organisms/sub-slot-card/sub-slot-card.component';
import { StockTemplateModalSlotComponent } from './domain/templates/stock-template-modal-slot/stock-template-modal-slot.component';
import { NavModule } from '../nav/nav.module';
import { SlotCardAccordeonComponent } from './domain/organisms/slot-card-accordeon/slot-card-accordeon.component';
import { StockTemplateModalNewSlotComponent } from './domain/templates/stock-template-modal-new-slot/stock-template-modal-new-slot.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StockModalMaxTypesComponent } from './domain/templates/stock-modal-max-types/stock-modal-max-types.component';
import { StockTemplateModalStockFileUpdateComponent } from './domain/templates/stock-template-modal-stock-file-update/stock-template-modal-stock-file-update.component';


@NgModule({
  declarations: [
    FeatureListProductComponent,
    FeatureAdminSlotComponent,
    StockTemplateFilterAdminComponent,
    StockTemplateListSlotsComponent,
    StockTemplateFilterListProductsComponent,
    ProductCardComponent,
    StockTemplateListProductsComponent,
    SubSlotCardComponent,
    StockTemplateModalSlotComponent,
    SlotCardAccordeonComponent,
    StockTemplateModalNewSlotComponent,
    StockModalMaxTypesComponent,
    StockTemplateModalStockFileUpdateComponent
  ],
  imports: [
    CommonModule,
    StockRoutingModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NavModule
  ],
  exports: [
  ]
})
export class StockModule { }
