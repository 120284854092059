<div class="card p-4">
  <div class="d-flex justify-content-between">
      <h4>{{title}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <app-title [title]="'Sedes'"></app-title>
  <form [formGroup]="idForm" class="row">
      <div class="form-group col-6">
          <label class="control-label">Nombre</label>
          <input class="form-control" type="text" formControlName="name">
      </div>
      <div>
        <label class="control-label">Cliente</label>
        <select class="form-select form-select-sm" formControlName="app_clients_id">
          <option [ngValue]="null" selected>{{cli}}</option>
          <option [ngValue]="item.id" *ngFor="let item of clients">{{ item.name }}</option>
        </select>
      </div>
      <div class="p-3">
        <label>Direccion</label>
        <app-map-marker [model]="model" (sendPosition)="addLatLng($event)"></app-map-marker>
      </div>     
  </form>
  <div class="d-flex align-items-center justify-content-center mt-3">
      <button class="btn button-blue btn-sm" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
      <button class="btn button-red-strong btn-sm" (click)="sendProviders()">Guardar</button>
  </div>

</div>