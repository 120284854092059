import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NavState } from 'src/app/modules/nav/domain/state/NavState';
import { selectCenterContent } from 'src/app/modules/nav/domain/state/nav.selector';
import { terms } from '../../constants/AuthConstants';
import { AuthService } from '../../services/auth.service';
import { LocalService } from '../../services/local.service';
import { LoginResponse } from '../../entities/LoginResponse';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';
@Component({
  selector: 'app-auth-form-login',
  templateUrl: './auth-form-login.component.html',
  styleUrls: ['./auth-form-login.component.scss']
})
export class AuthFormLoginComponent implements OnInit {
  error:string = ''
  loading=false
  showNavSection: any
  public terms = terms;
  validation_messages = {
    email: [
      {type: "required", message: "El email es requerido"},
      {type: "pattern", message: "No es un email valido"}
    ],
    password: [
      {type: "required", message: "El password es requerido"},
      {type: "minlength", message: "Minimo 5 letras"}
    ]
    
  };

  loginForm!: FormGroup;
  errorMessage:string = '';
  centerContent$: any
  constructor( 
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public store : Store<NavState>,
    public router: Router,
    public localService : LocalService,
    public apiService:ApiService

    ) { 
     this.loginForm = this.formBuilder.group({
      username: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
        ])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(5)

        ])
      ),
    
     }) 
    }

  
  ngOnInit(): void {
    localStorage.clear()
    this.store.select(selectCenterContent).subscribe(data => this.showNavSection = data)
  }

    
  loginUser(){
    this.loading=true
    this.authService.loginUser(this.loginForm.value).subscribe(response => {
      let responseAux = <LoginResponse>response
      localStorage.setItem('token', responseAux.token)
      this.router.navigate(['/usuarios/admin'])
      this.loading=false
    },error=>{
      if(error.status===401){
        this.loading=false
        this.error = 'Fallo al ingresar. El email o la contraseña que has ingresado es incorrecto. Por favor intentalo nuevamente.'
      }
      if(error.status===0){
        this.loading=false
        this.error = 'No hay datos del sistema. Por favor intentalo nuevamente en unos minutos.'
      }
      if(error.status===500){
        this.loading=false
        this.error = 'No se pude loguear en este momento. Porfavor, comuniquese con el administrador, intente nuevamente mas tarde.'
      }
    
    })
    
  }
}