import { ActionReducerMap } from "@ngrx/store";
import { AuthState } from "src/app/modules/auth/domain/entities/AuthState";
import { authReducer } from "src/app/modules/auth/domain/state/auth.reducer";
import { CodesState } from "src/app/modules/codes/domain/state/CodesState";
import { codesReducer } from "src/app/modules/codes/domain/state/codes.reducers";
import { IncomeState } from "src/app/modules/income/domain/state/IncomeState";
import { incomeReducer } from "src/app/modules/income/domain/state/income.reducers";
import { navStateEntity } from "src/app/modules/nav/domain/entities/navStateEntity";
import { navReducer } from "src/app/modules/nav/domain/state/nav.reducer";
import { OfficesState } from "src/app/modules/offices/domain/state/OfficesState";
import { officesReducer } from "src/app/modules/offices/domain/state/offices.reducers";
import { OptimizeState } from "src/app/modules/optimize/domain/entities/OptimizeState";
import { optimizeReducer } from "src/app/modules/optimize/domain/state/optimize.reducers";
import { ProductsState } from "src/app/modules/products/domain/entities/ProductsState";
import { productsReducer } from "src/app/modules/products/domain/state/products.reducers";
import { StockState } from "src/app/modules/stock/domain/state/StockState";
import { stockReducer } from "src/app/modules/stock/domain/state/stock.reducers";
import { UsersState } from "src/app/modules/users/domain/entities/UsersState";
import { usersReducer } from "src/app/modules/users/domain/state/users.reducers";
import { CommonState } from "../entities/CommonState";
import { commonReducer } from "./common.reducers";

export interface AppState {

    nav: navStateEntity,
    auth: AuthState,
    codes: CodesState,
    income: IncomeState,
    offices: OfficesState,
    optimize: OptimizeState,
    products: ProductsState,
    stock: StockState,
    users: UsersState,
    common: CommonState,
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> ={
    nav: navReducer,
    auth: authReducer,
    codes: codesReducer,
    income: incomeReducer,
    offices: officesReducer,
    optimize: optimizeReducer,
    products: productsReducer,
    stock: stockReducer,
    users: usersReducer,
    common: commonReducer,
}