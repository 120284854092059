import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PRODUCT_CATEGORIES_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { ApiService } from '../../services/api.service';
import { AppState } from '../../state.ts/app.state';
import { fetchList, rechargePage } from '../../state.ts/common.actions';

@Component({
  selector: 'app-modal-edit-categories',
  templateUrl: './modal-edit-categories.component.html',
  styleUrls: ['./modal-edit-categories.component.scss']
})
export class ModalEditCategoriesComponent implements OnInit {

  idForm!:FormGroup
  @Input() item?:any
  title:string= ''
  @Input() id:number = 0;
  loading$ = false
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService: ApiService,
    public store:Store<AppState>,
    public router: Router
  ) { 
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),

    })
  }

  ngOnInit(): void {
    this.title=this.item ? 'Edición': 'Crear'
    if(this.item){
      this.idForm.setValue({
        name:this.item.name,
      })
    }
  }
  sendProviders(){
    if(this.item){
      this.apiService.put(PRODUCT_CATEGORIES_URL + this.item.id, this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
      
    }else{
      this.apiService.post(PRODUCT_CATEGORIES_URL, this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
    }
    this.store.dispatch(rechargePage({loading:this.loading$}))

   
  }

}