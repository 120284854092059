import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { stock } from 'src/app/modules/stock/domain/constants/StockConstants';
import { stockListParamsEmpty, stockListParamsEntity } from 'src/app/modules/stock/domain/entities/StockListParamsEntity';
import { updateStockListParams } from 'src/app/modules/stock/domain/state/stock.actions';
import { OPTIMIZE_CYCLE_URL, optimize } from '../../constants/OptimizeConstants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';

@Component({
  selector: 'app-optimize-inventory-card-for-slot',
  templateUrl: './optimize-inventory-card-for-slot.component.html',
  styleUrls: ['./optimize-inventory-card-for-slot.component.scss']
})
export class OptimizeInventoryCardForSlotComponent implements OnInit {
  public optimize = optimize
  public stock = stock
  order:any
  dateSlotsA:any
  dateSlotsB:any
  dateSlotsC:any
  dateSlotsD:any
  listParams: stockListParamsEntity= stockListParamsEmpty
  constructor(
    private store:Store<AppState>,
    public modalService:NgbModal,
  ) { }

  ngOnInit(): void {
  }
  addIdSlot(item:any){
    this.order = item.id

  }
  openModalAlert(){
    const modalRef = this.modalService.open(ModalAlertComponent, {
      size: 'sm'
    });
    modalRef.componentInstance.content = 'Se va a iniciar la optimización ¿Desea continuar?', 
    modalRef.componentInstance.item = {
      app_slots_id: this.order
    }
    modalRef.componentInstance.buttonMain = 'Continuar'
    modalRef.componentInstance.url = OPTIMIZE_CYCLE_URL
  }
  filterPackage($event:any){
    
  }
  filterA(){
    this.store.dispatch(updateStockListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: 'A'
      }
    }))
  }
  filterB(){
    this.store.dispatch(updateStockListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: 'B'
      }
    }))
  }
  filterC(){
    this.store.dispatch(updateStockListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: 'C'
      }
    }))
  }
  filterD(){
    this.store.dispatch(updateStockListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: 'D'
      }
    }))
  }

}
