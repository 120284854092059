<app-title [title]="'Lista de productos'"  [fs]="'3 fw-bolder mb-3'"></app-title> 
<div class="d-flex">
      <app-input-button></app-input-button>
      <app-dropdown
          class="ms-3"
          [list]="listCat"
          [nameDefault]="'Categorias'"
          (searchInput)="filterKeyCat($event)"
          (searchId)="updateKeyCat($event)"
          (searchAll)="updateAllCat()">
      </app-dropdown>
      <app-dropdown
      class="ms-2"
      [list]="listBod"
      [nameDefault]="'Bodegas'"
      (searchInput)="filterKeyBod($event)"
      (searchId)="updateKeyBod($event)"
      (searchAll)="updateAllBod()">
      </app-dropdown>
</div>