import { Component, Input, OnInit } from '@angular/core';
import { stock } from 'src/app/modules/stock/domain/constants/StockConstants';

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
})
export class SlotComponent implements OnInit {

  public stock = stock
  @Input() value:any
  constructor() { }

  ngOnInit(): void {
  }

}
