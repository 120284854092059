import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { optimize } from '../../constants/OptimizeConstants';
import { TRANSFERED_EMPTY } from '../../constants/OptimizeEmpty';
import { transferedEntity } from '../../entities/OptimizeEntities';
import { OptimizeTemplateModalSelectComponent } from '../../templates/optimize-template-modal-select/optimize-template-modal-select.component';

@Component({
  selector: 'app-optimize-transfered-card',
  templateUrl: './optimize-transfered-card.component.html',
  styleUrls: ['./optimize-transfered-card.component.scss']
})
export class OptimizeTransferedCardComponent implements OnInit {

  public optimize = optimize
  @Input() dateTransfered:transferedEntity= TRANSFERED_EMPTY
  constructor(public modalService:NgbModal) { }

  ngOnInit(): void {
  }
  openModalSelect(){
    const modalRef = this.modalService.open(OptimizeTemplateModalSelectComponent,{
      size: 'sm'
    })
  }

}
