import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-transfered',
  templateUrl: './feature-transfered.component.html',
  styleUrls: ['./feature-transfered.component.scss']
})
export class FeatureTransferedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
