import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AREA_FILTER_URL, PROFILE_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { users } from '../../constants/UsersConstants';
import { profileEntity } from '../../entities/UsersEntities';
import { selectProfilesListParams } from '../../state/users.selectors';
import { UsersTemplateModalNewprofileComponent } from '../../templates/users-template-modal-newprofile/users-template-modal-newprofile.component';

@Component({
  selector: 'app-card-profile',
  templateUrl: './card-profile.component.html',
  styleUrls: ['./card-profile.component.scss']
})
export class CardProfileComponent implements OnInit {
  public users = users
  @Input() dateProfile:profileEntity[]=[]

  loading:boolean = false
  constructor(
    public modalService:NgbModal,
    private apiService:ApiService,
    private store:Store<AppState>
  ) { }

  ngOnInit(): void {
   
    this.store.select(selectProfilesListParams).subscribe(params => {
      this.apiService.post(PROFILE_URL+'filter',params).subscribe((data:any) => {
        this.dateProfile = <profileEntity[]>data
        this.loading = true
      })
    })
  }
  openModalNewProfile(item:any) {
    const modalRef = this.modalService.open(UsersTemplateModalNewprofileComponent,{
      size: 'lg'
    });
    modalRef.componentInstance.item = item
  }

}
