import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureForgotPasswordComponent } from './feature-forgot-password/feature-forgot-password.component';
import { FeatureLoginComponent } from './feature-login/feature-login.component';

const routes: Routes = [
  { path: 'auth', children: [
    { path: 'login', component: FeatureLoginComponent },    
    { path: 'recuperar-clave', component: FeatureForgotPasswordComponent },
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
