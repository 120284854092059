import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-input-password',
  templateUrl: './form-input-password.component.html',
  styleUrls: ['./form-input-password.component.scss']
})
export class FormInputPasswordComponent implements OnInit {

  @Input() title:string = '';
  @Input() colorText:string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
