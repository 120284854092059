import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-codes-asign',
  templateUrl: './feature-codes-asign.component.html',
  styleUrls: ['./feature-codes-asign.component.scss']
})
export class FeatureCodesAsignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
