<ul class="p-0 pt-3 vh-100" >
  <li *ngIf="!cargueMenu">
    cargando...
  </li>
  <li *ngIf="cargueMenu">
    <ngb-accordion [closeOthers]="true" >
      <ngb-panel id="{{i}}" *ngFor="let item of sections;index as i" >
        <ng-template ngbPanelHeader let-opened="opened" >
          <button class="d-flex align-items-center bg-{{bg}} w-100 p-3" *ngIf="userProfile===item.profile || userProfile===item.profile2" [routerLink]="item.url" ngbPanelToggle>
            <a>
              <div>
                <app-icon-text [src]="item.img" [title]="item.title" [icon]="item.icon" [colorText]="'white fs-5'"></app-icon-text>
              </div>
            </a>    
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <button class="text-start bg-{{item.bg1}} w-100"  [routerLink]="item.url1" *ngIf="item.subtitle1!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle1" [colorText]="'white'"></app-title>
              </div>
            </a> 
          </button>
          <button class="text-start bg-{{item.bg2}} w-100"  [routerLink]="item.url2"  *ngIf="item.subtitle2!== undefined" >
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle2" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>
          <button class="text-start bg-{{item.bg3}} w-100"  [routerLink]="item.url3" *ngIf="item.subtitle3!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle3" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>
          <button class="text-start bg-{{bg4}} w-100"  [routerLink]="item.url4" *ngIf="item.subtitle4!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle4" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>
          <button class="text-start bg-{{bg5}} w-100"  [routerLink]="item.url5" *ngIf="item.subtitle5!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle5" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>
          <button class="text-start bg-{{bg6}} w-100"  [routerLink]="item.url6" *ngIf="item.subtitle6!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle6" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>
          <button class="text-start bg-{{bg7}} w-100"  [routerLink]="item.url7" *ngIf="item.subtitle7!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle7" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>
          <button class="text-start bg-{{bg8}} w-100"  [routerLink]="item.url8" *ngIf="item.subtitle8!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle8" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>
          <button class="text-start bg-{{bg9}} w-100"  [routerLink]="item.url9" *ngIf="item.subtitle9!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle9" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>
          <button class="text-start bg-{{bg10}} w-100"  [routerLink]="item.url10" *ngIf="item.subtitle10!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle10" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>
          <button class="text-start bg-{{bg11}} w-100"  [routerLink]="item.url11" *ngIf="item.subtitle11!== undefined">
            <a >
              <div class="p-2 ps-4">
                <app-title [title]="item.subtitle11" [colorText]="'white'"></app-title>
              </div>
            </a>  
          </button>  
        </ng-template>
      </ngb-panel>
    </ngb-accordion>

  </li>
</ul>
