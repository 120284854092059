export const optimize = {
    optimizeCard: {
        code: 'Código',
        units: 'Unidades'
    },
    inventoryCard: {
        boxs: 'Cajas',
        imgBox: './assets/caja-out.png',
        sachets: 'Sachets',
        imgSachets: './assets/sachets-out.png',
        packing: 'Empaque',
        imgPacking: './assets/empaque-out.png'
    },
    levelCard: {
      withoutStarting: 'Sin iniciar',
      optimized: 'Optimizando',
      finalized: 'Finalizado',
      slotOpt: 'Slot a optimizar',
      optimize: 'Optimizado',
      slotObj: 'Slot objetivo',
      result: 'Resultado',
      productsTraslate: 'Productos a trasladar',
      suggestions: 'Sugerencias de optimización'
    },
    slotCard: {
        level: 'Nivel:'
    },
    transferedCard: {
        code: 'Código',
        product: 'Producto',
        units: 'Unidades',
        boxs: 'Cajas',
        imgBoxs: './assets/caja-out.png',
        sachets: 'Sachets',
        imgSachets: './assets/sachets-out.png',
        packing: 'Empaque',
        imgPacking: './assets/empaque-out.png',
        slot: 'Slot',
        lastMovement: 'Último movimiento',
        ago: 'Hace',
        transferedObsolet: 'Traslado obsoletos'
    },
    filter: {
        start: 'Iniciar',
        family: 'Familia',
        slots: 'Slots',
        subSlots: 'Sub-slots',
        newOptimize: 'Crear optimización',
        category: 'Categoría'
    }
}
export const familiesProducts = [
    {
        title: 'Elementos de proteccion personal',
        src: './assets/Grupo 98.png'
    },
    {
        title: 'Elementos de proteccion personal',
        src: './assets/Grupo 428.png'
    },
    {
        title: 'Elementos de proteccion personal',
        src: './assets/Grupo 429.png'
    },
    {
        title: 'Elementos de proteccion personal',
        src: './assets/Grupo 427.png'
    },
    {
        title: 'Elementos de proteccion personal',
        src: './assets/Grupo 432.png'
    },
    {
        title: 'Elementos de proteccion personal',
        src: './assets/Grupo 433.png'
    },
]
export const OPTIMIZE_LEVELS_URL = '/optimization/levels/'
export const OPTIMIZE_LEVELS_FILTER_URL = '/optimization/levels/filter'
export const OPTIMIZE_CYCLE_URL = '/optimization/cyclic/'
export const OPTIMIZE_CYCLE_FILTER_URL = '/optimization/cyclic/filter'
export const OPTIMIZE_TRANSFERED_URL = '/optimization/obsoletes/'
export const OPTIMIZE_TRANSFERED_FILTER_URL = '/optimization/obsoletes/filter'