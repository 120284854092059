export const tableProducts = [
    {
        title: 'Codigo',
        col: 'col-1'
    },
    {
        title: 'Familia',
        col: 'col'
    },
    {
        title: 'Producto',
        col: 'col'
    },
    {
        title: 'Descripción',
        col: 'col-1'
    },
    {
      title: 'Proveedor',
      col: 'col-1'
  },
    {
        title: 'Unidades',
        col: 'col-1'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
]
export const tablePackagesModal = [
    {
        title: 'Tipo',
        col: 'col'
    },
    {
        title: 'Cantidad',
        col: 'col'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
   
]