import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor() { }
  setItem(key:string, value:string){
    localStorage.setItem(key, value)
    return localStorage.getItem(key)
  }
  getItem(key:string){
    return localStorage.getItem(key)
  }
}
