import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavRoutingModule } from './nav-routing.module';
import { NavSidebarComponent } from './domain/templates/nav-sidebar/nav-sidebar.component';
import { NavHeaderComponent } from './domain/templates/nav-header/nav-header.component';
import { AppCommonModule } from '../app-common/app-common.module';
import { SidebarItemComponent } from './domain/organism/sidebar-item/sidebar-item.component';
import { HeaderProfileComponent } from './domain/organism/header-profile/header-profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    NavSidebarComponent,
    NavHeaderComponent,
    SidebarItemComponent,
    HeaderProfileComponent
  ],
  imports: [
    CommonModule,
    NavRoutingModule,
    AppCommonModule,
    NgbModule
  
  ],
  exports: [
    NavSidebarComponent,
    NavHeaderComponent
  ]
})
export class NavModule { }
