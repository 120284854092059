import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

declare global {
  interface Window {
    initMap: () => void;
  }
}
@Component({
  selector: 'app-map-marker',
  templateUrl: './map-marker.component.html',
  styleUrls: ['./map-marker.component.scss']
})
export class MapMarkerComponent implements OnInit {
  lista:any
  @Input() model:any
  @Output() sendPosition = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
    var positionGeneral:any=[];
    let markers: google.maps.Marker[] = [];
    const loader = new Loader({
      apiKey: "AIzaSyCNgY-Uk__GuFfS-u-wCxLyWi9oC8PIW8A",
      version: "weekly",
      libraries: ["drawing"]
    });
    loader.load().then(() => {
    const myLatlng = { lat: 4.60971, lng: -74.08175 };
    const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      center: myLatlng,
       zoom: 12,
    });
    if(this.model===undefined){
      this.model = positionGeneral
      new google.maps.Marker({
        position:LatLong,
        map: map,
      })
      map.addListener("click", (event: google.maps.MapMouseEvent) => {
        deleteMarkers(),
        addMarker(event.latLng!);
      });
      map.addListener("click", addLatLng);
      function setMapOnAll(map: google.maps.Map | null) {
        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(map);
        }
      }
      function hideMarkers(): void {
        setMapOnAll(null);
      }
      function deleteMarkers(): void {
        hideMarkers();
        markers = [];
      }
      map.addListener("click", addLatLng);
      function addLatLng(event: google.maps.MapMouseEvent) {
      var latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      positionGeneral.push(latlng)
    }
      function addMarker(position: google.maps.LatLng | google.maps.LatLngLiteral) {
        const marker = new google.maps.Marker({
          position,
          map,
        });
        
      
        markers.push(marker);
      }
    }else{
      var LatLong = this.model
      this.model = positionGeneral
      const markerEdit = new google.maps.Marker({
        position:LatLong,
        map,
      });
      markers.push(markerEdit);
      setMapOnAll
      map.addListener("click", (event: google.maps.MapMouseEvent) => {
        deleteMarkers(),
        addMarker(event.latLng!);
      });
      function setMapOnAll(map: google.maps.Map | null) {
        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(map);
        }
      }
      function hideMarkers(): void {
        setMapOnAll(null);
      }
      function deleteMarkers(): void {
        hideMarkers();
        markers = [];
      }
      map.addListener("click", addLatLng);
      function addLatLng(event: google.maps.MapMouseEvent) {
      var latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      positionGeneral.push(latlng)
    }
      function addMarker(position: google.maps.LatLng | google.maps.LatLngLiteral) {
        const marker = new google.maps.Marker({
          position,
          map,
        });
        markers.push(marker);
      }
      
     
    }
   
   
   
    })
  }
  sendLatLng(){
    this.sendPosition.emit(this.model)   
  } 
}

