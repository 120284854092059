<div class="d-flex mb-3">
  <div class="me-3">
    <button class="btn button-red-strong me-2 btn-sm" (click)="openModalNew()">Crear</button>
  </div>
  <div>
      <app-input-button
        [title]="'Buscar'"
        (click)="filter()"
        [model]="filters.keyword"
        (updateModelEvent)="updateKeyword($event)"
        (filterByKeyEvent)="filterByKey()"
      ></app-input-button>
  </div>
</div>
<div class="card border-0 p-3">
  <div class="d-flex justify-content-center" *ngIf="loading">
    <div class="spinner-border text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
    <table class="table" *ngIf="!loading">
        <tr class="d-flex">
          <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
        </tr>
        <tr class="d-flex" *ngFor="let Table of list">
          <app-row-item [title]="Table.name" class="bg-white col border border-light" *ngIf="Table.name !== undefined"></app-row-item>
          <app-row-item [title]="Table.lastname" class="bg-white col border border-light" *ngIf="Table.lastname !== undefined"></app-row-item>
          <app-row-item [title]="Table.roles" class="bg-white col border border-light" *ngIf="Table.roles !== undefined"></app-row-item>
          <app-row-item [title]="Table.code" class="bg-white col border border-light" *ngIf="Table.code !== undefined"></app-row-item>
          <app-row-item [title]="Table.email" class="bg-white col border border-light" *ngIf="Table.email !== undefined"></app-row-item>
          <app-row-item [title]="Table.quantity" class="bg-white col border border-light" *ngIf="Table.quantity !== undefined"></app-row-item>
          <td class="d-flex align-items-center border border-light col">
            <div class="ms-3 me-3">
              <button class="btn button-blue btn-sm" type="button" (click)="openModalEdit(Table, Table.id)">Editar</button>
            </div>
            <div>
              <button class="btn button-red-strong me-2 btn-sm" (click)="openAlert(Table.id)">Eliminar</button>
            </div>
          </td>
        </tr>
    </table>
</div>