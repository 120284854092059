export const  tableInventory = [
    {
        title: 'Todos',
        col: 'col-1'
    },
    {
        title: 'Codigo',
        col: 'col-1'
    },
    {
        title: 'Familia',
        col: 'col'
    },
    {
        title: 'Producto',
        col: 'col'
    },
    {
        title: 'Slot',
        col: 'col-1'
    },
    {
        title: 'Unidades',
        col: 'col-1'
    },

]
export const  tableProductsTraslate = [
    {
        title: 'Todos',
        col:'col-1'
    },
    {
        title: 'Código',
        col:'col-2'
    },
    {
        title: 'Producto',
        col:'col'
    },
    {
        title: 'Cantidad',
        col:'col-2'
    },

]