<div class="card bg-white shadow p-5">
  <div class=" text-center pt-4">
    <img src="{{terms.login.image}}">
  </div>
  <div class="text-center mt-4">        
    <app-title [title]="terms.login.title" [colorText]="'secondary'" [fs]="'3'"></app-title>
    <app-subtitle [title]="terms.login.subtitle" [colorText]="'secondary'"></app-subtitle>
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
    <div  class="mt-3 pb-1">
      <div class="loginForm">
        <app-title [title]="terms.login.userLabel" [colorText]="'secondary'"></app-title>
        <input type="email" formControlName="username" id="username" class="form-control" >
        <div class="validation-error">
          <ng-container *ngFor="let validation of validation_messages.email">
            <div *ngIf="loginForm.get('email')?.hasError(validation.type) && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)">
              {{validation.message}}
            </div>        
          </ng-container>
        </div>
      </div>
      <div class="mt-3">
        <app-title [title]="terms.login.passwordLabel" [colorText]="'secondary'"></app-title>
        <input type="password" formControlName="password" class="form-control">
        <div class="validation-error">
          <ng-container *ngFor="let validation of validation_messages.password">
            <div *ngIf="loginForm.get('password')?.hasError(validation.type) && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
              {{validation.message}}
            </div>        
          </ng-container>
        </div>
      </div>
      <div class="text-center">
        <div class="card-red bg-danger mt-2 p-3" *ngIf="error !== ''">
          <div class="d-flex h-100">
            <i class="bi bi-info-circle text-white me-2"></i>
            <p class="text-white m-0">{{error}}</p>
          </div>
        </div>
      </div>
     
      <div class="text-end mt-4">
        <a>
          <app-link [title]="terms.login.forgot" [colorLink]="'secondary'" [url]="terms.login.url"></app-link>
        </a>      
      </div>   
      <div class="text-end mt-4">
        <app-link-button [title]="terms.login.singIn" [url]="terms.introUrl" [type]="'submit'" *ngIf="!loading"></app-link-button>
        <button class="btn button-red" [routerLink]="terms.introUrl" type="submit" *ngIf="loading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </button>
      </div>
    </div>
  </form>
</div>