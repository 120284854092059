import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { CLIENT_FILTER_URL, CLIENT_HEADQUARTERS_FILTER_URL, OFFICE_URL, STOCK_FILTER_URL, termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { IncomeTemplateModalConfirmComponent } from 'src/app/modules/income/domain/templates/income-template-modal-confirm/income-template-modal-confirm.component';
import { PRODUCT_CATEGORIES_FILTER_URL, PRODUCT_PACKAGES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { packageEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { offices } from '../../constants/OfficesConstants';
import { tableClient } from '../../constants/TableOffices';
import { officeListParamsEmpty, officeListParamsEntity } from '../../entities/OfficesListParamsEntity';
import { updateOfficeListParams } from '../../state/offices.actions';
import { selectCloseModals } from 'src/app/modules/app-common/domain/state.ts/common.selectors';

@Component({
  selector: 'app-offices-template-modal-new-order',
  templateUrl: './offices-template-modal-new-order.component.html',
  styleUrls: ['./offices-template-modal-new-order.component.scss']
})
export class OfficesTemplateModalNewOrderComponent implements OnInit {

  stock:any
  loading:boolean = false
  checkClient:boolean = false
  multiple:any
  date:any
  hour:any
  checkSed:boolean = false
  checkDate:boolean = false
  client:any
  head= tableClient
  public offices = offices
  public termConstants = termConstants
  dateClients:any
  icons = ICONS_CONSTANTS
  listClient:any
  listSed:any
  model:any
  listCat:any
  loader:boolean = false
  idSelect:any
  bus:any
  alert:boolean = false
  order:any[] = []
  orderFull:any[] = []
  listParams: officeListParamsEntity= officeListParamsEmpty
  constructor(
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    private apiService:ApiService,
    private store:Store<AppState>
  ) {
    this.store.select(selectCloseModals).subscribe(data => {
      if(data) this.activeModal.close()
    })
   }

  ngOnInit(): void {
    this.apiService.post(CLIENT_FILTER_URL,{}).subscribe((res:any)=>{
      this.dateClients = res
      this.listClient = res
      this.loader = true
        })
    this.apiService.post(PRODUCT_CATEGORIES_FILTER_URL, {}).subscribe(category =>{
          this.listCat = category
        })
    this.apiService.post(CLIENT_HEADQUARTERS_FILTER_URL,{}).subscribe((res:any)=>{
          this.listSed = res
        })
  }
  AddItemHead(id:number){
    this.order.push({'headquarter':id},{'clientName':this.client.name})
    this.checkSed = true
  }
  filterPackage(item:any){
    console.log(item)
      this.client = item
      this.checkClient = true
  }
  AddDate(){
    this.order.push({'date':this.date})
    this.checkDate = true
  }
  AddHour(){
    this.order.push({'hour':this.hour})
    this.apiService.post(STOCK_FILTER_URL, {}).subscribe((stock:any)=>{
      this.stock = <packageEntity[]>stock
    this.loading=true
  })
  }
  sendProduct(data:packageEntity[], index:number, amount:number){
    if(amount > 0){
      let cos = this.orderFull.find(el => el.id ===data[index].id)
      this.bus = this.orderFull.includes(data[index])
      this.alert = false
      if(cos===undefined){
        this.stock[index].active = true
        this.orderFull.push(data[index])
        this.stock[index].amount = this.stock[index].quantity * amount
      }else{
        let pos = this.orderFull.indexOf(data[index])
        this.stock[index].active = false
        this.orderFull.splice(pos)
      }
    }
  }
  openModalConfirm(){
    this.order.push({'packages':this.orderFull})
    if(this.order.length===0){
      this.alert = true
    }else{
      const modalRef = this.modalService.open(IncomeTemplateModalConfirmComponent,{                                                                
        size: 'xl'
      });modalRef.componentInstance.order = this.order,
      modalRef.componentInstance.url = OFFICE_URL
    }
  }
  updateKeyCat(app_category_id: number){
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_products_categories_id: app_category_id
      }
    }))
    
  }
  updateAllCat(){
    this.apiService.post(PRODUCT_PACKAGES_FILTER_URL, this.listParams).subscribe((response:any) =>{
      this.stock = response
    } )
  }
  filterKeyCat(nameCategory: any){
    let key = this.listCat.filter((el:any)=>el.name.toLowerCase().indexOf(nameCategory.toLowerCase()) !== -1)
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_products_categories_id: key[0].id
      }
    }))
  }
  updateList(keyword: string){

    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: keyword
      }
    }))
  }

}
