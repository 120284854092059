import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/domain/guards/auth.guard';
import { FeatureProductsComponent } from './feature-products/feature-products.component';

const routes: Routes = [
  {path: 'productos', component: FeatureProductsComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
