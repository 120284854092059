import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextComponent } from './domain/atoms/input-text/input-text.component';
import { LinkComponent } from './domain/atoms/link/link.component';
import { FormInputTextComponent } from './domain/molecules/form-input-text/form-input-text.component';
import { FormInputPasswordComponent } from './domain/molecules/form-input-password/form-input-password.component';
import { FormInputEmailComponent } from './domain/molecules/form-input-email/form-input-email.component';
import { FormCheckboxLabeledComponent } from './domain/molecules/form-checkbox-labeled/form-checkbox-labeled.component';
import { InputEmailComponent } from './domain/atoms/input-email/input-email.component';
import { InputPasswordComponent } from './domain/atoms/input-password/input-password.component';
import { TextComponent } from './domain/atoms/text/text.component';
import { TitleComponent } from './domain/atoms/title/title.component';
import { RouterModule } from '@angular/router';
import { LinkButtonComponent } from './domain/atoms/link-button/link-button.component';
import { IconTextComponent } from './domain/molecules/icon-text/icon-text.component';
import { InputImageComponent } from './domain/molecules/input-image/input-image.component';
import { TitleSubtitleComponent } from './domain/molecules/title-subtitle/title-subtitle.component';
import { TextIconComponent } from './domain/molecules/text-icon/text-icon.component';
import { IconButtonComponent } from './domain/molecules/icon-button/icon-button.component';
import { IconButtonTopComponent } from './domain/molecules/icon-button-top/icon-button-top.component';
import { ProgressBarComponent } from './domain/atoms/progress-bar/progress-bar.component';
import { SubtitleComponent } from './domain/atoms/subtitle/subtitle.component';
import { InputTextIconComponent } from './domain/molecules/input-text-icon/input-text-icon.component';
import { InputButtonComponent } from './domain/molecules/input-button/input-button.component';
import { TextCheckboxComponent } from './domain/molecules/text-checkbox/text-checkbox.component';
import { ButtonTrashComponent } from './domain/molecules/button-trash/button-trash.component';
import { IconSubtitleComponent } from './domain/molecules/icon-subtitle/icon-subtitle.component';
import { IconTextIconComponent } from './domain/molecules/icon-text-icon/icon-text-icon.component';
import { ButtonIconTextComponent } from './domain/molecules/button-icon-text/button-icon-text.component';
import { TextInputTextIconComponent } from './domain/molecules/text-input-text-icon/text-input-text-icon.component';
import { TableComponent } from './domain/molecules/table/table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ItemTableComponent } from './domain/atoms/item-table/item-table.component';
import { RowItemComponent } from './domain/atoms/row-item/row-item.component';
import { PaginationSelectComponent } from './domain/molecules/pagination-select/pagination-select.component';
import { TooltipActiveComponent } from './domain/atoms/tooltip-active/tooltip-active.component';
import { TextAreaComponent } from './domain/atoms/text-area/text-area.component';
import { ModalAlertComponent } from './domain/organisms/modal-alert/modal-alert.component';
import { SlotComponent } from './domain/molecules/slot/slot.component';
import { SlotCardComponent } from './domain/organisms/slot-card/slot-card.component';
import { FeatureProvidersComponent } from './feature-providers/feature-providers.component';
import { CrudTableComponent } from './domain/organisms/crud-table/crud-table.component';
import { ModalEditProviderComponent } from './domain/templates/modal-edit-provider/modal-edit-provider.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatureProductsComponent } from './feature-products/feature-products.component';
import { ModalEditProductComponent } from './domain/templates/modal-edit-product/modal-edit-product.component';
import { FeaturePackingComponent } from './feature-packing/feature-packing.component';
import { ModalNewPackingComponent } from './domain/templates/modal-new-packing/modal-new-packing.component';
import { FeatureFamiliesComponent } from './feature-families/feature-families.component';
import { DropdownComponent } from './domain/organisms/dropdown/dropdown.component';
import { ModalEditCategoriesComponent } from './domain/templates/modal-edit-categories/modal-edit-categories.component';
import { DateComponent } from './domain/atoms/date/date.component';
import { InputSearchComponent } from './domain/organisms/input-search/input-search.component';
import { FeatureClientsComponent } from './feature-clients/feature-clients.component';
import { ModalEditClientComponent } from './domain/templates/modal-edit-client/modal-edit-client.component';
import { FeatureClientHeadquartersComponent } from './feature-client-headquarters/feature-client-headquarters.component';
import { ModalEditClientHeadquartersComponent } from './domain/templates/modal-edit-client-headquarters/modal-edit-client-headquarters.component';
import { MapSelectComponent } from './domain/organisms/map-select/map-select.component';
import { FormSelectComponent } from './domain/atoms/form-select/form-select.component';
import { FeatureCellarComponent } from './feature-cellar/feature-cellar.component';
import { ModalEditCellarComponent } from './domain/templates/modal-edit-cellar/modal-edit-cellar.component';
import { FeatureDocumentTypesComponent } from './feature-document-types/feature-document-types.component';
import { ModalEditDocumentTypesComponent } from './domain/templates/modal-edit-document-types/modal-edit-document-types.component';
import { InputFileComponent } from './domain/molecules/input-file/input-file.component';
import 'animate.css';
import { FeatureZonesComponent } from './feature-zones/feature-zones.component';
import { ModalEditZonesComponent } from './domain/templates/modal-edit-zones/modal-edit-zones.component';
import { MapMarkerComponent } from './domain/organisms/map-marker/map-marker.component';
import { FeatureVehiclesComponent } from './feature-vehicles/feature-vehicles.component';
import { ModalEditVehiclesComponent } from './domain/templates/modal-edit-vehicles/modal-edit-vehicles.component';
import { FeatureZonesDistributionComponent } from './feature-zones-distribution/feature-zones-distribution.component';
import { ModalEditZonesDistributionComponent } from './domain/templates/modal-edit-zones-distribution/modal-edit-zones-distribution.component';




@NgModule({
  declarations: [
    InputTextComponent,
    LinkComponent,
    FormInputTextComponent,
    FormInputPasswordComponent,
    FormInputEmailComponent,
    FormCheckboxLabeledComponent,
    InputEmailComponent,
    InputPasswordComponent,
    TextComponent,
    TitleComponent,
    LinkButtonComponent,
    IconTextComponent,
    InputImageComponent,
    TitleSubtitleComponent,
    TextIconComponent,
    IconButtonComponent,
    IconButtonTopComponent,
    ProgressBarComponent,
    SubtitleComponent,
    InputTextIconComponent,
    InputButtonComponent,
    TextCheckboxComponent,
    ButtonTrashComponent,
    IconSubtitleComponent,
    IconTextIconComponent,
    ButtonIconTextComponent,
    TextInputTextIconComponent,
    TableComponent,
    ItemTableComponent,
    RowItemComponent,
    PaginationSelectComponent,
    TooltipActiveComponent,
    TextAreaComponent,
    ModalAlertComponent,
    SlotComponent,
    SlotCardComponent,
    FeatureProvidersComponent,
    CrudTableComponent,
    ModalEditProviderComponent,
    FeatureProductsComponent,
    ModalEditProductComponent,
    FeaturePackingComponent,
    ModalNewPackingComponent,
    FeatureFamiliesComponent,
    DropdownComponent,
    ModalEditCategoriesComponent,
    DateComponent,
    InputSearchComponent,
    FeatureClientsComponent,
    ModalEditClientComponent,
    FeatureClientHeadquartersComponent,
    ModalEditClientHeadquartersComponent,
    MapSelectComponent,
    FormSelectComponent,
    FeatureCellarComponent,
    ModalEditCellarComponent,
    FeatureDocumentTypesComponent,
    ModalEditDocumentTypesComponent,
    InputFileComponent,
    FeatureZonesComponent,
    ModalEditZonesComponent,
    MapMarkerComponent,
    FeatureVehiclesComponent,
    ModalEditVehiclesComponent,
    FeatureZonesDistributionComponent,
    ModalEditZonesDistributionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    FormInputTextComponent,
    InputTextComponent,
    LinkComponent,
    FormInputTextComponent,
    FormInputPasswordComponent,
    FormInputEmailComponent,
    FormCheckboxLabeledComponent,
    InputEmailComponent,
    InputPasswordComponent,
    TextComponent,
    TitleComponent,
    LinkButtonComponent,
    IconTextComponent,
    TitleSubtitleComponent,
    TextIconComponent,
    IconButtonComponent,
    IconButtonTopComponent,
    ProgressBarComponent,
    SubtitleComponent,
    InputTextIconComponent,
    InputButtonComponent,
    TextCheckboxComponent,
    ButtonTrashComponent,
    IconSubtitleComponent,
    IconTextIconComponent,
    ButtonIconTextComponent,
    TextInputTextIconComponent,
    TableComponent,
    ItemTableComponent,
    RowItemComponent,
    PaginationSelectComponent,
    TooltipActiveComponent,
    TextAreaComponent,
    SlotComponent,
    SlotCardComponent,
    FeatureProvidersComponent,
    DropdownComponent,
    DateComponent,
    InputSearchComponent,
    FormSelectComponent,
    InputFileComponent,
    MapMarkerComponent,
  ]
})
export class AppCommonModule { }
