<div class="card shadow-sm p-3 mb-3 ">
    <div class="d-flex align-items-center">
        <div class="border-end pe-3">
            <app-title [title]="optimize.transferedCard.code" [colorText]="'primary'"></app-title>
            <app-title [title]="dateTransfered.cod" [colorText]="'info'" [fs]="'6 fw-bolder'"></app-title>
        </div>
        <div class="card-1 border rounded ms-2 me-2 mt-1">
          <img src="{{dateTransfered.image}}">
        </div>
        <div class="border-end pe-3">
            <app-title [title]="optimize.transferedCard.product" [colorText]="'primary'"></app-title>
            <app-title [title]="dateTransfered.name" [fs]="'6 fw-bolder'"></app-title>
        </div>
        <div class="ps-3 pe-3 pb-1">
            <app-title [title]="optimize.transferedCard.units" [colorText]="'primary'"></app-title>
            <app-title [title]="dateTransfered.unit" [fs]="'6 fw-bolder mt-1'" [colorText]="'info'"></app-title>
        </div>
        <div class="pe-3">
            <app-title [title]="optimize.transferedCard.boxs" [colorText]="'primary'"></app-title>
            <div class="d-flex align-items-center">
                <app-title [title]="dateTransfered.box" [fs]="'6 fw-bolder me-2'"></app-title>
                <img src="{{optimize.transferedCard.imgBoxs}}">
            </div>   
        </div>
        <div class="pe-3">
            <app-title [title]="optimize.transferedCard.sachets" [colorText]="'primary'"></app-title>
            <div class="d-flex align-items-center">
                <app-title [title]="dateTransfered.sachets" [fs]="'6 fw-bolder me-2'"></app-title>
                <img src="{{optimize.transferedCard.imgSachets}}">
            </div>
        </div>
        <div class="border-end pe-3">
            <app-title [title]="optimize.transferedCard.packing" [colorText]="'primary'"></app-title>
            <div class="d-flex align-items-center">
                <app-title [title]="dateTransfered.packing" [fs]="'6 fw-bolder me-2'"></app-title>
                <img src="{{optimize.transferedCard.imgPacking}}">
            </div>
        </div>
        <div class="border-end ps-3 pe-3">
            <app-title [title]="optimize.transferedCard.slot" [colorText]="'primary'"></app-title>
            <app-title [title]="dateTransfered.slot" [fs]="'4 fw-bolder'" [colorText]="'info'"></app-title>
        </div>
        <div class="ps-3 pe-3">
            <app-title [title]="optimize.transferedCard.lastMovement" [colorText]="'primary'"></app-title>
            <app-title [title]="dateTransfered.date" [fs]="'6 fw-bolder'"></app-title>
        </div>
        <div class="border-end ps-3 pe-3">
            <app-title [title]="optimize.transferedCard.ago" [colorText]="'primary'"></app-title>
            <app-title [title]="dateTransfered.day" [fs]="'6 fw-bolder'"></app-title>
        </div>
        <div class="ps-3 pt-3">
            <button class="btn button-red btn-sm" type="button" (click)="openModalSelect()">{{optimize.transferedCard.transferedObsolet}}</button>
        </div>
    </div>
</div>