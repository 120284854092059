import { createReducer, on } from "@ngrx/store";
import { profilesListParamsEmpty, usersListParamsEmpty } from "../entities/UsersListParamsEntity";
import { UsersState } from "../entities/UsersState";
import { loadProfiles, loadUsers, updateProfilesListParams, updateUsersList, updateUsersListParams } from "./users.actions";


export const initialState: UsersState = {
  user: [],
  profile: [],
  keyUser: usersListParamsEmpty,
  keyProfile:profilesListParamsEmpty,
  fetch: false
}

export const usersReducer = createReducer(
  initialState,
  on(loadUsers, (state,{user}) => ({ ...state, user})),
  on(loadProfiles, (state,{profile}) => ({ ...state, profile})),
  on(updateUsersListParams, (state, {keyUser}) => ({ ...state, keyUser})),
  on(updateProfilesListParams, (state, {keyProfile}) => ({ ...state, keyProfile})),
  on(updateUsersList, state => ({ ...state, fetch: true }))
);