export interface officeListParamsEntity{
    filters: {
      keyword: string,
      app_products_categories_id: number,
      app_client_id:number,
      app_distribution_zones_id:number
    },
    page: number,
    size: number
  }
  
  export const officeListParamsEmpty: officeListParamsEntity = {
    filters: {
      keyword: '',
      app_products_categories_id:0,
      app_client_id:0,
      app_distribution_zones_id:0
    },
    page: 1,
    size: 7,
  }