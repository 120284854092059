import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { USER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { incomeListParamsEmpty, incomeListParamsEntity } from 'src/app/modules/income/domain/entities/IncomeListParamsEntity';
import { users } from '../../constants/UsersConstants';
import { userEntity } from '../../entities/UsersEntities';
import { usersListParamsEmpty, usersListParamsEntity } from '../../entities/UsersListParamsEntity';
import { selectFetchUsers, selectUsersListParams } from '../../state/users.selectors';
import { UsersTemplateModalNewuserComponent } from '../../templates/users-template-modal-newuser/users-template-modal-newuser.component';

@Component({
  selector: 'app-card-user',
  templateUrl: './card-user.component.html',
  styleUrls: ['./card-user.component.scss']
})
export class CardUserComponent implements OnInit {

  loading:boolean = false
  public users = users
  dateUsers?:userEntity[]=[]
  listParams: usersListParamsEntity = usersListParamsEmpty
  constructor(
    public modalService:NgbModal,
    public apiService:ApiService,
    private store:Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.select(selectUsersListParams).subscribe(params => {
      this.listParams = params
      this.apiService.post(USER_URL+'filter', params).subscribe((data:any) => {
        this.dateUsers = data
        this.loading=true
      })
    })

    this.store.select(selectFetchUsers).subscribe(fetch => {
      if(fetch) this.apiService.post(USER_URL+'filter', this.listParams).subscribe((data:any) => this.dateUsers = data)
    })
    
  }

  openModalEditUser(data:userEntity, id:number) {
    const modalRef = this.modalService.open(UsersTemplateModalNewuserComponent,{
      size: 'lg'
    });modalRef.componentInstance.item = data, modalRef.componentInstance.id = id
  }
}
