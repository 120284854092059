<table class="table">
  <tr class="d-flex">
    <th class="d-flex bg-light align-items-center ps-1 pe-1" *ngIf="check==='.'">
      <app-text [title]="check" [colorText]="'light'"></app-text>
      <input class="form-check-input" type="checkbox" >      
    </th>
    <app-item-table *ngFor="let item of tableHead" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
    <th class="bg-light col-3" *ngIf="buttonBlue==='.'">
      <app-text [title]="buttonBlue" [colorText]="'light'" ></app-text>
    </th>
    <th class="bg-light col-2" *ngIf="buttonRed==='.'">
      <app-text [title]="buttonRed" [colorText]="'light'" *ngIf="buttonRed==='.'"></app-text>
    </th>
    <th class="bg-light col" *ngIf="buttonProv==='.'">
      <app-text [title]="buttonBlue" [colorText]="'light'" ></app-text>
    </th>
  </tr>
  <tr class="d-flex" *ngFor="let Table of tableBody">
    <td class="d-flex align-items-center border border-light ps-1 pe-1" *ngIf="check==='.'">
      <app-text [title]="check" [colorText]="'white'"></app-text>
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
    </td>
    <td class="d-flex align-items-center col border border-light ms-1" *ngIf="buttonRed==='.'">
      <app-text [title]="Table.zone" [colorText]="'warning'" *ngIf="Table.zone!=='Norte'"></app-text>
      <app-text [title]="Table.zone" [colorText]="'info'" *ngIf="Table.zone==='Norte'"></app-text>
    </td>
    <app-row-item [title]="Table.row1" [colorText]="Table.color" [src]="Table.src1" [class]="col1" [button]="Table.button" [placeholder]="Table.placeholder" class="bg-white border border-light" *ngIf="Table.row1!==undefined || Table.src1!==undefined"></app-row-item>
    <app-row-item [title]="Table.row2" [colorText]="Table.color" [src]="Table.src2" [class]="col2" class="bg-white border border-light" *ngIf="Table.row2!==undefined"></app-row-item>
    <app-row-item [title]="Table.row3" [colorText]="Table.color" [src]="Table.src3" [class]="col3" class="bg-white border border-light" *ngIf="Table.row3!==undefined"></app-row-item>
    <app-row-item [title]="Table.row4" [colorText]="Table.color" [src]="Table.src4" [class]="col4" class="bg-white border border-light" *ngIf="Table.row4!==undefined"></app-row-item>
    <app-row-item [title]="Table.row5" [colorText]="Table.color" [src]="Table.src5" [class]="col5" class="bg-white border border-light" *ngIf="Table.row5!==undefined"></app-row-item>
    <app-row-item [title]="Table.row6" [colorText]="Table.color" [src]="Table.src6" [class]="col6" class="bg-white border border-light" *ngIf="Table.row6!==undefined"></app-row-item>
    <td class="d-flex align-items-center border border-light col-3" *ngIf="buttonBlue==='.'">
      <button class="btn button-blue btn-sm" type="button" (click)="openModalProduct()">Ver Productos</button>
    </td>
    <td class="d-flex align-items-center border border-light col-2" *ngIf="buttonRed==='.'">
      <app-button-icon-text [title]="'Asignar Zona'" [icon]="icons.LOCATE" *ngIf="Table.zone==='Sin localización'"></app-button-icon-text>
      <app-icon-text-icon class="ms-4" [src]="'./assets/icon-alert.png'" [title]="'Distinta zona'" [colorText]="'color-1'" *ngIf="Table.zone!=='Sin localización' && Table.zone!=='Norte'"></app-icon-text-icon>
    </td>
    <td class="d-flex align-items-center border border-light col" *ngIf="buttonProv==='.'">
      <div class="ms-3 me-3">
        <button class="btn button-blue btn-sm" type="button" (click)="openModalEdit()">Editar</button>
      </div>
      <div>
        <button class="btn button-red-strong me-2 btn-sm" (click)="openAlert()">Eliminar</button>
      </div>
     
    </td>
  </tr>
</table>
<app-pagination-select
  [collectionSize]="collectionSize"
  [page]="page"
  [pageSize]="pageSize"
  *ngIf="collectionSize > 1"></app-pagination-select>

