import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalService } from 'src/app/modules/auth/domain/services/local.service';
 
import { hideHeader, hideSidebar, showHeader, showSidebar } from '../state/nav.actions';

 
@Injectable({ providedIn: 'root' })
export class SideBarService {
  constructor( public store:Store, public local:LocalService) {}
 
  show(){
    this.store.dispatch(showSidebar())
    this.store.dispatch(showHeader())
  }
  hide(){
    this.store.dispatch(hideSidebar())
    this.store.dispatch(hideHeader())
  }
}
