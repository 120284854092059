<div class="p-3">
  <app-title [title]="'Selecciona el producto, la familia o el slot a optimizar'"></app-title>
  <div class="d-flex align-items-center">
      <div class="d-flex col-10">
          <div class="me-3">
            <button class="btn button-red-strong btn-sm" (click)="openModalAlert()">{{optimize.filter.start}}</button>
          </div>
          <div class="me-3">
              <app-input-button></app-input-button>
          </div>
          <div class="col-1 me-3">
            <div class="form-group">
              <select class="form-select form-select-sm">
                <option value="{{optimize.filter.family}}" selected></option>
                <option >Name</option>
              </select>
            </div>
          </div>
          <div class="col-1 me-3">
            <div class="form-group">
              <select class="form-select form-select-sm">
                <option value="{{optimize.filter.slots}}" selected></option>
                <option >Name</option>
              </select>
            </div>
          </div>
          <div class="col-1 me-3">
            <div class="form-group">
              <select class="form-select form-select-sm">
                <option value="{{optimize.filter.subSlots}}" selected></option>
                <option >Name</option>
              </select>
            </div>
          </div>
      </div>
      <div class="col">
          <div class="d-flex justify-content-between">
            <div class="col-2">
              <img src="./assets/icon1-blue.png" class="w-75">
            </div>
            <div class="col-2">
              <img src="./assets/icon2-blue.png" class="w-75">
            </div>            
          </div>
          <div class="col-10">
              <app-progress-bar [value]="0"></app-progress-bar>
          </div>
      </div>
  </div>
  <div class="card bg-light border border-0">
    <table class="table" >
      <tr class="d-flex">
        <app-item-table *ngFor="let item of tableHead" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
      </tr>
      <tr class="row" *ngFor="let Table of products ; index as i" (click)="sendProduct(products, i, Table.quantity)">
        <app-row-item [src]="'/assets/icon3-blue.png'" [active]="Table.active" class="bg-white col-1 border border-light" ></app-row-item>
        <app-row-item [title]="Table.appProducts.code" [textWar]="Table.active" class="bg-white col-1 border border-light" ></app-row-item>
        <app-row-item [title]="Table.appProducts.appProductCategories.name" [textWar]="Table.active" class="bg-white col border border-light" ></app-row-item>
        <app-row-item [title]="Table.appProducts.name" [textWar]="Table.active" [src]="'/assets/image.png'" class="bg-white col border border-light" ></app-row-item>
        <app-row-item [title]="Table.appProducts.appProviders.name"  [textWar]="Table.active" class="bg-white col-1 border border-light" ></app-row-item>
        <app-row-item [title]="Table.quantity" [textWar]="Table.active" class="bg-white col-1 border border-light" ></app-row-item>
      </tr>
    </table>
  </div>

</div>
