import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { OPTIMIZE_TRANSFERED_FILTER_URL } from '../../constants/OptimizeConstants';

@Component({
  selector: 'app-optimize-template-transfered-list',
  templateUrl: './optimize-template-transfered-list.component.html',
  styleUrls: ['./optimize-template-transfered-list.component.scss']
})
export class OptimizeTemplateTransferedListComponent implements OnInit {

  dateTransfereds:any
  constructor(
    public apiService:ApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.post(OPTIMIZE_TRANSFERED_FILTER_URL,{"keyword":""}).subscribe((response:any)=>{
      this.dateTransfereds = response     
    })
  }

}
