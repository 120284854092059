import { createReducer, on } from "@ngrx/store";
import { CommonState } from "../entities/CommonState";
import { PROVIDER_EMPTY } from "../entities/ProviderEntity";
import { clearProvider, closeModals, closeModalsDone, fetchList, fetchListDone, rechargePage, setProvider, updateFilter } from "./common.actions";



export const initialState: CommonState = {
    filters: {
        keyword:''
    },
    loading: false,
    fetch: false,
    provider: PROVIDER_EMPTY,
    closeModals: false
}

export const commonReducer = createReducer(
  initialState,
  on(updateFilter, (state,{filters}) => ({ ...state, filters})),
  on(rechargePage, state => ({ ...state, loading:true})),
  on(setProvider, (state,{provider}) => ({ ...state, provider})),
  on(clearProvider, (state => ({ ...state, provider:PROVIDER_EMPTY}))),
  on(fetchList, state => ({ ...state, fetch: true })),
  on(fetchListDone, state => ({ ...state, fetch: false })),
  on(closeModals, state => ({ ...state, closeModals: true })),
  on(closeModalsDone, state => ({ ...state, closeModals: false }))
);
