import { createAction, props } from '@ngrx/store';
import { dateUser } from '../entities/DateUser';

 
export const loaddedApi = createAction('[Auth Login] load Api',
props<{dates:any}>()
)

export const setMe = createAction('[Auth set me] Set me',
props<{me: dateUser}>()
)
