<div class="d-flex justify-content-center" *ngIf="!loading">
    <div class="spinner-border text-danger" role="status">
    </div>
</div>
<div class="d-flex flex-wrap " *ngIf="loading">
  <div class="card shadow me-4 mt-4" *ngFor="let dateUser of dateUsers">
    <div class="card-1 text-center p-3">
      <img class="border border-2 border-danger rounded-circle p-1" src="{{dateUser?.imageUrl}}" width="80" height="80">
      <app-title [title]="dateUser?.name" [fs]="'5'"></app-title>
    </div>
    <div class="text-center shadow">
        <div class="card-adm shadow" *ngIf="dateUser?.userProfiles?.name==='Administrador'">
            <app-title [title]="dateUser?.userProfiles?.name" [colorText]="'light fw-bolder p-2'" ></app-title>
        </div>
        <div class="card-jef shadow" *ngIf="dateUser?.userProfiles?.name==='Jefe de bodega'">
            <app-title [title]="dateUser?.userProfiles?.name" [colorText]="'light fw-bolder p-2'" ></app-title>
        </div>
        <div class="card-op shadow" *ngIf="dateUser?.userProfiles?.name==='Operario'">
            <app-title [title]="dateUser?.userProfiles?.name" [colorText]="'light fw-bolder p-2'" ></app-title>
        </div>
    </div>    
    <div class="p-3">
        <app-title [title]="'Bodega A'" [colorText]="'info'"></app-title>
        <app-title [title]="dateUser?.email" [colorText]="'secondary'"></app-title>
        <app-title [title]="dateUser?.id" [colorText]="'secondary'"></app-title>
        <div class="d-flex justify-content-center pt-3">
            <button class="btn button-red btn-sm" type="button" (click)="openModalEditUser(dateUser, dateUser.id)">{{users.cardProfile.edit}}</button>
        </div>
        
    </div>
</div>
</div>
