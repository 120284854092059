import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() list:any
  @Input() model:any
  @Input() key:any
  @Input() active:boolean = false
  nameFilter:any
  @Input() nameDefault :any
  @Output() searchIdItem = new EventEmitter()
  @Output() searchKeyWord = new EventEmitter()
 
  constructor() { }

  ngOnInit(): void {

  }
  sendIdItem($event:any){
    this.model = $event.name
    this.searchIdItem.emit($event.id)
  }
  sendKeyWord($event: KeyboardEvent){
    if($event.key === 'Enter'){
      this.searchKeyWord.emit(this.model)
    }
  }
 

}
