import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NavState } from 'src/app/modules/nav/domain/state/NavState';
import { terms } from '../../constants/AuthConstants';

@Component({
  selector: 'app-auth-form-forgot-password',
  templateUrl: './auth-form-forgot-password.component.html',
  styleUrls: ['./auth-form-forgot-password.component.scss']
})
export class AuthFormForgotPasswordComponent implements OnInit {
  public terms = terms;

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
