import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent implements OnInit {
  @Input() title:string = '';
  @Input() url:string = '';
  @Input() class:string = '';
  @Input() colorLink:string = '';
  @Input() colorText:string = '';
  @Input() type:string = '';
  @Input() disabled:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
