import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-text-icon',
  templateUrl: './icon-text-icon.component.html',
  styleUrls: ['./icon-text-icon.component.scss']
})
export class IconTextIconComponent implements OnInit {

  @Input() icon:string = '';
  @Input() title:string = '';
  @Input() colorText:string = '';
  @Input() colorIcon:string = '';
  @Input() fs:string = '';
  @Input() src:string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
