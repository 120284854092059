import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/domain/guards/auth.guard';
import { FeatureAdminSlotComponent } from './feature-admin-slot/feature-admin-slot.component';
import { FeatureListProductComponent } from './feature-list-product/feature-list-product.component';

const routes: Routes = [
  {path: 'stock', children: [
    {path: 'lista', component: FeatureListProductComponent, canActivate: [AuthGuard] },
    {path: 'administracion', component: FeatureAdminSlotComponent, canActivate: [AuthGuard] },

  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StockRoutingModule { }
