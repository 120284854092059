import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductsRoutingModule } from './products-routing.module';
import { FeatureProductsComponent } from './feature-products/feature-products.component';
import { AppModule } from 'src/app/app.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { ProductsTemplateFilterComponent } from './domain/templates/products-template-filter/products-template-filter.component';
import { ProductsTemplateTableComponent } from './domain/templates/products-template-table/products-template-table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavModule } from '../nav/nav.module';
import { ProductModalNewProductComponent } from './domain/templates/product-modal-new-product/product-modal-new-product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    FeatureProductsComponent,
    ProductsTemplateFilterComponent,
    ProductsTemplateTableComponent,
    ProductModalNewProductComponent,
  ],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    AppCommonModule,
    NgbModule,
    NavModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ProductsModule { }
