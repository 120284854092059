
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { INCOME_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { fetchListDone } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { selectFetch } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { income, INCOMES_FILTER_URL, INCOMES_URL } from '../../constants/IncomeConstants';
import { tableIncome } from '../../constants/TablesIncome';
import { incomeEntity } from '../../entities/IncomeEntities';
import { incomeListParamsEmpty, incomeListParamsEntity } from '../../entities/IncomeListParamsEntity';
import { updateIncomeListParams } from '../../state/income.actions';
import { selectIncomeListParams } from '../../state/income.selectors';
import { IncomeTemplateModalIncomeComponent } from '../../templates/income-template-modal-income/income-template-modal-income.component';

@Component({
  selector: 'app-card-income',
  templateUrl: './card-income.component.html',
  styleUrls: ['./card-income.component.scss']
})
export class CardIncomeComponent implements OnInit {
  public head = tableIncome
  public income = income
  icons = ICONS_CONSTANTS
  @Input() show: boolean = false;
  i:any
  provName:any
  packages:any
  loading:boolean= false
	pageSize = 7;
  collectionSize:any
  data?: any

  listParams: incomeListParamsEntity = incomeListParamsEmpty
	
  constructor(
    public apiService:ApiService,
    public store:Store<AppState>,
    public modalService:NgbModal
  ) {
    this.store.select(selectFetch).subscribe(fetch => {
      if(fetch){
        this.apiService.post(INCOME_FILTER_URL, this.listParams).subscribe(data => this.data = <incomeEntity[]>data )
        this.store.dispatch(fetchListDone())
      }
    })
  }

  ngOnInit(): void {
    this.store.select(selectIncomeListParams).subscribe(params => {
      this.listParams = params
      this.apiService.post(INCOMES_FILTER_URL, params).subscribe((data:any) => {
        this.data =<incomeEntity[]>data
        this.loading = true
      })
      this.apiService.post(INCOMES_URL+'length').subscribe((length:any)=>{
        this.collectionSize = length
      })
    })
  }

  rotated(index:number){   
    this.packages = this.data[index].packages
  }

  openModalIncome(id:number) {
    const modalRef = this.modalService.open(IncomeTemplateModalIncomeComponent,{
      size: 'xl'
    });
    modalRef.componentInstance.id = id
  }

  updateListParams(){
    window.scroll(0, 0)
    this.store.dispatch(updateIncomeListParams({...this.listParams}))
  }
}

