import { createAction, props } from "@ngrx/store";
import { FiltersEntity, idEntity } from "../entities/FiltersEntity";
import { providerEntity } from "../entities/ProviderEntity";

export const updateFilter = createAction('[Update providers filter] Update providers filter',
    props< { filters: FiltersEntity }>()
  )

  export const setProvider = createAction('[Provider] Set Provider',
    props< { provider: providerEntity }>()
  )

  export const clearProvider = createAction( '[Provider] Clear Provider'
  )
  export const rechargePage = createAction( '[Provider] Clear Provider',
  props< { loading: boolean }>()
  )

  export const fetchList = createAction('[Common] Fetch list')
  export const fetchListDone = createAction('[Common] Fetch list done')

  export const closeModals = createAction('[Common] Close modals')
  export const closeModalsDone = createAction('[Common] Close modals done')


  

