import { createAction, props } from "@ngrx/store";
import { CodeEntity } from "../entities/CodesEntity";
import { codesListParamsEntity } from "../entities/CodesListParamsEntity";
import { ProductPackageCodesEntity } from "../entities/ProductPackageCodesEntity";

export const loadCodes = createAction('[Auth Login] load Api',
  props<{code:CodeEntity[]}>()
)
// export const updateCodesListParams = createAction('[Codes] Update list params', 
// props<{keyCode:codesListParamsEntity}>())

export const updateCodesListParams = createAction('[Codes] Update list params',
  props<codesListParamsEntity>()
)
export const updateProductPackageCodesListParams = createAction('[Product package codes] Update list params',
  props<ProductPackageCodesEntity>()
)
