export interface usersListParamsEntity{
    filters: {
      keyword: string,
    },
    page: number,
    size: number
  }
  
  export const usersListParamsEmpty: usersListParamsEntity = {
    filters: {
      keyword: '',
    },
    page: 1,
    size: 10
  }

  export interface profilesListParamsEntity{
    filters: {
      keyword: string,
    },
    page: number,
    size: number
  }
  
  export const profilesListParamsEmpty: profilesListParamsEntity = {
    filters: {
      keyword: '',
    },
    page: 1,
    size: 10
  }
    
    