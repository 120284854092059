import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-text-icon',
  templateUrl: './input-text-icon.component.html',
  styleUrls: ['./input-text-icon.component.scss']
})
export class InputTextIconComponent implements OnInit {

  @Input() placeholder:string = '';
  @Input() icon:string = '';
  @Input() h:string = '';
  @Input() title:string = '';
  @Input() class:string = '';
  @Input() colorType:string = '';
  @Input() position:string = '';
  @Input() colorText:string = '';
  @Input() formcontrol?:FormControl

  constructor() { }

  ngOnInit(): void {
  }

}
