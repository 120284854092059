import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/modules/app-common/domain/state.ts/app.state";
import { CodesState } from "./CodesState";

 
export const selectLoadCodes = (state:AppState) => state.codes;
 
export const selectCodesListParams = createSelector(
  selectLoadCodes,
  (state: CodesState) => state.codeParams
)

export const selectProductPackageCodesListParams = createSelector(
  selectLoadCodes,
  (state: CodesState) => state.listParams
)
