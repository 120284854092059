import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CLIENT_FILTER_URL, OFFICE_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { PRODUCT_CATEGORIES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { offices } from '../../constants/OfficesConstants';
import { officeListParamsEmpty, officeListParamsEntity } from '../../entities/OfficesListParamsEntity';
import { updateOfficeListParams } from '../../state/offices.actions';
import { selectOfficeListParams } from '../../state/offices.selectors';
import { OfficesTemplateModalNewOrderComponent } from '../offices-template-modal-new-order/offices-template-modal-new-order.component';
import { OfficesTemplateModalComponent } from '../offices-template-modal/offices-template-modal.component';

@Component({
  selector: 'app-offices-template-filter',
  templateUrl: './offices-template-filter.component.html',
  styleUrls: ['./offices-template-filter.component.scss']
})
export class OfficesTemplateFilterComponent implements OnInit {

  listOffices:any
  listCust:any
  listCat:any
  public offices = offices
  listParams: officeListParamsEntity= officeListParamsEmpty
  constructor(
    public modalService:NgbModal,
    private apiService : ApiService,
    private store:Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.select(selectOfficeListParams).subscribe(params => this.listParams = params)
    this.apiService.post(CLIENT_FILTER_URL, {}).subscribe(clients => this.listCust = clients)
    this.apiService.post(PRODUCT_CATEGORIES_FILTER_URL, {}).subscribe(category => this.listCat = category)
  }
  openModalIncome() {
    const modalRef = this.modalService.open(OfficesTemplateModalComponent,{
      size: 'xxl'
    })
  }
  openModalNewOrder(){
    const modalRef = this.modalService.open(OfficesTemplateModalNewOrderComponent,{
      size: 'xxl'
    })

  }
  updateList(keyword: string){

    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: keyword
      }
    }))
  }
  filterKeyWordCli(nameCli: any){
    console.log(nameCli)
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: nameCli
      }
    }))
  }
  filterIdCli(id:number){
    console.log(id)
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_client_id: id
      }
    }))
  }
  filterKeyWordCat(nameCat: any){
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: nameCat
      }
    }))
  }
  filterIdCat(id:number){
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_products_categories_id: id
      }
    }))
  }

 

}

