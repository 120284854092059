import { Component, OnInit } from '@angular/core';
import { USER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';

@Component({
  selector: 'app-users-template-list',
  templateUrl: './users-template-list.component.html',
  styleUrls: ['./users-template-list.component.scss']
})
export class UsersTemplateListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   
  }

}
