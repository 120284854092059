import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { OFFICE_URL, termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { closeModals, closeModalsDone, fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { selectCloseModals } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { INCOMES_URL } from '../../constants/IncomeConstants';
import { tableOrder } from '../../constants/TablesIncome';

@Component({
  selector: 'app-income-template-modal-confirm',
  templateUrl: './income-template-modal-confirm.component.html',
  styleUrls: ['./income-template-modal-confirm.component.scss']
})
export class IncomeTemplateModalConfirmComponent implements OnInit {

  active = false
  head = tableOrder
  loading:boolean=false
  units:any
  products:any
  @Input() url:string = '';
  @Input() orderBuy:any
  @Input() order:any
  @Input() codeOrder:any
  @Input() packages?:any
  
  orderBuyer:any
  public termConstants = termConstants
  constructor( 
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    private apiService:ApiService,
    public store:Store<AppState>,
  ) {
    this.store.select(selectCloseModals).subscribe(data => {
      if(data){
        this.activeModal.close()
        this.store.dispatch(closeModalsDone())
      }
    })
  }

  ngOnInit(): void {
    console.log(this.order)
    if(this.url===OFFICE_URL){
      this.active = true
      this.products=this.order[4]?.packages.length
      let numbers = this.order[4]?.packages.map((item:any)=>parseInt(item.amount))
      let total = numbers.reduce((a:any, b:any) => a + b, 0);
      this.units = total
    }else{
      this.active = true
      this.products=this.packages.length
      let numbers = this.packages.map((item:any)=>parseInt(item.amount))
      let total = numbers.reduce((a:any, b:any) => a + b, 0);
      this.units = total

    }
    
  }
  update($event:KeyboardEvent){
    if($event.key==='Enter'){
    let numbers = this.order[4]?.packages.map((item:any)=>parseInt(item.amount))
    let total = numbers.reduce((a:any, b:any) => a + b, 0);
    this.units = total
    }
  }
  confirmOrder(){
    if(this.url===OFFICE_URL){
      const Pack={"products":this.order[4]?.packages.map((item:any) => {return {'id': item.appProducts.id, 'amount': item.amount}}),"app_client_headquarters_id":this.order[0].headquarter}
      this.orderBuyer={
        ...Pack,
      }
      this.apiService.post(this.url,this.orderBuyer).subscribe((res)=>{
        this.store.dispatch(closeModals())
        this.store.dispatch(fetchList())
      })
    }else{
      const Pack={"packages":this.packages.map((item:any) => {return {'id': item.id, 'amount': item.amount}})}
      this.orderBuyer={
        ...Pack,
      }
      this.apiService.post(this.url,this.orderBuyer).subscribe(res => {
        this.store.dispatch(closeModals())
        this.store.dispatch(fetchList())
      })
    }
      
  }

}
