import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PROFILE_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { users } from '../../constants/UsersConstants';
import { usersListParamsEmpty, usersListParamsEntity } from '../../entities/UsersListParamsEntity';
import { loadProfiles, updateProfilesListParams } from '../../state/users.actions';
import { selectProfilesListParams } from '../../state/users.selectors';
import { UsersTemplateModalNewprofileComponent } from '../users-template-modal-newprofile/users-template-modal-newprofile.component';

@Component({
  selector: 'app-users-template-filter-profile',
  templateUrl: './users-template-filter-profile.component.html',
  styleUrls: ['./users-template-filter-profile.component.scss']
})
export class UsersTemplateFilterProfileComponent implements OnInit {

  prof:any
  listProfiles:any
  public users = users
  listParams: usersListParamsEntity = usersListParamsEmpty
  constructor(
    public modalService:NgbModal,
    private apiService:ApiService,
    private store :Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.select(selectProfilesListParams).subscribe(params => this.listParams = params) 
  }
  updateProfile($event:any){
    this.prof = $event
  }
  searchProfile(){
    if(this.prof === ''){
      this.apiService.post(PROFILE_URL+'filter', {}).subscribe((response:any) => {
        this.store.dispatch(loadProfiles({profile:response}))
      })
    }else{
      let key = this.listProfiles.filter((el:any)=>el.name === this.prof)
      this.store.dispatch(loadProfiles({profile:key}))
    }
    
  }
  openModalNewProfile() {
    const modalRef = this.modalService.open(UsersTemplateModalNewprofileComponent,{
      size: 'lg'
    })
  }
  updateList(keyword: string){
    this.store.dispatch(updateProfilesListParams({keyProfile:{
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: keyword
      }
    }}))
  }

}
