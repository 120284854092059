<div class="card p-4 bg-white">
    <form [formGroup]="idForm">
        <div class="d-flex justify-content-between">
            <app-title [title]="title + ' de perfiles'" [class]="'fw-bolder mb-2'" [fs]="'5'"></app-title>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
        </div>
       
        <div class="d-flex">
            <div class="col">
                <app-title [title]="users.modalProfile.icon"></app-title>
                <div class="card-1 col-6 text-center border bg-light">
                  <img [src]="image" class="p-2" width="100" height="100">
                  <div class="input-group">
                    <input type="file" class="form-control" id="inputGroupFile01" name="profile" (change)="onFileSelect($event)" style="display: none;">
                    <label class="label button-blue d-flex align-items-center justify-content-center rounded-circle input-group-text position-absolute top-100 start-50 translate-middle p-0" for="inputGroupFile01">
                      <img src="./assets/Trazado 23.png" class="button-img p-1 clickable" width="28">
                    </label>
                  </div> 
                </div>          
            </div>
            <div class="col p-3">
                <div class="mb-2">
                    <div class="col-8 form-group">
                        <label class="control-label text-secondary">Nombre del perfil</label>
                        <div class="input-group mb-3">
                          <input class="form-control form-control-sm" type="text" formControlName="name">
                        </div>         
                      </div>            
                </div>
            </div>
        </div>
        <!--<div class="d-flex w-100 border-bottom">
            <app-title [title]="users.modalProfile.permissions" [class]="'mb-2'" [fs]="'5'"></app-title>
        </div>
        <div class="mt-3">
            <app-input-button></app-input-button>
        </div>
        <div class="row mt-3">
            <div class="col-4" *ngFor="let item of options">
                <app-text-checkbox [title]="item.title"></app-text-checkbox>
            </div>  
    
        </div> -->
       
    </form>
   
    <div class="d-flex justify-content-center mt-3">
        <button class="btn button-blue btn-sm me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="createProfile()">{{termConstants.modal.save}}</button>
    </div>  
</div>
