import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
declare global {
  interface Window {
    initMap: () => void;
  }
}
@Component({
  selector: 'app-map-select',
  templateUrl: './map-select.component.html',
  styleUrls: ['./map-select.component.scss']
})
export class MapSelectComponent implements OnInit {
  LatLong:any
  lista:any
  position:any
  @Input() model:any
  @Output() sendPolygon = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
    var position:any=[];
    
    const loader = new Loader({
      apiKey: "AIzaSyCNgY-Uk__GuFfS-u-wCxLyWi9oC8PIW8A",
      version: "weekly",
      libraries: ["drawing"]
    });
    loader.load().then(() => {
      const myLatlng = { lat: 4.60971, lng: -74.08175 };
      const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: myLatlng,
        zoom: 12,
      });
      if(this.model===undefined){
        this.model = position
        const bermudaTriangle = new google.maps.Polyline({
          path: position,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
        });
        bermudaTriangle.setMap(map);
        map.addListener("click", addLatLng);
        function addLatLng(event: google.maps.MapMouseEvent) {
        var latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
        position.push(latlng)
        const path = bermudaTriangle.getPath();
      
        // Because path is an MVCArray, we can simply append a new coordinate
        // and it will automatically appear.
        path.push(event.latLng as google.maps.LatLng);
      }
      }else{
        var LatLong = this.model
        const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
          center: LatLong[0],
          zoom: 12,
        });  
        const bermuda = new google.maps.Polygon({
          paths: LatLong,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
        });
        bermuda.setMap(map);
      }   
      // Construct the polygon.
      

    })
    
    
   
  }
  sendLatLng(){
    this.sendPolygon.emit(this.model)   
  } 
  
  
   
      
    
   
   
}

