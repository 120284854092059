import { Component, Input, OnInit } from '@angular/core';
import { stock } from '../../constants/StockConstants';
import { STOCK_PRODUCT_EMPTY } from '../../constants/StockEmpty';
import { stockProductEntity } from '../../entities/StockEntities';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StockTemplateModalSlotComponent } from '../../templates/stock-template-modal-slot/stock-template-modal-slot.component';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  public stock = stock
  @Input() dateProduct:any
  @Input() active:boolean = false
  constructor(
    public modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }
  openModalSlot() {
   
  }

}
