import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { AREA_FILTER_URL, PROFILE_URL, termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { options, users } from '../../constants/UsersConstants';

@Component({
  selector: 'app-users-template-modal-newprofile',
  templateUrl: './users-template-modal-newprofile.component.html',
  styleUrls: ['./users-template-modal-newprofile.component.scss']
})
export class UsersTemplateModalNewprofileComponent implements OnInit {
  item:any
  idForm!:FormGroup
  title:any
  image:any
  imageObj: any
  public users = users
  public termConstants = termConstants
  public options = options
  icons = ICONS_CONSTANTS
  listArea:any
  constructor(
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public formBuilder:FormBuilder,
    private sanitizer:DomSanitizer,
    private apiService:ApiService,
  ) { 
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),
      image: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.apiService.post(AREA_FILTER_URL,{}).subscribe((area:any)=> this.listArea = area)
    this.title=this.item ? 'Edición': 'Creación'
    if(this.item){
      this.image = this.item.imageUrl
      this.idForm.setValue({
        name: this.item.name,
        image: this.item.imageUrl || null,
      })
    }else{
      this.image = './assets/descarga.jpg'
    }
  }
  openAlert(){
    const modalRef = this.modalService.open(ModalAlertComponent,{
      size: 'sm'
    });modalRef.componentInstance.content = '¿Desea guardar la información?',
    modalRef.componentInstance.buttonMain = 'Guardar'
  }
  onFileSelect(event:any) {
    this.imageObj = event.target
    this.image = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.target.files[0]))
  }
  createProfile(){
    if(this.item){
      this.apiService.put(PROFILE_URL+this.item.id, this.idForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
        
        var formData = new FormData();
        formData.append('image', this.imageObj.files[0])

        this.apiService.post(PROFILE_URL+this.item.id, formData).subscribe((ress)=>{
          
        })
      })
      
    }else{
      this.apiService.post(PROFILE_URL, this.idForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
      })
    }
    
  }

}
