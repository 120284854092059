import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PRODUCT_PACKAGES_FILTER_URL, PRODUCT_PACKAGES_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { productEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { PRODUCT_URL } from '../../constants/TermsConstants';
import { ApiService } from '../../services/api.service';
import { AppState } from '../../state.ts/app.state';
import { fetchList, rechargePage } from '../../state.ts/common.actions';
import { selectFilterProviders } from '../../state.ts/common.selectors';

@Component({
  selector: 'app-modal-new-packing',
  templateUrl: './modal-new-packing.component.html',
  styleUrls: ['./modal-new-packing.component.scss']
})
export class ModalNewPackingComponent implements OnInit {

  listProducts:productEntity []= []
  idForm!:FormGroup
  @Input() item?:any
  title:string= ''
  @Input() id:number = 0;
  products: productEntity[] = []
  prod:any
  loading$ = false
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService: ApiService,
    public store:Store<AppState>,
    public router: Router
  ) { 
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),
      code: new FormControl(''),
      image: new FormControl(''),
      quantity: new FormControl(''),
      app_products_id: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.store.select(selectFilterProviders).subscribe(data => {
      this.apiService.post(PRODUCT_URL+'filter',{}).subscribe(response => {
        this.products = <productEntity[]>response
      })
    })  
    this.title=this.item ? 'Edición': 'Crear'
    if(this.item){
      this.idForm.setValue({
        name:this.item.name,
        code:this.item.code,
        image:this.item.image,
        quantity:this.item.quantity,
        app_products_id:this.item.appProducts.id,
      })
    }
  }

  sendProviders(){
    if(this.item){
      this.apiService.put(PRODUCT_PACKAGES_URL + this.item.id, this.idForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(fetchList())
      })
      
    }else{
      this.apiService.post(PRODUCT_PACKAGES_URL, this.idForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(fetchList())
      })
    }
    this.store.dispatch(rechargePage({loading:this.loading$}))
  }

}
       