import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WAREHOUSES_URL } from '../../constants/TermsConstants';
import { ApiService } from '../../services/api.service';
import { fetchList } from '../../state.ts/common.actions';
import { AppState } from '../../state.ts/app.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-modal-edit-cellar',
  templateUrl: './modal-edit-cellar.component.html',
  styleUrls: ['./modal-edit-cellar.component.scss']
})
export class ModalEditCellarComponent implements OnInit {
  idForm!:FormGroup
  title:string= ''
  @Input() item?:any
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService: ApiService,
    public store:Store<AppState>,
  ) {
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.title=this.item ? 'Edición': 'Crear'
    if(this.item){
      this.idForm.setValue({
        name:this.item.name,
        logo:this.item.logo,
      })
    }
  }
  sendProviders(){
    if(this.item){
      this.apiService.put(WAREHOUSES_URL+this.item.id, this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
      
    }else{
      this.apiService.post(WAREHOUSES_URL, this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
    }

  }

}
