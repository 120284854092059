import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { offices } from '../../constants/OfficesConstants';
import { VEHICLE_EMPTY } from '../../constants/OfficesEmpty';
import { vehicleEntity } from '../../entities/OfficesEntities';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { Store } from '@ngrx/store';
import { VEHICLE_FILTER_URL, VEHICLE_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';

@Component({
  selector: 'app-calcule-volumen',
  templateUrl: './calcule-volumen.component.html',
  styleUrls: ['./calcule-volumen.component.scss']
})
export class CalculeVolumenComponent implements OnInit {

  @Input() select:number = 0;
  listVeh:any
  public offices = offices
  @Input() dateVehicle:vehicleEntity = VEHICLE_EMPTY
  @Output() sendItemVehicle = new EventEmitter()
  model:number = 0;
  constructor(
    public apiService: ApiService,
    public store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.apiService.post(VEHICLE_FILTER_URL,{}).subscribe((ress)=>{
      this.listVeh = ress
      this.store.dispatch(fetchList())
    })
  }
  sendIdVehicle(){
    this.sendItemVehicle.emit(this.model)
  }

}
