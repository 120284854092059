<app-title [title]="'Listas de separación'"  [fs]="'3 fw-bolder mb-3'"></app-title> 
<div class="d-flex mt-3">
    <div>
      <div class="d-flex">
        <app-input-search (search)="updateList($event)"></app-input-search>
        <app-dropdown
          class="ms-2"
          [list]="listCust"
          [nameDefault]="'Clientes'"
          (searchKeyWord)="filterKeyWordCli($event)"
          (searchIdItem)="filterIdCli($event)">
        </app-dropdown>
        <app-dropdown
          class="ms-2"
          [list]="listCat"
          [nameDefault]="'Zona'"
          (searchKeyWord)="filterKeyWordCat($event)"
          (searchIdItem)="filterIdCat($event)">
        </app-dropdown>
      </div>         
    </div>
</div> 