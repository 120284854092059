export const users = {
    cardProfile:{
        edit: 'Editar'
    },
    cardUser:{
        edit: 'Editar'
    },
    filter: {
        newUser: 'Crear usuario',
        newProfile: 'Crear perfil'
    },
    modalUser: {
        personInformation: 'Información personal',
        imgUser: './assets/profile.png',
        name: 'Nombre',
        cc: 'Cédula',
        email: 'Correo electrónico',
        adminInformation: 'Información administrativa',
        profile: 'Perfil',
        cellar: 'Bodega',
        password: 'Contraseña',
        confirmPassword: 'Confirmar Contraseña'
    },
    modalProfile: {
        editProfile: 'Edicion de perfiles',
        icon: 'Icono',
        imgProfile: './assets/oper-profile.png',
        nameProfile: 'Nombre del perfil',
        area: 'Área',
        permissions: 'Permisos',

    }
}
export const options = [
    {
        title: 'Control total'
    },
    {
        title: 'Optimizar'
    },
    {
        title: 'Registrar productos'
    },
    {
        title: 'Despachar productos'
    },
    {
        title: 'Registrar productos'
    },
    {
        title: 'Despachar productos'
    },

]