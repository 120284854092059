export interface incomeListParamsEntity{
  filters: {
    keyword: string,
    app_providers_id: number
    app_products_id: number
  },
  page: number,
  size: number
}

export const incomeListParamsEmpty: incomeListParamsEntity = {
  filters: {
    keyword: '',
    app_providers_id: 0,
    app_products_id:0,
  },
  page: 1,
  size: 10,
}