<div class="card p-1 mb-3">
    <div class="d-flex align-items-center">
        <div class="card-1">
            <app-title [title]="optimize.levelCard.withoutStarting" [fs]="'6'" [colorText]="'color-1 mb-2 ps-3'" *ngIf="dateOptimize.value===0"></app-title>
            <app-title [title]="optimize.levelCard.optimized" [fs]="'6'" [colorText]="'color-1 mb-2 ps-3'" *ngIf="dateOptimize.value>0 && dateOptimize.value<100"></app-title>
            <app-title [title]="optimize.levelCard.finalized" [fs]="'6'" [colorText]="'color-1 mb-2 ps-3'" *ngIf="dateOptimize.value===100"></app-title>
            <div class="border-end ps-3 pe-5">
                <button class="btn d-flex align-items-center" *ngIf="dateOptimize.value===0" (click)="openModalSelect()">Iniciar</button>
                <button class="btn d-flex align-items-center" *ngIf="dateOptimize.value>0 && dateOptimize.value<100" (click)="openAlert()">Cancelar</button>
                <button class="btn d-flex opacity-50 align-items-center" *ngIf="dateOptimize.value===100">Iniciar</button>
                <app-title [title]="dateOptimize.value + '%'" [colorText]="'success fw-bolder mt-1'"></app-title>
                <app-progress-bar [value]="dateOptimize.value" [colorBar]="'success'"></app-progress-bar>
            </div>
        </div>
        <div class="card-2 d-flex">
            <div class=" ps-4 pe-4">
                <app-title [title]="optimize.levelCard.slotOpt" [fs]="'6'" [colorText]="'secondary'" *ngIf="dateOptimize.value===0"></app-title>
                <app-title [title]="optimize.levelCard.optimized" [fs]="'6'" [colorText]="'secondary'" *ngIf="dateOptimize.value>0 && dateOptimize.value<100"></app-title>
                <app-title [title]="optimize.levelCard.optimize" [fs]="'6'" [colorText]="'secondary opacity-50'" *ngIf="dateOptimize.value===100"></app-title>
                <div class="d-flex align-items-center">
                    <app-optimize-slot-card [colorBar]="'info'" [colorText]="'info'" [section]="dateOptimize.sectionOpt" [value]="dateOptimize.valueOpt"  *ngIf="dateOptimize.value<100"></app-optimize-slot-card>
                    <app-optimize-slot-card [colorBar]="'info'" [colorText]="'info'" [opacity]="'50'" [section]="dateOptimize.sectionOpt" [value]="dateOptimize.valueOpt" *ngIf="dateOptimize.value===100"></app-optimize-slot-card>
                   
                </div>
            </div>
            <div class="d-flex align-items-center">                                                                     
              <i class="bi bi-{{icons.FILTER_RIGHT}} text-info fs-3"></i>     
            </div>          
            <div class="ps-4 pe-4">
                <app-title [title]="optimize.levelCard.slotObj" [fs]="'6'" [colorText]="'secondary'" *ngIf="dateOptimize.value===0"></app-title>
                <app-title [title]="optimize.levelCard.optimized" [fs]="'6'" [colorText]="'secondary'" *ngIf="dateOptimize.value>0 && dateOptimize.value<100"></app-title>
                <app-title [title]="optimize.levelCard.optimize" [fs]="'6'" [colorText]="'secondary opacity-50'" *ngIf="dateOptimize.value===100"></app-title>
                <div>
                    <app-optimize-slot-card [colorBar]="'info'" [colorText]="'info'" [section]="dateOptimize.sectionObj" [value]="dateOptimize.valueObj" *ngIf="dateOptimize.value<100"></app-optimize-slot-card> 
                    <app-optimize-slot-card [colorBar]="'info'" [colorText]="'info'" [opacity]="'50'" [section]="dateOptimize.sectionObj" [value]="dateOptimize.valueObj" *ngIf="dateOptimize.value===100"></app-optimize-slot-card> 
                </div>
            </div>
        </div>
        <img src="./assets/slot-producer.png">
        <div class="card-3 d-flex">
            <div class="ps-4 pe-4">
                <app-title [title]="optimize.levelCard.result" [fs]="'6'" [colorText]="'secondary'" *ngIf="dateOptimize.value===0"></app-title>
                <app-title [title]="optimize.levelCard.result" [fs]="'6'" [colorText]="'secondary opacity-50'"  *ngIf="dateOptimize.value>0 && dateOptimize.value<100"></app-title>
                <app-title [title]="optimize.levelCard.result" [fs]="'6'" [colorText]="'warning'" *ngIf="dateOptimize.value===100"></app-title>
                <div>
                    <app-optimize-slot-card [colorBar]="'success'" [colorText]="'success'" [section]="dateOptimize.sectionOpt" [value]="dateOptimize.valueOpt-dateOptimize.valueOpt" *ngIf="dateOptimize.value===0 || dateOptimize.value===100"></app-optimize-slot-card> 
                    <app-optimize-slot-card [colorBar]="'success'" [colorText]="'success'" [opacity]="'50'" [section]="dateOptimize.sectionOpt" [value]="dateOptimize.valueOpt-dateOptimize.valueOpt" *ngIf="dateOptimize.value>0 && dateOptimize.value<100"></app-optimize-slot-card> 
                </div>
            </div>
            <div class="ps-4 pe-4">
                <app-title [title]="'.'" [fs]="'6'" [colorText]="'white'"></app-title>
                <div>
                    <app-optimize-slot-card [colorBar]="'success'" [colorText]="'success'" [section]="dateOptimize.sectionObj" [value]="dateOptimize.valueObj+dateOptimize.valueOpt" *ngIf="dateOptimize.value===0 || dateOptimize.value===100"></app-optimize-slot-card> 
                    <app-optimize-slot-card [colorBar]="'success'" [colorText]="'success'" [opacity]="'50'" [section]="dateOptimize.sectionObj" [value]="dateOptimize.valueObj+dateOptimize.valueOpt" *ngIf="dateOptimize.value>0 && dateOptimize.value<100"></app-optimize-slot-card> 
                </div>
            </div>
        </div>
      
        <div *ngIf="dateOptimize.value===0">
            <app-title [title]="optimize.levelCard.productsTraslate" [fs]="'5'" [colorText]="'secondary'"></app-title>
            <app-optimize-card-product [dateProduct]="dateProduct" *ngFor="let dateProduct of dateProducts" ></app-optimize-card-product>
        </div>
        <div *ngIf="dateOptimize.value>0">
            <app-title [title]="optimize.levelCard.productsTraslate" [fs]="'5'" [colorText]="'secondary opacity-50'"></app-title>
            <app-optimize-card-product [dateProduct]="dateProduct" *ngFor="let dateProduct of dateProducts" [opacity]="'50'"></app-optimize-card-product>
        </div>
    </div>
</div>