import { createReducer, on } from "@ngrx/store";
import { officeListParamsEmpty } from "../entities/OfficesListParamsEntity";
import { OfficesState } from "./OfficesState";
import { updateOfficeListParams } from "./offices.actions";


export const initialState: OfficesState = {listParams: officeListParamsEmpty}

export const officesReducer = createReducer(
  initialState,
  on(updateOfficeListParams, (state, listParams) => ({
    ...state,
    listParams
  })),
);