import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { OFFICE_ASSIGN_URL, OFFICE_FILTER_URL, OFFICE_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { IncomeTemplateModalIncomeComponent } from 'src/app/modules/income/domain/templates/income-template-modal-income/income-template-modal-income.component';
import { offices } from '../../constants/OfficesConstants';
import { tableItems } from '../../constants/TableOffices';
import { officeListParamsEmpty, officeListParamsEntity } from '../../entities/OfficesListParamsEntity';
import { updateOfficeListParams } from '../../state/offices.actions';
import { selectOfficeListParams } from '../../state/offices.selectors';
import { selectFetch } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { fetchListDone } from 'src/app/modules/app-common/domain/state.ts/common.actions';


@Component({
  selector: 'app-card-office',
  templateUrl: './card-office.component.html',
  styleUrls: ['./card-office.component.scss'],
})
export class CardOfficeComponent implements OnInit {


  public offices = offices
  public head = tableItems
  icons = ICONS_CONSTANTS
  @Input() show: boolean = false;
  i:any
  packages:any
  purchaseOrders:any[]=[]
  loading:boolean= false
	pageSize = 7;
  collectionSize:any
	countries: any;
  listParams: officeListParamsEntity = officeListParamsEmpty
  constructor(
    public apiService:ApiService,
    public store:Store<AppState>,
    public modalService:NgbModal
  ) {
    this.store.select(selectFetch).subscribe(fetch => {
      if(fetch){
        this.apiService.post(OFFICE_FILTER_URL, this.listParams).subscribe((data:any) => this.purchaseOrders = data )
        this.store.dispatch(fetchListDone())
      }
    })
  }

  ngOnInit(): void {
    this.store.select(selectOfficeListParams).subscribe(params =>{
      this.listParams = params
      this.apiService.post(OFFICE_FILTER_URL,params).subscribe((res:any)=>{
        this.purchaseOrders = res
        this.loading = true
      })
      this.apiService.post(OFFICE_URL+'length').subscribe((length:any)=>{
        this.collectionSize = length
      })
    })
  }
  rotated(index:number){   
    this.packages = this.purchaseOrders[index]
    console.log(this.purchaseOrders)
  }
  openModalIncome() {
    const modalRef = this.modalService.open(IncomeTemplateModalIncomeComponent,{
      size: 'xl'
    })
  }
  updateListParams(){
    window.scroll(0, 0)
    this.store.dispatch(updateOfficeListParams({...this.listParams}))
  }
}