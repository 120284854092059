import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CodesRoutingModule } from './codes-routing.module';
import { FeatureCodesComponent } from './feature-codes/feature-codes.component';
import { AppModule } from 'src/app/app.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { CodesTemplateFilterComponent } from './domain/templates/codes-template-filter/codes-template-filter.component';
import { CodesTemplateListComponent } from './domain/templates/codes-template-list/codes-template-list.component';
import { CodesCardComponent } from './domain/organisms/codes-card/codes-card.component';
import { CodesTemplateModalComponent } from './domain/templates/codes-template-modal/codes-template-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CodesCardProductComponent } from './domain/organisms/codes-card-product/codes-card-product.component';
import { NavModule } from '../nav/nav.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatureCodesAsignComponent } from './feature-codes-asign/feature-codes-asign.component';
import { CodesTemplateListAsignComponent } from './domain/templates/codes-template-list-asign/codes-template-list-asign.component';


@NgModule({
  declarations: [
    FeatureCodesComponent,
    CodesTemplateFilterComponent,
    CodesTemplateListComponent,
    CodesCardComponent,
    CodesTemplateModalComponent,
    CodesCardProductComponent,
    FeatureCodesAsignComponent,
    CodesTemplateListAsignComponent
  ],
  imports: [
    CommonModule,
    CodesRoutingModule,
    AppCommonModule,
    NgbModule,
    NavModule,
    FormsModule,
    ReactiveFormsModule,
    
  ]
})
export class CodesModule { }
