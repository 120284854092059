<div class="card p-3 bg-white">
    <div class="d-flex justify-content-between">
        <app-title [title]="'Crear orden de despacho'" [class]="'fw-bolder mb-2'" ></app-title>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <app-title [title]="offices.newOrder.select" [colorText]="'secondary'" [class]="'fw-bolder mb-2'"></app-title>
    <div class="d-flex mt-2">
      <div class="me-3 col-2">
        <app-title [title]="'Proveedores'" [colorText]="'secondary'"></app-title>
        <div class="pe-4">
          <div class="d-flex justify-content-center mt-3" *ngIf="!loader">
            <div class="spinner-border text-danger" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="mt-3" *ngIf="loader">
            <app-card-client [idSelect]="idSelect" (customerItem)="filterPackage($event)"></app-card-client>
          </div> 
        </div>
      </div>
      <div class="col ps-4">
            <div class="me-3">
                
                <div class="d-flex mb-3">
                  <div class="form-group me-3">
                    <div>
                      <app-title [title]="client?.name" [colorText]="'color-1 fw-bolder mb-3'" *ngIf="client?.name"></app-title>
                      <app-title [title]="'.'" [colorText]="'white fw-bolder mb-3'" *ngIf="!client?.name"></app-title>
                    </div>
                    <app-dropdown [nameDefault]="'Sede'" [list]="listSed" *ngIf="checkClient" (searchIdItem)="AddItemHead($event)"></app-dropdown>
                    <app-dropdown [nameDefault]="'Sede'" [active]="true" *ngIf="!checkClient"></app-dropdown>
                  </div>
                  <div>
                      <label class="control-label text-secondary user-select-none mb-3">Fecha *</label>
                      <div class="input-group input-group-sm" *ngIf="checkSed">
                        <input class="form-control" placeholder="yyyy-mm-dd" type="date" (change)="AddDate()" [(ngModel)]="date"/>
                      </div>
                      <div class="input-group input-group-sm" *ngIf="!checkSed">
                        <input class="form-control" placeholder="yyyy-mm-dd" type="date" disabled/>
                      </div>        
                  </div>
                  <div class="form-group ms-3 me-3">
                    <label class="control-label text-secondary user-select-none mb-3">Hora *</label>
                    <div class="input-group"  *ngIf="!checkDate">
                      <input class="form-control form-control-sm" type="text" disabled/>
                    </div>
                    <div class="input-group" *ngIf="checkDate">
                      <input class="form-control form-control-sm border-blue" type="text" placeholder="ej. 10:00 am" (change)="AddHour()" [(ngModel)]="hour">
                    </div>
                  </div>
                  
                  <div class="mt-5">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        Cliente recoge
                      </label>
                    </div>
                  </div>    
                </div>
                <app-title [title]="offices.newOrder.products" [class]="'mb-2'" [colorText]="'secondary'"></app-title>
                <div class="d-flex">
                    <app-input-search (search)="updateList($event)"></app-input-search>
                    <div class="col-2 ms-3 me-3">
                      <app-dropdown [list]="listCat" [nameDefault]="'Categorias'"></app-dropdown>
                    </div>
                </div>
            </div>
            <div class="card-over-red mt-4 pe-3">
              <table class="table" >
                <tr class="d-flex">
                  <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
                </tr>
                <div class="d-flex justify-content-center" *ngIf="!loading">
                  <div class="text-center text-secondary mt-5">
                    <h5>Por favor</h5>
                    <h5>Selecciona el cliente, la sede y la fecha</h5>
                    <h5>En el menu izquierdo de esta ventana</h5>
                    <img src="./assets/select-providers.png" >
                  </div>
                </div>
                <div *ngIf="loading">
                  <tr class="head d-flex" *ngFor="let Table of stock; index as i">
                    <app-row-item [src]="'./assets/Grupo 67.png'" [active]="Table.active" class="col-1"></app-row-item>            
                    <app-row-item [title]="Table.appProducts.code" class="bg-white col-1 border border-light" ></app-row-item>
                    <app-row-item [title]="Table.appProducts.name" [src]="'./assets/image.png'" class="bg-white col border border-light" ></app-row-item>
                    <td class="col-2 border border-light">
                      <div class="input-group input-group-sm pe-5">
                        <input class="form-control border-0" value="" type="text" [(ngModel)]="Table.quantity" readonly>
                      </div>                  
                    </td>
                    <td class="col-2 border border-light">
                      <div class="input-group input-group-sm pe-5">
                        <input class="form-control" value="" type="text" [(ngModel)]="Table.multiple" (change)="sendProduct(stock, i, Table.multiple)">
                      </div>                  
                    </td>
                    <td class="col-2 border border-light">
                      <div class="input-group  input-group-sm pe-5">
                        <input class="form-control border-0" value="" type="text" [(ngModel)]="Table.amount" readonly>
                      </div>                  
                    </td>
                  </tr>
                </div> 
              </table>
            </div>
           
      </div>
    </div>
   
    <div class="d-flex justify-content-center mt-3">
        <button class="btn button-blue btn-sm me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="openModalConfirm()">{{termConstants.modal.save}}</button>
    </div> 
</div>