import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { INCOME_ASSIGN_URL, INCOME_FILTER_URL, PRODUCT_FILTER_URL, PROVIDER_FILTER_URL, termConstants, USER_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { providerEntity } from 'src/app/modules/app-common/domain/entities/ProviderEntity';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { closeModalsDone, fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { selectCloseModals } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { categoryEntity, productEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { income, INCOMES_URL } from '../../constants/IncomeConstants';
import { tableAsign } from '../../constants/TablesIncome';
import { incomeEntity } from '../../entities/IncomeEntities';
import { incomeListParamsEmpty, incomeListParamsEntity } from '../../entities/IncomeListParamsEntity';
import { updateIncomeListParams } from '../../state/income.actions';
import { IncomeTemplateModalProductsComponent } from '../income-template-modal-products/income-template-modal-products.component';

@Component({
  selector: 'app-income-template-modal-asign',
  templateUrl: './income-template-modal-asign.component.html',
  styleUrls: ['./income-template-modal-asign.component.scss']
})
export class IncomeTemplateModalAsignComponent implements OnInit {

    model: any;
    public termConstants = termConstants
    public income = income
    users:any
    packages:any
    listProv:providerEntity[] =[]
    listProd:categoryEntity[]=[]
    pack:any
    icons = ICONS_CONSTANTS
    head = tableAsign
    listOrder:any [] = []
    listOperator:any [] = []
    listParams: incomeListParamsEntity= incomeListParamsEmpty
   
  constructor(
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public store:Store<AppState>,
    public apiService:ApiService
  ) { 
    this.store.select(selectCloseModals).subscribe(close => {
      if(close){
        this.activeModal.close()
        this.store.dispatch(fetchList())
        this.store.dispatch(closeModalsDone())
      }
    })
  }

  ngOnInit(): void {
    this.apiService.post(INCOME_FILTER_URL, {
      filters: {
        status: 'unassigned'
      }
    }).subscribe((response:any) =>{
      this.packages = <incomeEntity[]>response
    })

    this.apiService.post(USER_FILTER_URL, {}).subscribe((response:any) =>{
      let key = response.filter((el:any)=>el.userProfiles.name === 'Operario')
      this.users = key
    })
    this.apiService.post(PROVIDER_FILTER_URL).subscribe(providers => this.listProv = <providerEntity[]>providers)
    this.apiService.post(PRODUCT_FILTER_URL).subscribe(categories => this.listProd = <categoryEntity[]>categories)

  }

  openAlert(){
    const asignOrder = {'users':this.listOperator}
    const modalRef = this.modalService.open(ModalAlertComponent, {
      size: 'sm'
    });
    modalRef.componentInstance.content = '¿Desea guardar la información?', 
    modalRef.componentInstance.item = {
      orders: this.listOrder,
      users: asignOrder.users
    } 
    modalRef.componentInstance.id = this.listOrder,
    modalRef.componentInstance.buttonMain = 'Guardar'
    modalRef.componentInstance.url = INCOME_ASSIGN_URL
  }
  openModalProduct(item:any){
    const modalRef = this.modalService.open(IncomeTemplateModalProductsComponent,{
      size: 'xl'
    });
    modalRef.componentInstance.packages = item
  }
  filterProviders(item:providerEntity){
    let lock = this.packages.map((pack:any)=>({'index': pack.providers.map((prov:any)=>prov.appProductPackages.appProducts.appProviders.id)}))
    let stu = lock.indexOf(16)
  }
  loadProviders(){
   this.packages =this.packages
   this.packages=[]
  }
  addOrder(id:number){
    let bus = this.listOrder.indexOf(id)
    if (bus === -1){
      this.listOrder.push(id)
    }else{
      this.listOrder.splice(bus, 1)
    }
  }
  addOperator(id:number){
    let bus = this.listOperator.indexOf(id)
    if (bus === -1){
      this.listOperator.push(id)
    }else{
      this.listOperator.splice(bus, 1)
    }
  }
  filterIncomes($event:any){

  }
  filterKeyProv(nameProvider: any){
    let key = this.listProv.filter((el:any)=>el.name.toLowerCase().indexOf(nameProvider.toLowerCase()) !== -1)
    this.store.dispatch(updateIncomeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_providers_id: key[0].id
      }
    }))
  }
  updateKeyProv(app_providers_id: number){
    this.store.dispatch(updateIncomeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_providers_id: app_providers_id
      }
    }))
  }
  updateAllProv(){
    this.apiService.post(INCOME_FILTER_URL,{}).subscribe((response:any) =>{
      this.listOrder = <incomeEntity[]>response
    } )
  }
  filterKeyProd(nameProduct: any){
    let key = this.listProv.filter((el:any)=>el.name.toLowerCase().indexOf(nameProduct.toLowerCase()) !== -1)
    this.store.dispatch(updateIncomeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_providers_id: key[0].id
      }
    }))
  }
  updateKeyProd(app_products_id: number){
    this.store.dispatch(updateIncomeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_products_id: app_products_id
      }
    }))
  }
  updateAllProd(){
    this.apiService.post(INCOME_FILTER_URL,{}).subscribe((response:any) =>{
      this.listOrder = <incomeEntity[]>response
    } )
  }
  filterOperators($event:any){

  }
  
}
