<div class="card p-3">
    <div class="d-flex justify-content-between">
        <app-title [title]="income.modal.products" [class]="'fw-bolder mb-2'" ></app-title>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="d-flex align-items-center mb-3">
        <img src="{{income.modal.imgCargue}}" class="me-2">
        <div class="me-3">
            <app-title [title]="income.modal.purchaseOrder" [colorText]="'secondary'"></app-title>
            <app-title [title]="packages.id" [colorText]="'info fw-bolder'"></app-title>
        </div>
        <div class="me-3">
            <app-title [title]="income.modal.provider" [colorText]="'secondary'"></app-title>
            <app-title [title]="packages.packages[0]?.appProductPackages.appProducts.appProviders.name" [colorText]="'info fw-bolder'"></app-title>
        </div>
        <div class="me-3">
            <app-title [title]="income.modal.volumen" [colorText]="'secondary'"></app-title>
            <app-title [title]="packages.id + ' m3'" [colorText]="'info fw-bolder'"></app-title>
        </div>
        <div>
            <app-title [title]="income.modal.products" [colorText]="'secondary'"></app-title>
            <app-title [title]="packages.packages.length" [colorText]="'info fw-bolder'"></app-title>
        </div>
    </div>
    <div>
      <table class="table" >
        <tr class="d-flex">
          <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
        </tr>
        <div>
          <tr class="head d-flex" *ngFor="let Table of datas;index as i">
            <app-row-item [title]="Table.appProductPackages.code" class="bg-white col-1 border border-light" ></app-row-item>
            <app-row-item [title]="Table.appProductPackages.appProducts.name" class="bg-white col border border-light" ></app-row-item>
            <app-row-item [title]="Table.appProductPackages.name" class="bg-white col border border-light" ></app-row-item>
            <app-row-item [title]="Table.appProductPackages.quantity" class="bg-white col-3 border border-light" ></app-row-item>
            
          </tr>
        </div> 
      </table>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn button-blue btn-sm" type="button" (click)="activeModal.dismiss('Cross click')">Regresar</button>
    </div>

</div>