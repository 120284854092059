import { Component, Input, OnInit } from '@angular/core';
import { optimize } from '../../constants/OptimizeConstants';

@Component({
  selector: 'app-optimize-card-product',
  templateUrl: './optimize-card-product.component.html',
  styleUrls: ['./optimize-card-product.component.scss']
})
export class OptimizeCardProductComponent implements OnInit {

  public optimize = optimize
  @Input() opacity:string='';
  @Input() dateProduct:any
  constructor() { }

  ngOnInit(): void {
  }

}
