import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { optimize } from '../../constants/OptimizeConstants';
import { OPTIMIZE_EMPTY } from '../../constants/OptimizeEmpty';
import { optimizeEntity } from '../../entities/OptimizeEntities';
import { OptimizeTemplateModalSelectComponent } from '../../templates/optimize-template-modal-select/optimize-template-modal-select.component';

@Component({
  selector: 'app-optimize-level-card',
  templateUrl: './optimize-level-card.component.html',
  styleUrls: ['./optimize-level-card.component.scss']
})
export class OptimizeLevelCardComponent implements OnInit {

  public optimize = optimize
  icons = ICONS_CONSTANTS
  @Input() value:number = 0;
  @Input() dateOptimize:optimizeEntity= OPTIMIZE_EMPTY
  dateProducts:any = [
    {
      code: 'Z-9501-6',
      image: './assets/product.png',
      product: 'SCOTCHGARD PROTEC & SHINE FLOOR PROTECTOR',
      units: '137'
    }
  ]
  constructor(public modalService:NgbModal) { }

  ngOnInit(): void {
  }
  openModalSelect(){
    const modalRef = this.modalService.open(OptimizeTemplateModalSelectComponent,{
      size: 'sm'
    })
  }
  openAlert(){
    const modalRef = this.modalService.open(ModalAlertComponent,{
      size: 'sm'
    });modalRef.componentInstance.content = '¿Desea cancelar la operacion?'
    modalRef.componentInstance.buttonMain = 'Cancelar'
  }

}
