<div class="d-flex">
    <div class="col">
        <app-title [title]="stock.slotCard.level" [class]="'fw-bolder'"></app-title> 
    </div>
    <div class="col">
        <app-title [title]="value + '%'" [class]="'fw-bolder'"></app-title> 
    </div>
</div>
<div>
    <app-progress-bar [colorBar]="'info'" [value]="value" [class]="'bg-danger'"></app-progress-bar> 
</div>
<div class="text-center">
    <img src="./assets/slot5.png" *ngIf="value === 100">
    <img src="./assets/slot4.png" *ngIf="value <= 99 && value > 66">
    <img src="./assets/slot3.png"  *ngIf="value <= 66 && value > 33">
    <img src="./assets/slot2.png" *ngIf="value <= 33 && value > 0">
    <img src="./assets/slot1.png" *ngIf="value <= 0">
</div>    
