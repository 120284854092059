import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { USER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { users } from '../../constants/UsersConstants';
import { usersListParamsEmpty, usersListParamsEntity } from '../../entities/UsersListParamsEntity';
import { loadUsers, updateUsersListParams } from '../../state/users.actions';
import { selectUsersListParams } from '../../state/users.selectors';
import { UsersTemplateModalNewuserComponent } from '../users-template-modal-newuser/users-template-modal-newuser.component';

@Component({
  selector: 'app-users-template-filter',
  templateUrl: './users-template-filter.component.html',
  styleUrls: ['./users-template-filter.component.scss']
})
export class UsersTemplateFilterComponent implements OnInit {

  usr:any
  listUsers:any
  public users = users
  listParams: usersListParamsEntity = usersListParamsEmpty
  constructor(
    public modalService:NgbModal,
    private apiService:ApiService,
    private store :Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.select(selectUsersListParams).subscribe(params => this.listParams = params)     
  }
  updateUser($event:any){
    this.usr = $event
  }
  searchUser(){
    if(this.usr === ''){
      this.apiService.post(USER_URL+'filter',{}).subscribe((response:any) => {
        this.store.dispatch(loadUsers({user:response}))
      })
    }else{
      let key = this.listUsers.filter((el:any)=>el.name === this.usr)
      this.store.dispatch(loadUsers({user:key}))
    }
    
  }
  openModalNewUser() {
    const modalRef = this.modalService.open(UsersTemplateModalNewuserComponent,{
      size: 'lg'
    })
  }
  updateList(keyword: string){
    this.store.dispatch(updateUsersListParams({keyUser:{
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: keyword
      }
    }}))
  }

}
