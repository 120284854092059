<div class="card p-4">
  <div class="d-flex justify-content-between">
      <h4>{{ title }}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>
  <div class="row mt-2">
      <div class="form-group col-12">
            <label class="control-label">Archivo</label>
            <input class="form-control" type="file" (change)="setFile($event)">
      </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-danger m-2 mt-3" role="status" *ngIf="loading">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center mt-3">
      <button class="btn button-blue btn-sm" type="button" (click)="activeModal.dismiss()">Cancelar</button>
      <button class="btn button-red-strong btn-sm ms-2" (click)="updateFile()" [disabled]="loading">Guardar</button>
  </div>

</div>