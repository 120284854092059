import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-text',
  templateUrl: './icon-text.component.html',
  styleUrls: ['./icon-text.component.scss']
})
export class IconTextComponent implements OnInit {

  @Input() icon:string | undefined = '';
  @Input() title:string = '';
  @Input() colorText:string = '';
  @Input() colorIcon:string = '';
  @Input() fs:string = '';
  @Input() src:string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
