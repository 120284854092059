<div class="card p-4">
    <div class="d-flex justify-content-between">
        <h4>{{title}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <app-title [title]="'Slots'"></app-title>
    <form [formGroup]="idForm">
        <div class="row">
            <div class="form-group col-6">
                 <label class="control-label">Nombre</label>
                 <input class="form-control" type="text" formControlName="name">
            </div>
            <div class="form-group col-6">
                <label class="control-label">Alto</label>
                <input class="form-control" type="text" formControlName="dimension_depth">
            </div>
        </div>
         <div class="row">
            <div class="form-group col-6">
                 <label class="control-label">Ancho</label>
                 <input class="form-control" type="text" formControlName="dimension_width">
            </div>
            <div class="form-group col-6">
                <label class="control-label">Largo</label>
                <input class="form-control" type="text" formControlName="dimension_height">
            </div>
        </div>
        <div class="form-group">
            <label class="control-label">Bodega</label>
            <select class="form-select form-select-sm" formControlName="">
              <option value="" selected="selected">Seleccione la bodega</option>
                <option *ngFor="let item of listBod" value="{{item.id}}">
                  {{item.name}}
                </option>
            </select>
          </div>
        
    </form>
    <div class="d-flex align-items-center justify-content-center mt-3">
        <button class="btn button-blue btn-sm" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="sendProviders()">Guardar</button>
    </div>
  
  </div>