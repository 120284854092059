import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Router } from 'express';
import { SLOTS_URL, STOCK_URL, WAREHOUSES_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';

@Component({
  selector: 'app-stock-template-modal-new-slot',
  templateUrl: './stock-template-modal-new-slot.component.html',
  styleUrls: ['./stock-template-modal-new-slot.component.scss']
})
export class StockTemplateModalNewSlotComponent implements OnInit {

  idForm!:FormGroup
  @Input() item?:any
  title:string= ''
  @Input() id:number = 0;
  listBod:any
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService: ApiService,
    public store:Store<AppState>,
  ) { 
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),
      dimension_depth: new FormControl(''),
      dimension_width: new FormControl(''),
      dimension_height: new FormControl(''),

    })
  }

  ngOnInit(): void {
    this.apiService.post(WAREHOUSES_FILTER_URL,{}).subscribe(response => this.listBod = <any[]>response)
    this.title=this.item ? 'Edición': 'Crear'
    if(this.item){
      this.idForm.setValue({
        name:this.item.name,
        dimension_depth:this.item.dimensionDepth,
        dimension_width:this.item.dimensionHeight,
        dimension_height:this.item.dimensionWidth,
      })
    }
  }

  sendProviders(){
    if(this.item){
      this.apiService.put(SLOTS_URL + this.item.id, this.idForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
      })
      
    }else{
      this.apiService.post(SLOTS_URL, this.idForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
      })
    }

   
  }
}
