import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { termConstants } from '../../constants/TermsConstants';

@Component({
  selector: 'app-input-button',
  templateUrl: './input-button.component.html',
  styleUrls: ['./input-button.component.scss']
})
export class InputButtonComponent implements OnInit {

  public termConstants = termConstants
  @Input() class:string = '';
  @Input() title:string = '';
  @Input() colorType:string = '';
  @Input() url:string = ''
  @Input() model: string = ''
  @Output() updateModelEvent = new EventEmitter()
  @Output() filterByKeyEvent = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  updateModel($event: string){
    this.model = $event
    this.updateModelEvent.emit($event)

  }

  checkKeyCode($event: KeyboardEvent){
    if($event.key === 'Enter'){
      this.filterByKeyEvent.emit()
    }
  }
}

