import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavState } from '../../state/NavState';
import { selectshowSide } from '../../state/nav.selector';

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss'],
  animations: [
    trigger('showSidebar', [
      state('showControls', style({
        marginLeft: 0,
        opacity: 1
      })),
      state('hideControls', style({
        marginLeft: -230,
        opacity: 0
      })),
      // ...
      transition('* => *', [
        animate('0.5s')
      ])
    ])

  ],
})
export class NavSidebarComponent implements OnInit {
  showSidebar$: boolean = false
  @Input() route:string = '';
  constructor(
    private navStore: Store<NavState>
  ) { }

  ngOnInit(): void {
    this.navStore.select(selectshowSide).subscribe(data => this.showSidebar$ = data)
  }
}
