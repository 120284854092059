import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { income } from '../../constants/IncomeConstants';
import { tableProduct } from '../../constants/TablesIncome';

@Component({
  selector: 'app-income-template-modal-products',
  templateUrl: './income-template-modal-products.component.html',
  styleUrls: ['./income-template-modal-products.component.scss']
})
export class IncomeTemplateModalProductsComponent implements OnInit {

  packages:any
  datas:any
  head = tableProduct
  public income = income
  constructor(
    public activeModal: NgbActiveModal,
    public store:Store<AppState>
  ) { }

  ngOnInit(): void {
    this.datas= this.packages.packages
  }

}
