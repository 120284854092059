<div class="card p-3 bg-white">
    <div class="d-flex justify-content-between">
        <app-title [title]="offices.listSeparation.list" [class]="'fw-bolder mb-2'" ></app-title>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    
    <app-title [title]="offices.listSeparation.orders" class="mb-2"></app-title>
    <div class="d-flex">
        <div class="me-3">
            <app-input-button></app-input-button>
        </div>
        <app-dropdown
          class="ms-2"
          [list]="listCat"
          [nameDefault]="'Categorias'"
          (searchKeyWord)="filterKeyWordCat($event)"
          (searchIdItem)="filterIdCat($event)">
        </app-dropdown>
        <app-dropdown
        class="ms-2"
        [list]="listZone"
        [nameDefault]="'Zona'"
        (searchKeyWord)="filterKeyWordZone($event)"
        (searchIdItem)="filterIdZone($event)">
      </app-dropdown>
    </div>
    <div class="card-over-red mt-3 mb-3 border-bottom">
      <table class="table" >
        <tr class="d-flex">
          <th class="d-flex align-items-center ps-2 pe-1">
            <input class="form-check-input" type="checkbox" >      
          </th>
          <app-item-table *ngFor="let item of tableHead" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
        </tr>
        <div>
          <tr class="head d-flex" *ngFor="let Table of tableBody;index as i">
            <td class="d-flex align-items-center border border-light ps-2 pe-1">
              <div class="form-check" (change)="addOrder(Table.id)">
                <input class="form-check-input" type="checkbox" value="">     
              </div>
            </td>
            <td class="d-flex align-items-center col border border-light ms-1">
              <app-text [title]="Table.distributionZone.name" [colorText]="'warning'" *ngIf="Table.distributionZone.name!=='Zona Norte'"></app-text>
              <app-text [title]="Table.distributionZone.name" [colorText]="'info'" *ngIf="Table.distributionZone.name==='Zona Norte'"></app-text>
            </td>  
            <app-row-item [title]="Table.id" class="bg-white col border border-light" ></app-row-item>
            <app-row-item [title]="Table.appClientHeadquarters.appClients.name" class="bg-white col border border-light" ></app-row-item>
            <app-row-item [title]="Table.id" class="bg-white col border border-light" ></app-row-item>
            <app-row-item [title]="Table.products.length" class="bg-white col border border-light" ></app-row-item>
            <app-row-item [title]="Table.id"  class="bg-white col border border-light" ></app-row-item>
            <td class="d-flex align-items-center border border-light col-2">
              <app-button-icon-text [title]="'Asignar Zona'" [icon]="icons.LOCATE" *ngIf="Table.distributionZone.name==='Sin localización'"></app-button-icon-text>
              <app-icon-text-icon class="ms-4" [src]="'./assets/icon-alert.png'" [title]="'Distinta zona'" [colorText]="'color-1'" *ngIf="Table.distributionZone.name!=='Sin localización' && Table.zone!=='Norte'"></app-icon-text-icon>
            </td>
          </tr>
        </div> 
      </table> 
    </div>
    <div class="d-flex">
        <div class="col">
            <app-calcule-volumen [dateVehicle]="listVeh[0]" [select]="select" (sendItemVehicle)="readDataVehicle($event)"></app-calcule-volumen>
        </div>
        <div class="col">
          <div class="d-flex p-3">
            <div class="col p-2">
                <app-title [title]="offices.assignOperators.operators" class="mb-3"></app-title>
                <div class="card p-3">
                    <div class="col-11 mb-3">
                      <app-input-search></app-input-search>
                    </div>
                  <div class="d-flex align-items-center mt-1" *ngFor="let item of users">
                    <div class="form-check m-0" (change)="addOperator(item.id)">
                      <input class="form-check-input" type="checkbox" value="">     
                    </div>
                    <img class="border border-2 border-danger rounded-circle p-1 ms-2 me-2" src="{{item.imageUrl}}" width="40" height="40">
                    <app-title [title]="item.name"></app-title>
                  </div>    
                </div>
            </div>
            <div class="col p-2">
                <app-title [title]="offices.assignOperators.hour" class="mb-3"></app-title>
                <div>
                  <div class="input-group input-group-sm" >
                    <input class="form-control" placeholder="yyyy-mm-dd" type="date" [(ngModel)]="date"/>
                  </div>
                </div>
                <app-title [title]="offices.assignOperators.zone" class="mt-3 mb-3"></app-title>
                <div>
                  <div class="form-group">
                    <select class="form-select form-select-sm" (change)="sendIdZone()" [(ngModel)]="zone">
                      <option value="" selected="selected">Selecciona la zona</option>
                        <option *ngFor="let item of listZone" value="{{item.id}}" >
                          {{item.name}}
                        </option>
                    </select>
                </div>
                </div>
                <app-title [title]="offices.assignOperators.comments" class="mt-3 mb-3"></app-title>
                <div>
                    <app-text-area [placeholder]="'Escribe tu comentario'"></app-text-area>
                </div>
            </div>
          </div>
        </div>
    </div>
    <div class="d-flex justify-content-center mt-3">
        <button class="btn button-blue btn-sm  me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="openAlert()">{{termConstants.modal.save}}</button>
    </div> 
</div>
