import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OFFICE_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';

@Component({
  selector: 'app-offices-template-list',
  templateUrl: './offices-template-list.component.html',
  styleUrls: ['./offices-template-list.component.scss']
})
export class OfficesTemplateListComponent implements OnInit {

  purchaseOrders= [
    {
      name: 'SCOTCHGARD PROTEC & SHINE FLOOR PROTECTOR',
      order: '85517',
      cant:'100',
      zone: 'Norte',
      value: 100
    },
    {
      name: 'SCOTCHGARD PROTEC & SHINE FLOOR PROTECTOR',
      order: '85517',
      cant:'100',
      zone: 'Norte',
      value: 20
    },
    {
      name: 'SCOTCHGARD PROTEC & SHINE FLOOR PROTECTOR',
      order: '85517',
      cant:'100',
      zone: 'Norte',
      value: 40
    },
    {
      name: 'SCOTCHGARD PROTEC & SHINE FLOOR PROTECTOR',
      order: '85517',
      cant:'100',
      zone: 'Norte',
      value: 60
    },
    {
      name: 'SCOTCHGARD PROTEC & SHINE FLOOR PROTECTOR',
      order: '85517',
      cant:'100',
      zone: 'Norte',
      value: 80
    },
    {
      name: 'SCOTCHGARD PROTEC & SHINE FLOOR PROTECTOR',
      order: '85517',
      cant:'100',
      zone: 'Norte',
      value: 100
    },
    {
      name: 'SCOTCHGARD PROTEC & SHINE FLOOR PROTECTOR',
      order: '85517',
      cant:'100',
      zone: 'Sin localización',
      value: 0
    },
  ]
  constructor(
   
  ) { }

  ngOnInit(): void {
   
  }

}
