import { createAction, props } from "@ngrx/store";
import { profileEntity, userEntity } from "../entities/UsersEntities";
import { usersListParamsEntity } from "../entities/UsersListParamsEntity";

export const loadUsers = createAction('[load Users] load Users',
props<{user:userEntity[]}>()
)
export const loadProfiles = createAction('[load Users] load Users',
props<{profile:profileEntity[]}>()
)
export const updateUsersListParams = createAction('[Users] Update list params', 
props<{keyUser:usersListParamsEntity}>())

export const updateProfilesListParams = createAction('[Profiles] Update list params', 
props<{keyProfile:usersListParamsEntity}>())

export const updateUsersList = createAction('[Users] Update list')