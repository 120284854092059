import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/modules/app-common/domain/state.ts/app.state";
import { OfficesState } from "./OfficesState";

 
export const selectOfficeFeature = (state:AppState) => state.offices;
 
export const selectOfficeListParams = createSelector(
  selectOfficeFeature,
  (state: OfficesState) => state.listParams
)

