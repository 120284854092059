import { createReducer, on } from "@ngrx/store";
import { CodesState } from "./CodesState";
import { ProductPackageCodesEmpty } from "../entities/ProductPackageCodesEntity";
import { updateCodesListParams, updateProductPackageCodesListParams } from "./codes.actions";
import { codesListParamsEmpty } from "../entities/CodesListParamsEntity";

export const initialState: CodesState = {code: [], listParams: ProductPackageCodesEmpty, codeParams:codesListParamsEmpty}

export const codesReducer = createReducer(
  initialState,
  on(updateCodesListParams, (state, codeParams) => ({ ...state, codeParams })),
  on(updateProductPackageCodesListParams, (state, listParams) => ({ ...state, listParams }))
);
