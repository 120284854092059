import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { optimize } from '../../constants/OptimizeConstants';
import { OptimizeTemplateModalNewOptimizeComponent } from '../optimize-template-modal-new-optimize/optimize-template-modal-new-optimize.component';

@Component({
  selector: 'app-optimize-template-level-filter',
  templateUrl: './optimize-template-level-filter.component.html',
  styleUrls: ['./optimize-template-level-filter.component.scss']
})
export class OptimizeTemplateLevelFilterComponent implements OnInit {

  public optimize = optimize
  constructor( public modalService:NgbModal) { }

  ngOnInit(): void {
  }
  openModalNewOptimize(){
    const modalRef = this.modalService.open(OptimizeTemplateModalNewOptimizeComponent,{
      size: 'xxl'
    })
  }

}
