export const terms = {
  login: {
    image: './assets/master_logo.png',
    title: 'Inicia Sesión',
    subtitle: 'Ingresa tu correo y contraseña',
    userLabel: 'Email',
    passwordLabel: 'Contraseña',
    forgot: "¿Olvidaste tu contraseña?",
    singIn: 'Ingresar',
    url: '/auth/recuperar-clave',
  },

  forgotPassword: {
    title: 'Recupera tu contraseña',
    subtitle: 'Ingresa tu correo electronico',
    userLabel: 'Email',
    send: 'Enviar',
    login: 'Volver', 
    url: '/auth/login',
    sendUrl: '/auth/send'
  },
  introUrl: "/usuarios/admin",
}
export const LOCALSTORAGE_TOKEN_KEYNAME = "token"