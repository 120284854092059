import { createReducer, on } from "@ngrx/store";
import { productsListParamsEmpty } from "../entities/ProductsListParamsEntity";
import { ProductsState } from "../entities/ProductsState";
import { fetchProviders, loadPackages, updateProductsListParams } from "./products.actions";

export const initialState: ProductsState = {
  pack: [],
  listParams: productsListParamsEmpty
} 

export const productsReducer = createReducer(
  initialState,
  on(loadPackages, (state,{pack}) => ({ ...state, pack})),
  on(updateProductsListParams, (state, listParams) => ({
    ...state,
    listParams
  })),
);