<div class="card p-3 bg-white">
    <div class="d-flex justify-content-between">
        <app-title [title]="income.modal.asign" [class]="'fw-bolder mb-2'" ></app-title>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
   
    <app-title [title]="income.modal.orders" class="mb-2"></app-title>
    <div class="d-flex">
        <div class="me-3">
          <app-input-search (search)="filterIncomes($event)"></app-input-search>
        </div>
        <app-dropdown
          [list]="listProv"
          [nameDefault]="'Proveedores'">
        </app-dropdown>
        <app-dropdown
          [list]="listProd"
          [nameDefault]="'Productos'">
        </app-dropdown>
    </div>
    <div class="card-over-red mt-3 mb-2 border-bottom">
      <table class="table" >
        <tr class="d-flex">
          <th class="d-flex align-items-center ps-2 pe-1">
            <input class="form-check-input" type="checkbox" >      
          </th>
          <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
          <th class="col-3"></th>
        </tr>
        <div>
          <tr class="head d-flex" *ngFor="let Table of packages;index as i">
            <td class="d-flex align-items-center border border-light ps-2 pe-1">
              <div class="form-check" (change)="addOrder(Table.id)">
                <input class="form-check-input" type="checkbox" value="">     
              </div>
            </td>   
            <app-row-item [title]="Table.id" class="bg-white col-2 border border-light" ></app-row-item>
            <app-row-item [title]="Table.packages[0]?.appProductPackages.appProducts.appProviders.name" class="bg-white col border border-light" ></app-row-item>
            <app-row-item [title]="Table.packages.length"  class="bg-white col-2 border border-light" ></app-row-item>
            <td class="d-flex align-items-center border border-light col-3">
              <button class="btn button-blue btn-sm" type="button" (click)="openModalProduct(Table)">Ver Productos</button>
            </td>
          </tr>
        </div> 
      </table>
    </div>
    <div class="d-flex">
            <div class="col p-2">
                <app-title [title]="income.modal.operators" class="mb-3"></app-title>
                <div class="card p-3">
                    <div class="col-4 mb-3">
                      <app-input-search (search)="filterOperators($event)"></app-input-search>
                    </div>
                  <div class="d-flex align-items-center mt-1" *ngFor="let item of users">
                    <div class="form-check m-0" (change)="addOperator(item.id)">
                      <input class="form-check-input" type="checkbox" value="">     
                    </div>
                    <img class="border border-2 border-danger rounded-circle p-1 ms-2 me-2" src="{{item.imageUrl}}" width="40" height="40">
                    <app-title [title]="item.name"></app-title>
                  </div>    
                </div>
            </div>
           
    </div>
    <div class="d-flex justify-content-center mt-3">
        <button class="btn button-blue btn-sm  me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="openAlert()">{{termConstants.modal.save}}</button>
    </div> 
</div>