import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CLIENT_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { offices } from '../../constants/OfficesConstants';
import { clientEntity } from '../../entities/OfficesEntities';

@Component({
  selector: 'app-card-client',
  templateUrl: './card-client.component.html',
  styleUrls: ['./card-client.component.scss']
})
export class CardClientComponent implements OnInit {

  public offices = offices
  @Input() dateClients:clientEntity[] = []
  providers:any
  activeId:any
  listCust:any
  @Input() idSelect:any
  cargued:boolean = false
  @Output() customerName = new EventEmitter()
  @Output() customerItem = new EventEmitter()
  @Output() customerId = new EventEmitter()
  @Output() sendId = new EventEmitter()
  constructor(
    private apiService:ApiService
  ) { 
    
  }

  ngOnInit(): void {
    this.apiService.post(CLIENT_FILTER_URL).subscribe((clients) => {
      this.dateClients = <clientEntity[]>clients
      this.listCust = <clientEntity[]>clients
    })
  }
  sendCustomerName(name:string, id:number, item:any){
    this.idSelect = id

    this.customerName.emit(name)
    this.customerItem.emit(item)
    this.customerId.emit(id)
    this.cargued = true
  }
  sendAllId(){
    this.customerId.emit('')
  }
  filterPackage($event:any){
    this.cargued = true
    let indexof = this.dateClients.filter((el:any)=>el.name.toLowerCase().indexOf($event.toLowerCase()) !== -1)
    this.sendId.emit(indexof[0].name)
    this.idSelect = indexof[0].id
  }

}
