import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { fetchListDone } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { selectFetch } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { PACKAGE_CODES_FILTER_URL, PACKAGE_CODES_LENGTH_URL, PACKAGE_CODES_URL } from '../../constants/ApiConstants';
import { codesListParamsEmpty, codesListParamsEntity } from '../../entities/CodesListParamsEntity';
import { updateCodesListParams } from '../../state/codes.actions';
import { selectCodesListParams } from '../../state/codes.selectors';

@Component({
  selector: 'app-codes-template-list',
  templateUrl: './codes-template-list.component.html',
  styleUrls: ['./codes-template-list.component.scss']
})
export class CodesTemplateListComponent implements OnInit {
  codesList:any
  pageSize = 7;
  collectionSize:any
  listParams: codesListParamsEntity = codesListParamsEmpty

  loading:boolean = false
  constructor(
    private store:Store<AppState>,
    public apiService:ApiService,
  ) {
    this.store.select(selectFetch).subscribe(fetch => {
      if(fetch){
        this.loadCodes()
        this.store.dispatch(fetchListDone())
      }
    })
   }

  ngOnInit(): void {
    this.loadCodes()
  }
  loadCodes(){
    this.store.select(selectCodesListParams).subscribe(params => {
      this.listParams = <codesListParamsEntity>params
      this.apiService.post(PACKAGE_CODES_FILTER_URL, {
        ...params,
        filters: {
          ...params.filters,
          assigned: true
        }
      }).subscribe((data:any) => {
        this.codesList = data
        this.apiService.post(PACKAGE_CODES_LENGTH_URL, {
          ...params,
          filters: {
            ...params.filters,
            assigned: true
          }
        }).subscribe((length: any)=>{
          this.collectionSize = length
          this.loading = true
        })
      })
    })
  }
  updateListParams(){
    window.scroll(0, 0)
    this.store.dispatch(updateCodesListParams({...this.listParams}))
  }

}
