<div class="me-2" ngbDropdown #myDrop="ngbDropdown" [ngClass]="{'active':active}">
  <button type="button" class="btn d-flex button-blue p-0" >
    <input type="text" class="form-control form-control-sm border-0 rounded-start" placeholder="{{nameDefault}}" [(ngModel)]="model" (keyup)="sendKeyWord($event)">
    <button type="button" class="btn button-dropdown border-0 rounded-end" (click)="$event.stopPropagation(); myDrop.open();">
      <i class="bi bi-filter"></i>
    </button>
  </button>
  <div class="p-2 bg-light" ngbDropdownMenu>
    <button ngbDropdownItem class="btn button-blue border-0 rounded-0 p-0 btn-sm" *ngFor="let item of list" (click)="sendIdItem(item)">{{item.name}}</button>
  </div>
</div>