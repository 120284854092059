import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { stock } from 'src/app/modules/stock/domain/constants/StockConstants';
import { OPTIMIZE_CYCLE_FILTER_URL } from '../../constants/OptimizeConstants';

@Component({
  selector: 'app-optimize-template-inventory-list',
  templateUrl: './optimize-template-inventory-list.component.html',
  styleUrls: ['./optimize-template-inventory-list.component.scss']
})
export class OptimizeTemplateInventoryListComponent implements OnInit {

  active = 1;
public stock = stock
  dateSlotsA = [
    {
      slot: 'A1',
      product: '10',
      vol: '20 m3',
      date: 'Dato',
      level: '100%',
      value: 100,
    },
    {
      slot: 'A2',
      product: '2',
      vol: '20 m3',
      date: 'Dato',
      level: '30%',
      value: 30,
    },
    {
      slot: 'A3',
      product: '0',
      vol: '20 m3',
      date: 'Dato',
      level: '0%',
      value: 0,
    },
    {
      slot: 'A4',
      product: '9',
      vol: '20 m3',
      date: 'Dato',
      level: '90%',
      value: 90,
    },
    {
      slot: 'A5',
      product: '9',
      vol: '20 m3',
      date: 'Dato',
      level: '90%',
      value: 90,
    },
  ]
  dateSlotsB = [
    {
      slot: 'B1',
      product: '10',
      vol: '20 m3',
      date: 'Dato',
      level: '100%',
      value: 100,
    },
    {
      slot: 'B2',
      product: '2',
      vol: '20 m3',
      date: 'Dato',
      level: '30%',
      value: 30,
    },
    {
      slot: 'B3',
      product: '0',
      vol: '20 m3',
      date: 'Dato',
      level: '0%',
      value: 0,
    },
    {
      slot: 'B4',
      product: '9',
      vol: '20 m3',
      date: 'Dato',
      level: '90%',
      value: 90,
    },
  ]
  dateSlotsC = [
    {
      slot: 'C1',
      product: '0',
      vol: '20 m3',
      date: 'Dato',
      level: '0%',
      value: 0,
    },
   
  ]
  dateSlotsD = [
    {
      slot: 'D1',
      product: '10',
      vol: '20 m3',
      date: 'Dato',
      level: '100%',
      value: 100,
    },
    {
      slot: 'D2',
      product: '2',
      vol: '20 m3',
      date: 'Dato',
      level: '30%',
      value: 30,
    },
    {
      slot: 'D3',
      product: '0',
      vol: '20 m3',
      date: 'Dato',
      level: '0%',
      value: 0,
    },
    {
      slot: 'D4',
      product: '9',
      vol: '20 m3',
      date: 'Dato',
      level: '90%',
      value: 90,
    },
  ]
  constructor(
    public apiService:ApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.post(OPTIMIZE_CYCLE_FILTER_URL,{"keyword":""}).subscribe((response:any)=>{
     
    })
  }

}
