<div class="mb-4 mt-2">
    <app-input-search (search)="filterPackage($event)"></app-input-search>
</div>
<div class="d-flex mt-3 ms-3" >
    <button class="rounded shadow d-flex align-items-center justify-content-center me-3" (click)="filterA()">
      <app-title [title]="stock.listSlots.a" [class]="'fw-bolder'" [fs]="'5'"></app-title>
    </button>
    <button class="rounded shadow d-flex align-items-center justify-content-center me-3" (click)="filterB()">
      <app-title [title]="stock.listSlots.b" [class]="'fw-bolder'" [fs]="'5'"></app-title>
    </button>
    <button class="rounded shadow d-flex align-items-center justify-content-center me-3" (click)="filterC()">
      <app-title [title]="stock.listSlots.c" [class]="'fw-bolder'" [fs]="'5'"></app-title>
    </button>
    <button class="rounded shadow d-flex align-items-center justify-content-center" (click)="filterD()">
      <app-title [title]="stock.listSlots.d" [class]="'fw-bolder'" [fs]="'5'"></app-title>
    </button>
</div>
<div class="card-over-red pe-3">
    <ngb-accordion  [closeOthers]="true" activeIds="{{idSelect}}">
      <ngb-panel id="{{item.id}}" *ngFor="let item of listSlot;index as i">
        <ng-template  ngbPanelHeader let-opened="opened">
            <button class="btn border-0 p-0 m-2 w-100 h-100" ngbPanelToggle (click)="sendSlotId(item.id)">
                <div class="card p-3" [ngClass]="{'active':!opened}">
                  <div class="d-flex ">
                      <div class="col">
                          <app-title [title]="stock.slotCard.slot" [class]="'fw-bolder'"></app-title>
                          <app-title [title]="item.name" [fs]="'1 fw-bolder'" [colorText]="'info'"></app-title>
                          <div class="d-flex">
                              <div class="col">
                                  <app-title [title]="stock.slotCard.products" [class]="'fw-bolder'"></app-title>
                                  <app-title [title]="stock.slotCard.volumen" [class]="'fw-bolder'"></app-title>
                                  <app-title [title]="stock.slotCard.date" [class]="'fw-bolder'"></app-title>
                              </div>
                              <div class="col ms-3">
                                  <app-title [title]="item.appStock.length" [colorText]="'secondary fw-bolder'"></app-title>
                                  <app-title [title]="item.dimensionDepth * item.dimensionHeight * item.dimensionWidth" [colorText]="'secondary fw-bolder'"></app-title>
                                  <app-title [title]="item.quantity" [colorText]="'secondary fw-bolder'"></app-title>
                              </div>            
                          </div>
                      </div>
                      <div class="col">
                          <app-slot [value]="item.productLevels.data"></app-slot>
                      </div>
                  </div>
                </div>
              </button>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
</div>
