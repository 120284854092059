import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {

  @Input() title:string = '';
  @Input() src:string = '';
  @Input() class:string = '';
  @Input() colorType:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
