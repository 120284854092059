import { createReducer, on } from '@ngrx/store';
import { AuthState } from '../entities/AuthState';
import { dateUserEMPTY } from '../entities/DateUser';
import { loaddedApi, setMe } from './auth.actions';


export const initialState: AuthState = {dates: [] , me: dateUserEMPTY}

export const authReducer = createReducer(
  initialState,
  on(loaddedApi, (state,{dates}) => ({ ...state, dates})),
  on(setMe, (state,{me}) => ({ ...state, me})),
);


