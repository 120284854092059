<div class="card p-4">
    <div class="d-flex justify-content-between mb-3">
        <app-title [title]="'Asignación  de código'" [class]="'fw-bolder'"></app-title>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="mt-3">
        <app-codes-card [item]="item" [showButtons]="true"></app-codes-card>
    </div>
    <app-title [title]="codes.modal.description"></app-title>
    <div class="mt-3 w-100">
        <app-input-search (search)="updateList($event)"></app-input-search>
    </div>
    <div class="mt-3">
        <app-codes-card-product [packages]="packages" (productId)="selectProduct($event)"></app-codes-card-product>
    </div>
    <div class="d-flex justify-content-center mt-3">
        <button class="btn button-blue btn-sm  me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="sendAddProduct()">{{termConstants.modal.save}}</button>
    </div>
</div>