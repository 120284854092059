import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FILTER_EMPTY } from '../../entities/FiltersEntity';
import { ApiService } from '../../services/api.service';
import { AppState } from '../../state.ts/app.state';
import { fetchListDone, updateFilter } from '../../state.ts/common.actions';
import { selectFilterProviders, selectrechargePage } from '../../state.ts/common.selectors';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';

@Component({
  selector: 'app-crud-table',
  templateUrl: './crud-table.component.html',
  styleUrls: ['./crud-table.component.scss']
})
export class CrudTableComponent implements OnInit {

  @Input() title:string = '';
  filters = FILTER_EMPTY
  list: any[] = []
  load$:any
  modalComponents: any = {}
  msj:any
  loading:boolean = false

  @Input() url: string = ''
  @Input() modalComponentName: any
  @Input() head: any

  constructor( private apiService: ApiService,
    private store: Store<AppState>,
    public modalService:NgbModal,
   
  ) {
   }

  ngOnInit(): void {
    this.head = this.head
    
    this.store.select(selectFilterProviders).subscribe(data => {
      this.apiService.post(this.url+'filter',{}).subscribe(response => {
        this.list = <any[]>response
        this.store.dispatch(fetchListDone())
      })
    })
  }

  
  filter(){
    this.store.dispatch(updateFilter({filters:this.filters}))
  }
  updateKeyword($event: string){
    this.filters = {
      keyword: $event
    }
  }

  filterByKey(){
    this.filter()
  }
  openModalNew(){
    const modalRef = this.modalService.open(this.modalComponentName, {
      size: 'lg'
    })
  }
  openModalEdit(item:any, index:number){
    this.load$=false
    const modalRef = this.modalService.open(this.modalComponentName,{
      size: 'lg'
    });
    modalRef.componentInstance.item = item, modalRef.componentInstance.id = index
  }
  openAlert(index:number){
    const modalRef = this.modalService.open(ModalAlertComponent,{
      size: 'sm'
    });
    modalRef.componentInstance.content = '¿Desea eliminar el '+this.title+'?', modalRef.componentInstance.id = index, modalRef.componentInstance.url = this.url,
    modalRef.componentInstance.buttonMain = 'Eliminar'
  }

  

}
