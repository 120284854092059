import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/modules/app-common/domain/state.ts/app.state";
import { ProductsState } from "../entities/ProductsState";

 
export const selectloadProducts = (state:AppState) => state.products;
 
export const selectloadPackage = createSelector(
  selectloadProducts,
  (state: ProductsState) => state.pack
);
export const selectProductsListParams = createSelector(
  selectloadProducts,
  (state: ProductsState) => state.listParams
)
