import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OfficesRoutingModule } from './offices-routing.module';
import { FeatureOfficesComponent } from './feature-offices/feature-offices.component';
import { AppCommonModule } from '../app-common/app-common.module';
import { OfficesTemplateListComponent } from './domain/templates/offices-template-list/offices-template-list.component';
import { OfficesTemplateFilterComponent } from './domain/templates/offices-template-filter/offices-template-filter.component';
import { CardOfficeComponent } from './domain/organisms/card-office/card-office.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OfficesTemplateModalComponent } from './domain/templates/offices-template-modal/offices-template-modal.component';
import { CalculeVolumenComponent } from './domain/organisms/calcule-volumen/calcule-volumen.component';
import { OfficesTemplateModalNewOrderComponent } from './domain/templates/offices-template-modal-new-order/offices-template-modal-new-order.component';
import { CardClientComponent } from './domain/organisms/card-client/card-client.component';
import { NavModule } from '../nav/nav.module';
import { FormsModule } from '@angular/forms';
import { FeatureListSeparationComponent } from './feature-list-separation/feature-list-separation.component';
import { OfficesTemplateListSeparationComponent } from './domain/templates/offices-template-list-separation/offices-template-list-separation.component';
import { OfficesTemplateFilterSeparationComponent } from './domain/templates/offices-template-filter-separation/offices-template-filter-separation.component';


@NgModule({
  declarations: [
    FeatureOfficesComponent,
    OfficesTemplateListComponent,
    OfficesTemplateFilterComponent,
    CardOfficeComponent,
    OfficesTemplateModalComponent,
    CalculeVolumenComponent,
    OfficesTemplateModalNewOrderComponent,
    CardClientComponent,
    FeatureListSeparationComponent,
    OfficesTemplateListSeparationComponent,
    OfficesTemplateFilterSeparationComponent,
  ],
  imports: [
    CommonModule,
    OfficesRoutingModule,
    AppCommonModule,
    NgbModule,
    NavModule,
    FormsModule
  ],
})
export class OfficesModule { }
