
import { ModalEditCategoriesComponent } from "../templates/modal-edit-categories/modal-edit-categories.component";
import { ModalEditCellarComponent } from "../templates/modal-edit-cellar/modal-edit-cellar.component";
import { ModalEditClientHeadquartersComponent } from "../templates/modal-edit-client-headquarters/modal-edit-client-headquarters.component";
import { ModalEditClientComponent } from "../templates/modal-edit-client/modal-edit-client.component";
import { ModalEditDocumentTypesComponent } from "../templates/modal-edit-document-types/modal-edit-document-types.component";
import { ModalEditProductComponent } from "../templates/modal-edit-product/modal-edit-product.component";
import { ModalEditProviderComponent } from "../templates/modal-edit-provider/modal-edit-provider.component";
import { ModalEditVehiclesComponent } from "../templates/modal-edit-vehicles/modal-edit-vehicles.component";
import { ModalEditZonesDistributionComponent } from "../templates/modal-edit-zones-distribution/modal-edit-zones-distribution.component";
import { ModalEditZonesComponent } from "../templates/modal-edit-zones/modal-edit-zones.component";
import { ModalNewPackingComponent } from "../templates/modal-new-packing/modal-new-packing.component";

export const MODAL_CONST = {
  providers: ModalEditProviderComponent,
  products: ModalEditProductComponent,
  packing: ModalNewPackingComponent,
  family: ModalEditCategoriesComponent,
  client: ModalEditClientComponent,
  headquarter: ModalEditClientHeadquartersComponent,
  cellar: ModalEditCellarComponent,
  type: ModalEditDocumentTypesComponent,
  zone: ModalEditZonesComponent,
  vehicle: ModalEditVehiclesComponent,
  distribution:ModalEditZonesDistributionComponent,
}