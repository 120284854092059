<div class="card p-3 bg-white">
    <div class="d-flex justify-content-between">
        <app-title [title]="'Crear orden de compra de productos'" [class]="'fw-bolder mb-2'" ></app-title>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <app-title [title]="'Selecciona un proveedor'" [colorText]="'secondary'" [class]="'fw-bolder mb-2'"></app-title>   
    <div class="d-flex">
        <div class="me-3 col-2">
          <app-title [title]="'Proveedores'" [colorText]="'secondary'"></app-title>
          <div class="pe-4">
            <div class="d-flex justify-content-center mt-3" *ngIf="!loader">
              <div class="spinner-border text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <div *ngIf="loader">
              <app-card-supplier [idSelect]="idSelect" (providerId)="filterPackage($event)" (sendId)="filterPackage($event)"></app-card-supplier>
            </div> 
          </div>
        </div>
        <div class="col me-3">
            <div class="d-flex">
              <div class="col-4 me-3">
                <app-title [title]="'Productos'" [class]="'mb-2'" [colorText]="'secondary'"></app-title>
                <app-input-search (search)="searchProduct($event)"></app-input-search>
              </div>       
            </div>
            <div class="card-1 mt-4" >
              <table class="table" >
                <tr class="d-flex">
                  <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
                </tr>
                <div class="d-flex justify-content-center" *ngIf="loading">
                  <div class="text-center text-secondary mt-5">
                    <h5>Por favor</h5>
                    <h5>Selecciona un proveedor</h5>
                    <h5>En el menu izquierdo de esta ventana</h5>
                    <img src="./assets/select-providers.png" >
                  </div>
                </div>
                <div *ngIf="!loading">
                  <tr class="head d-flex" *ngFor="let Table of packagesList; index as i">
                    <app-row-item [src]="'./assets/Grupo 67.png'" [active]="Table.active" class="col-1"></app-row-item>            
                    <app-row-item [title]="Table.appProducts.code" class="bg-white col-1 border border-light" ></app-row-item>
                    <app-row-item [title]="Table.appProducts.appProductCategories.name" class="bg-white col-4 border border-light" ></app-row-item>
                    <app-row-item [title]="Table.name" [src]="'./assets/image.png'" class="bg-white col border border-light" ></app-row-item>
                    <td class="col-1">
                      <div class="input-group input-group-sm">
                        <input class="form-control" value="" type="text" [(ngModel)]="Table.amount" (change)="sendProduct(packages, i, Table.amount)">
                      </div>                  
                    </td>
                  </tr>
                </div> 
              </table>
           </div>
        </div>
    </div>
    <div class="d-flex" >
      <div class="col-3 text-white p-1">.</div>
      <div class="col">
        <div class="card-5 bg-danger text-center text-white rounded-pill p-1"  [ngClass]="{'alert':alert}">
          <i class="bi bi-exclamation-triangle"></i>
          Atención. Seleccione al menos un producto para crear la orden de compra
        </div>
      </div>
      <div class="col-3"></div>
    </div>
    <div class="d-flex justify-content-center mt-4">
        <button class="btn button-blue btn-sm me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="openModalConfirm()">Confirmar</button>
    </div> 
</div>