import { environment } from "src/environments/environment.prod"

export const income = {
    cardProgress: {
        order: 'Orden de compra',
        numberProducts: 'No. Productos',
        asign: 'Asignar',
    },
    cardSupplier: {
        code: 'Código'
    },
    filter: {
        newOrder: 'Crear orden',
        asign: 'Asignar equipo de recibo',
        providers: 'Proveedores',
        category: 'Categoria'
    },
    modal: {
        asign: 'Asignación del equipo de recibo',
        orders: 'Ordenes',
        operators: 'Operarios',
        date: 'Fecha de recibo',
        hour: 'Hora de recibo',
        products: 'Productos',
        imgCargue: './assets/cargue.png',
        purchaseOrder: 'Orden de compra',
        provider: 'Proveedor',
        volumen: 'Volumen',
    }
}
export const getIncomes = '/incomes/'
export const INCOMES_URL = '/incomes/'
export const INCOMES_FILTER_URL = '/incomes/filter'
