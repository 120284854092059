import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OptimizeRoutingModule } from './optimize-routing.module';
import { FeatureLevelComponent } from './feature-level/feature-level.component';
import { FeatureInventoryComponent } from './feature-inventory/feature-inventory.component';
import { FeatureTransferedComponent } from './feature-transfered/feature-transfered.component';
import { AppCommonModule } from '../app-common/app-common.module';
import { OptimizeTemplateLevelFilterComponent } from './domain/templates/optimize-template-level-filter/optimize-template-level-filter.component';
import { OptimizeTemplateLevelListComponent } from './domain/templates/optimize-template-level-list/optimize-template-level-list.component';
import { OptimizeLevelCardComponent } from './domain/organisms/optimize-level-card/optimize-level-card.component';
import { OptimizeSlotCardComponent } from './domain/organisms/optimize-slot-card/optimize-slot-card.component';
import { OptimizeTemplateInventoryListComponent } from './domain/templates/optimize-template-inventory-list/optimize-template-inventory-list.component';
import { StockModule } from '../stock/stock.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OptimizeInventoryCardComponent } from './domain/organisms/optimize-inventory-card/optimize-inventory-card.component';
import { OptimizeCardProductComponent } from './domain/organisms/optimize-card-product/optimize-card-product.component';
import { OptimizeTemplateTransferedFilterComponent } from './domain/templates/optimize-template-transfered-filter/optimize-template-transfered-filter.component';
import { OptimizeTemplateTransferedListComponent } from './domain/templates/optimize-template-transfered-list/optimize-template-transfered-list.component';
import { OptimizeTransferedCardComponent } from './domain/organisms/optimize-transfered-card/optimize-transfered-card.component';
import { OptimizeTemplateModalSelectComponent } from './domain/templates/optimize-template-modal-select/optimize-template-modal-select.component';
import { NavModule } from '../nav/nav.module';
import { OptimizeTemplateModalNewOptimizeComponent } from './domain/templates/optimize-template-modal-new-optimize/optimize-template-modal-new-optimize.component';
import { OptimizeInventoryCardForFamilyComponent } from './domain/organisms/optimize-inventory-card-for-family/optimize-inventory-card-for-family.component';
import { OptimizeInventoryCardForSlotComponent } from './domain/organisms/optimize-inventory-card-for-slot/optimize-inventory-card-for-slot.component';
import { OptimizeLevelAccordeonComponent } from './domain/organisms/optimize-level-accordeon/optimize-level-accordeon.component';
import { OptimizeLevelAccordeonOptComponent } from './domain/organisms/optimize-level-accordeon-opt/optimize-level-accordeon-opt.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    FeatureLevelComponent,
    FeatureInventoryComponent,
    FeatureTransferedComponent,
    OptimizeTemplateLevelFilterComponent,
    OptimizeTemplateLevelListComponent,
    OptimizeLevelCardComponent,
    OptimizeSlotCardComponent,
    OptimizeTemplateInventoryListComponent,
    OptimizeInventoryCardComponent,
    OptimizeCardProductComponent,
    OptimizeTemplateTransferedFilterComponent,
    OptimizeTemplateTransferedListComponent,
    OptimizeTransferedCardComponent,
    OptimizeTemplateModalSelectComponent,
    OptimizeTemplateModalNewOptimizeComponent,
    OptimizeInventoryCardForFamilyComponent,
    OptimizeInventoryCardForSlotComponent,
    OptimizeLevelAccordeonComponent,
    OptimizeLevelAccordeonOptComponent,
  ],
  imports: [
    CommonModule,
    OptimizeRoutingModule,
    AppCommonModule,
    NgbModule,
    StockModule,
    NavModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class OptimizeModule { }
