<div class="card p-3 bg-light">
    <div class="text-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="d-flex mt-3">
        <div class="col-1">
            <div class="ms-2">
                <app-title [title]="stock.modal.slot" [fs]="'5 fw-bolder'"></app-title>
                <app-title [title]="item?.name" [fs]="'1 fw-bolder'" [colorText]="'info'"></app-title>
            </div>           
            <div class="d-flex p-2">
                <app-slot [value]="item?.productLevels?.data"></app-slot>
            </div>
            
        </div>
        <div class="col-4 d-flex border-end p-3">
            <div class="col">
                <app-title [title]="stock.modal.volumenSlot" [class]="'fw-bolder p-2'"></app-title>
                <app-title [title]="stock.modal.lastOptimization" [class]="'fw-bolder p-2'"></app-title>
                <app-title [title]="stock.modal.cantProducts" [class]="'fw-bolder p-2'"></app-title>
                <app-title [title]="stock.modal.date" [class]="'fw-bolder p-2'"></app-title>
                <app-title [title]="stock.modal.date" [class]="'fw-bolder p-2'"></app-title>
                <app-title [title]="'Límite de productos:'" [class]="'fw-bolder p-2'"></app-title>
                <div class="d-flex">
                    <button class="btn button-blue btn-sm" type="button" (click)="openModalChangeMaxLimite(item.maxProductTypes, item.id)">Cambiar límite</button>
                </div>
            </div>
            <div class="col text-end">
                <app-title [title]=" item?.dimensionDepth * item?.dimensionHeight * item?.dimensionWidth+'m3'" [class]="'p-2'"></app-title>
                <app-title [title]="item?.createdAt"  [class]="'p-2'"></app-title>
                <app-title [title]="item?.appStock.length"  [class]="'p-2'"></app-title>
                <app-title [title]="'Dato'"  [class]="'p-2'"></app-title>
                <app-title [title]="'Dato'"  [class]="'p-2'"></app-title>
                <app-title [title]="item?.maxProductTypes"  [class]="'p-2'"></app-title>
                <div class="d-flex flex-row-reverse">
                    <button class="btn button-red btn-sm" type="button">{{stock.modal.optimizeSlot}}</button>
                </div>
            </div>
            
        </div>
        <div class="col p-3">
            <app-title [title]="stock.modal.levelSubSlots" [class]="'fw-bolder'"></app-title>
            <canvas id="myChart" width="100" height="30" ></canvas>
        </div>

    </div>
    <app-title [title]="stock.modal.products" [class]="'fw-bolder'"></app-title>
    <div class="d-flex">
        <div class="me-3">
            <app-input-button></app-input-button>
        </div>
        <div>
          <div class="form-group">
            <select class="form-select form-select-sm">
              <option value="{{stock.modal.category}}" selected></option>
              <option >Name</option>
            </select>
          </div>
        </div>
    </div>
    <div class="card-1 mt-3" *ngIf="item?.appStock[0]?.appProducts!==null">
        <app-product-card [dateProduct]="item?.appStock[0]"></app-product-card>
    </div>
    <div class="card-1 mt-3" *ngIf="item?.appStock[0]?.appProducts==null">
        <app-title [title]="'No hay productos'" [colorText]="'danger'"></app-title>
    </div>
   
</div>
