<div class="card p-2">
  <form [formGroup]="idForm" class="row">
    <div class="text-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="card-2 text-center">
      <app-title [title]="title + ' de usuarios'" [class]="'mb-4 ms-4 border-bottom text-secondary fw-bolder'" ></app-title>
      <img class="border border-3 border-danger rounded-circle p-2" [src]="image" width="120" height="120">
        <div class="input-group">
          <input type="file" class="form-control" id="inputGroupFile01" name="profile" (change)="onFileSelect($event)" style="display: none;">
          <label class="label button-blue d-flex align-items-center justify-content-center rounded-circle input-group-text position-absolute top-100 start-50 translate-middle p-0" for="inputGroupFile01">
            <img src="./assets/Trazado 23.png" class="button-img p-1 clickable" width="28">
          </label>
        </div>      
    </div>
    <div class="ps-5 mt-4 pe-5">
      <div class="row">
        <div class="col form-group">
          <label class="control-label text-secondary">Nombre</label>
          <div class="input-group mb-3">
            <input class="form-control form-control-sm" type="text" formControlName="name">
          </div>         
        </div>
        <div class="col form-group">
          <label class="control-label text-secondary">Perfil</label>
          <select class="form-select form-select-sm" formControlName="user_profiles_id">
            <option value="" selected="selected">Elija su perfil</option>
              <option *ngFor="let item of listProf" value="{{item.id}}">
                {{item.name}}
              </option>
          </select>
        </div>   
         
      </div>
      <div class="row">
        <div class="col form-group">
          <label class="control-label text-secondary">Tipo de documento</label>
          <select class="form-select form-select-sm border-blue" formControlName="user_document_types_id">
            <option value="" selected="selected">Elija su tipo de documento</option>
              <option *ngFor="let item of listType" value="{{item.id}}">
                {{item.name}}
              </option>
          </select>
        </div>
        <div class="col form-group">
          <label class="control-label text-secondary">Cedula</label>
          <div class="input-group mb-3">
            <input class="form-control form-control-sm" type="text" formControlName="document_number">
          </div>         
        </div>   
      </div>
      <div class="row">
        <div class="col form-group">
          <label class="control-label text-secondary">Correo electronico</label>
          <div class="input-group">
            <input class="form-control form-control-sm" type="email" formControlName="email">
          </div>         
        </div>   
        <div class="col form-group" *ngIf="title==='Creación'">
          <label class="control-label text-secondary">Contraseña</label>
          <div class="input-group">
            <input class="form-control form-control-sm" type="password" formControlName="password">
          </div>         
        </div>     
      </div>       
    </div>
   
    
    <div class="ps-5 pe-5 mt-3">
     
      <label class="control-label text-secondary mt-2 mb-2">Bodega</label>
      <div class="d-flex">
        <div class="form-check col" *ngFor="let item of listBod;index as i" (change)="addListWarehouses(item.id)">
          <input class="form-check-input border-primary" type="checkbox" value="" id="{{i}}">
          <label class="form-check-label text-secondary" for="{{i}}">
            {{item.name}}
          </label>        
        </div>
      </div>
    </div>
   
    <div class="d-flex justify-content-center p-3">
      <button class="btn button-blue btn-sm me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
      <button class="btn button-red-strong btn-sm" type="submit" (click)="createUser()">{{termConstants.modal.save}}</button>
    </div>
  </form>   
</div>
