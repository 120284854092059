import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { USER_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { users } from 'src/app/modules/users/domain/constants/UsersConstants';
import { userEntity } from 'src/app/modules/users/domain/entities/UsersEntities';
import { usersListParamsEmpty, usersListParamsEntity } from 'src/app/modules/users/domain/entities/UsersListParamsEntity';
import { selectUsersListParams } from 'src/app/modules/users/domain/state/users.selectors';
import { UsersTemplateModalNewuserComponent } from 'src/app/modules/users/domain/templates/users-template-modal-newuser/users-template-modal-newuser.component';
import { incomeListParamsEmpty, incomeListParamsEntity } from '../../entities/IncomeListParamsEntity';

@Component({
  selector: 'app-card-operator',
  templateUrl: './card-operator.component.html',
  styleUrls: ['./card-operator.component.scss']
})
export class CardOperatorComponent implements OnInit {
  public users = users
  dateUsers:userEntity[]=[]
  icons = ICONS_CONSTANTS
  listOperator:any [] = []
  page = 1;
	pageSize = 3;
  listOperators:any
  active : boolean = false
  length:any
  operators:any
  listParams:usersListParamsEntity=usersListParamsEmpty
  @Output() sendUsers = new EventEmitter()
  constructor(
    public modalService:NgbModal,
    public apiService:ApiService,
    private store:Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.select(selectUsersListParams).subscribe(params=>{
      const asignOrder = {'users':this.listOperator}
      this.listParams = params
      this.apiService.post(USER_FILTER_URL,params).subscribe((response:any) =>{
        this.dateUsers = response.filter((el:any)=>el.userProfiles.name === 'Operario')
        this.length = this.dateUsers.length
        this.operators = this.dateUsers.map((oper:any)=>({...oper})).slice(
          	(this.page - 1) * this.pageSize,
			      (this.page - 1) * this.pageSize + this.pageSize,
        )
      })
    }) 
  }
  openModalEditUser(data:userEntity, id:number) {
    const modalRef = this.modalService.open(UsersTemplateModalNewuserComponent,{
      size: 'sm'
    });modalRef.componentInstance.item = data, modalRef.componentInstance.id = id
  }
  addOperator(id:number){
    let bus = this.listOperator.indexOf(id)
    if (bus === -1){
      this.listOperator.push(id)
      this.active = !this.active
    }else{
      this.listOperator.splice(bus, 1)
    }
    this.sendUsers.emit(this.listOperator)
  }
  newPage(){
      this.operators = this.dateUsers.map((oper:any)=>({...oper})).slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize,
      )
  }
  dimissPage(){
    if(this.page>1){
      this.page =this.page-1
      this.newPage()
    }
   
  }
  addPage(){
    if((this.length/3)>this.page){
      this.page =this.page+1
      this.newPage()
    }
  }


}
