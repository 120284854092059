import { Component, Input, OnInit } from '@angular/core';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { optimize } from '../../constants/OptimizeConstants';

@Component({
  selector: 'app-optimize-slot-card',
  templateUrl: './optimize-slot-card.component.html',
  styleUrls: ['./optimize-slot-card.component.scss']
})
export class OptimizeSlotCardComponent implements OnInit {

  public optimize = optimize
  icons = ICONS_CONSTANTS
  @Input() colorText:string ='';
  @Input() colorBar:string ='';
  @Input() section:string ='';
  @Input() value:number =0;
  @Input() opacity:string='';
  constructor() { }

  ngOnInit(): void {
  }

}
