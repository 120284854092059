import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/domain/guards/auth.guard';
import { FeatureProfilesComponent } from './feature-profiles/feature-profiles.component';
import { FeatureUsersComponent } from './feature-users/feature-users.component';

const routes: Routes = [
  {path: 'usuarios', children: [
    {path: 'admin', component: FeatureUsersComponent, canActivate: [AuthGuard]},
    {path: 'perfiles', component: FeatureProfilesComponent , canActivate: [AuthGuard]}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
