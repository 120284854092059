<div class="d-flex">
  <div class="col-12">
    <div class="d-flex justify-content-center" *ngIf="!loading">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <ngb-accordion [closeOthers]="true" *ngIf="loading">
      <ngb-panel id="{{item.id}}" *ngFor="let item of purchaseOrders;index as i">
        <ng-template  ngbPanelHeader let-opened="opened">
          <div class="rounded-0 w-100 custom-header justify-content-between m-0 p-0 ">
            <div class="d-flex align-items-center">
              <button class="btn bg-white d-flex align-items-center border rounded-0 m-0 p-0 w-100 ms-3" ngbPanelToggle (click)="rotated(i)">
                <img src="./assets/filter.png" class="ms-2" *ngIf="!opened">
                <img src="./assets/filter.png" class="rotate ms-2" *ngIf="opened">
                <div class="card border-0 rounded-0 p-2">
                  <div class="d-flex">
                    <div class="col-6">
                      <div class="border-bottom text-start ms-4 ps-3 col-9">
                        <app-title [title]="item.appClientHeadquarters?.appClients?.name" [class]="'fw-bolder'"></app-title>
                      </div>  
                      <div class="d-flex ">
                        <div class="card-1 border-end text-center">
                          <app-title [title]="offices.cardProgress.order"></app-title>
                          <app-title [title]="item.id" [colorText]="'info'"></app-title>
                        </div>
                        <div class="card-1 border-end text-center">
                          <app-title [title]="offices.cardProgress.numberProducts"></app-title>
                          <app-title [title]="item.products.length" [colorText]="'info'"></app-title>
                        </div>
                        <div class="card-1 border-end text-center">
                          <app-title [title]="offices.cardProgress.zone"></app-title>
                          <!-- <app-title [title]="item.distributionZone.name" [colorText]="'info'" *ngIf="item.id !== 0"></app-title> -->
                          <app-title [title]="item.id" [colorText]="'warning'" *ngIf="item.id === 0"></app-title>
                        </div>
                        <div class="card-1 d-flex align-items-center justify-content-center">
                          <button class="btn button-red btn-sm" type="button" *ngIf="item.id === 0" (click)="openModalIncome()">{{offices.cardProgress.asign}}</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="d-flex">
                        <div class="col">
                          <button type="button" class="btn border-0" ngbPopover="{{item.team[0]?.user?.name}}, {{item.team[1]?.user?.name}}, {{item.team[2]?.user?.name}} 3:30pm" triggers="mouseenter:mouseleave" popoverTitle="Asignado a" popoverClass="my-custom-class" *ngIf="item.appDispatchStatus.id>1">
                            <img src="./assets/icon1-green.png" class="w-25">
                          </button>            
                          <button type="button" class="btn border-0" ngbPopover="" triggers="mouseenter:mouseleave" popoverTitle="Sin Asignar" *ngIf="item.appDispatchStatus.id===1">
                            <img src="./assets/icon-alert.png" class="w-25">
                          </button>
                        </div>
                        <div class="col">
                          <button type="button" class="btn border-0" ngbPopover="{{item.team[0]?.user?.name}} 3:30pm" triggers="mouseenter:mouseleave" popoverTitle="En distribucion" *ngIf="item.appDispatchStatus.id===2">
                            <img src="./assets/icon2-green.png" class="w-25">
                          </button>
                          <button class="btn border-0" *ngIf="item.appDispatchStatus.id<2">
                            <img src="./assets/icon2-blue.png" class="w-25">
                          </button>
                        </div>
                        <div class="col">
                          <button type="button" class="btn border-0" ngbPopover="{{item.team[0]?.user?.name}} 3:30pm" triggers="mouseenter:mouseleave" popoverTitle="En distribucion" *ngIf="item.appDispatchStatus.id===3">
                            <img src="./assets/icon3-green.png" class="w-25">
                          </button>
                          <button class="btn border-0" *ngIf="item.appDispatchStatus.id<3">
                            <img src="./assets/icon3-blue.png" class="w-25">
                          </button>
                        </div>
                        <div class="col">
                          <button type="button" class="btn border-0" ngbPopover="{{item.team[0]?.user?.name}} 3:30pm" triggers="mouseenter:mouseleave" popoverTitle="En distribucion" *ngIf="item.appDispatchStatus.id===4">
                            <img src="./assets/icon4-green.png" class="w-25">
                          </button>
                          <button class="btn border-0" *ngIf="item.appDispatchStatus.id<4">
                            <img src="./assets/icon4-blue.png" class="w-25">
                          </button>
                        </div>
                        <div class="col">
                          <button type="button" class="btn border-0" ngbPopover="{{item.team[0]?.user?.name}} 3:30pm" triggers="mouseenter:mouseleave" popoverTitle="Distribuido en bodega" *ngIf="item.appDispatchStatus.id === 5">
                            <img src="./assets/icon5-green.png" class="w-25">
                          </button>
                          <button class="btn border-0" *ngIf="item.appDispatchStatus.id<5">
                            <img src="./assets/icon5-blue.png" class="w-25">
                          </button>
                        </div>
                      </div>
                      <div class="ps-5 pe-5 me-2">
                        <app-progress-bar [colorBar]="'success'" [value]="1" *ngIf="item.appDispatchStatus.id === 1"></app-progress-bar>
                        <app-progress-bar [colorBar]="'success'" [value]="1" *ngIf="item.appDispatchStatus.id === 2"></app-progress-bar>
                        <app-progress-bar [colorBar]="'success'" [value]="25" *ngIf="item.appDispatchStatus.id === 3"></app-progress-bar>
                        <app-progress-bar [colorBar]="'success'" [value]="50" *ngIf="item.appDispatchStatus.id === 4"></app-progress-bar>
                        <app-progress-bar [colorBar]="'success'" [value]="75" *ngIf="item.appDispatchStatus.id === 5"></app-progress-bar>
                        <app-progress-bar [colorBar]="'success'" [value]="100" *ngIf="item.appDispatchStatus.id === 6"></app-progress-bar>
                      </div>
                    </div>
                    <div class="col"></div>
                    
                  </div>
                </div>
                
              </button> 
            </div>                
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="p-3">
            <table class="table" >
              <tr class="d-flex">
                <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
              </tr>
              <div>
                <tr class="head d-flex" *ngFor="let Table of packages?.products">
                  <app-row-item [title]="Table.appProducts.code" class="bg-white col-3 border border-light" ></app-row-item>
                  <app-row-item [title]="Table.appProducts.name" class="bg-white col border border-light" ></app-row-item>
                  <app-row-item [title]="Table.amount"  class="bg-white col-3 border border-light" ></app-row-item>
                </tr>
              </div> 
            </table>
          </div>       
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <div class="pagination mt-3">
      <ngb-pagination
        [collectionSize]="collectionSize"
        [(page)]="listParams.page"
        [pageSize]="listParams.size"
        (pageChange)="updateListParams()">
      </ngb-pagination>
    </div>  
  </div>
</div>


