import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { INCOME_ASSIGN_URL, OFFICE_ASSIGN_URL, OFFICE_FILTER_URL, USER_FILTER_URL, VEHICLE_FILTER_URL, ZONE_FILTER_URL, termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { offices } from '../../constants/OfficesConstants';
import { tableModal } from '../../constants/TableOffices';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { VEHICLE_EMPTY } from '../../constants/OfficesEmpty';
import { selectOfficeListParams } from '../../state/offices.selectors';
import { officeListParamsEmpty, officeListParamsEntity } from '../../entities/OfficesListParamsEntity';
import { updateOfficeListParams } from '../../state/offices.actions';
import { PRODUCT_CATEGORIES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';

@Component({
  selector: 'app-offices-template-modal',
  templateUrl: './offices-template-modal.component.html',
  styleUrls: ['./offices-template-modal.component.scss']
})
export class OfficesTemplateModalComponent implements OnInit {

  icons = ICONS_CONSTANTS
  tableHead = tableModal
  public offices = offices
  @Input() tableBody:any
  @Input() item:any
  @Input() id:any
  listOrder:any [] = []
  listOperator:any [] = []
  users:any
  listVeh = [VEHICLE_EMPTY]
  listZone:any
  idVehicle:any
  idZone:number = 0
  date:any
  zone:any
  listCat:any
  comment:any
  select:number = 0
  public termConstants = termConstants
  listParams: officeListParamsEntity= officeListParamsEmpty
  constructor(
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public store:Store<AppState>,
    public apiService:ApiService
  ) { }

  ngOnInit(): void {
    if(this.item){
      this.tableBody = this.item
    }else{
      this.store.select(selectOfficeListParams).subscribe(params =>{
        this.listParams = params
        this.apiService.post(OFFICE_FILTER_URL, params).subscribe((response:any) =>{
          this.tableBody = response
        })
      })
    }
    
    this.apiService.post(PRODUCT_CATEGORIES_FILTER_URL, {}).subscribe(category => this.listCat = category)
    
    this.apiService.post(USER_FILTER_URL, {}).subscribe((response:any) =>{
      let key = response.filter((el:any)=>el.userProfiles.name === 'Operario')
      this.users = key
    })
    this.apiService.post(ZONE_FILTER_URL,{}).subscribe((ress)=>{
      this.listZone = ress
      this.store.dispatch(fetchList())
    })
  }
  sendIdZone(){
    this.idZone = this.zone
  }
  filterKeyWordCat(nameCat: any){
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: nameCat
      }
    }))
  }
  filterIdCat(id:number){
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_products_categories_id: id
      }
    }))
  }
  filterKeyWordZone(nameCat: any){
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: nameCat
      }
    }))
  }
  filterIdZone(id:number){
    this.store.dispatch(updateOfficeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_distribution_zones_id: id
      }
    }))
  }
  openAlert(){
    const asignOrder = {'users':this.listOperator}
    const modalRef = this.modalService.open(ModalAlertComponent, {
      size: 'sm'
    });
    modalRef.componentInstance.content = '¿Desea guardar la información?', 
    modalRef.componentInstance.item = {
      "enlistment_date":this.date+ ' 00:00:00',
      "orders": this.listOrder,
      "team": asignOrder.users,
      "app_dispatch_vehicles_id":this.idVehicle,
      "comments": ""
    } 
    modalRef.componentInstance.id = this.listOrder,
    modalRef.componentInstance.buttonMain = 'Crear'
    modalRef.componentInstance.url = OFFICE_ASSIGN_URL
  }
  readDataVehicle(id:any){
    this.idVehicle = id
    this.apiService.post(VEHICLE_FILTER_URL,{}).subscribe((ress:any)=>{
      this.listVeh = ress.filter((el:any)=>el.id === parseInt(id))
      this.store.dispatch(fetchList())
    })
  }
  addOrder(id:number){
    let bus = this.listOrder.indexOf(id)
    if (bus === -1){
      this.listOrder.push(id)
      this.select=this.select+1
    }else{
      this.listOrder.splice(bus, 1)
      this.select=this.select-1
    }
  }
  addOperator(id:number){
    let bus = this.listOperator.indexOf(id)
    if (bus === -1){
      this.listOperator.push(id)
    }else{
      this.listOperator.splice(bus, 1)
    }
  }

}
