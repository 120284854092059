import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { PRODUCT_PACKAGES_FILTER_URL, PRODUCT_PACKAGES_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { packageEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { PACKAGE_CODES_URL } from '../../constants/ApiConstants';
import { codes } from '../../constants/CodesConstants';
import { selectProductPackageCodesListParams } from '../../state/codes.selectors';

@Component({
  selector: 'app-codes-card-product',
  templateUrl: './codes-card-product.component.html',
  styleUrls: ['./codes-card-product.component.scss']
})
export class CodesCardProductComponent implements OnInit {
  public codes = codes
  cargued:boolean = false
  @Input() packages: packageEntity[]=[]
  @Input() idSelect:any
  @Input() content:any
  @Output() productId = new EventEmitter()
  constructor(
    private store:Store<AppState>,
    private apiService : ApiService,
    ) { }

  ngOnInit(): void {
    this.store.select(selectProductPackageCodesListParams).subscribe(params => {
      this.apiService.post(PRODUCT_PACKAGES_FILTER_URL, params).subscribe((response:any) => {
        this.packages = response
      })
    })
      
      
    
    
  }
  sendProductId(id:number){
    this.idSelect = id
    this.productId.emit(id)
    this.cargued = true
  }
 

}
