<div class="d-flex mt-4 ms-3">
    <div class="d-flex align-items-center me-5" (click)="dimissPage()">
      <i class="bi bi-{{icons.FILTER_LEFT}} text-primary fs-3"></i>
    </div>
    <div class="card-2">
        <div class="d-flex flex-wrap ">
            <div class="card shadow me-4 mt-4" *ngFor="let dateUser of operators" [ngClass]="{'active':dateUser.active}" (click)="addOperator(dateUser.id)">
              <div class="card-1 text-center p-3">
                <img class="border border-2 border-danger rounded-circle p-1" src="{{dateUser.image}}" width="80" height="80">
                <app-title [title]="dateUser.name" [fs]="'5'"></app-title>
              </div>
              <div class="text-center shadow">
                  <div class="card-adm shadow" *ngIf="dateUser.userProfiles.name==='Administrador'">
                      <app-title [title]="dateUser.userProfiles.name" [colorText]="'light fw-bolder p-2'" ></app-title>
                  </div>
                  <div class="card-jef shadow" *ngIf="dateUser.userProfiles.name==='Jefe de bodega'">
                      <app-title [title]="dateUser.userProfiles.name" [colorText]="'light fw-bolder p-2'" ></app-title>
                  </div>
                  <div class="card-op shadow" *ngIf="dateUser.userProfiles.name==='Operario'">
                      <app-title [title]="dateUser.userProfiles.name" [colorText]="'light fw-bolder p-2'" ></app-title>
                  </div>
              </div>    
              <div class="p-3">
                  <app-title [title]="'Bodega A'" [colorText]="'info'"></app-title>
                  <app-title [title]="dateUser.email" [colorText]="'secondary'"></app-title>
                  <app-title [title]="dateUser.id" [colorText]="'secondary'"></app-title>        
              </div>
            </div>
          </div>
    </div>        
    <div class="d-flex align-items-center" (click)="addPage()">
      <i class="bi bi-{{icons.FILTER_RIGHT}} text-primary fs-3"></i>
    </div> 
</div> 
