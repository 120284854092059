import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { stock } from 'src/app/modules/stock/domain/constants/StockConstants';
import { SLOT_EMPTY } from 'src/app/modules/stock/domain/constants/StockEmpty';
import { slotEntity } from 'src/app/modules/stock/domain/entities/StockEntities';
import { ApiService } from '../../services/api.service';
import { SLOTS_URL } from '../../constants/TermsConstants';

@Component({
  selector: 'app-slot-card',
  templateUrl: './slot-card.component.html',
  styleUrls: ['./slot-card.component.scss']
})
export class SlotCardComponent implements OnInit {
  idForm!:FormGroup
  model:any
  public stock = stock
  @Input() class:string = ''
  @Input() dateSlot:slotEntity = SLOT_EMPTY
  constructor(
    public apiService: ApiService,
    public formBuilder:FormBuilder,
  ) {
    this.idForm = this.formBuilder.group({
      max_product_types: new FormControl(''),

    })
   }

  ngOnInit(): void {
    this.idForm.setValue({
      max_product_types:this.dateSlot.maxProductTypes
    })
  }
  changeMaxType(){
    this.apiService.put(SLOTS_URL+this.dateSlot.id,this.idForm.value).subscribe((res:any)=>{})
  }

}
