import { Component, OnInit } from '@angular/core';
import { OPTIMIZE_CYCLE_URL, optimize } from '../../constants/OptimizeConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { PRODUCT_CATEGORIES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { packageEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-optimize-inventory-card-for-family',
  templateUrl: './optimize-inventory-card-for-family.component.html',
  styleUrls: ['./optimize-inventory-card-for-family.component.scss']
})
export class OptimizeInventoryCardForFamilyComponent implements OnInit {

  public optimize = optimize
  families:any
  order:any
  constructor(
    private apiService:ApiService,
    public modalService:NgbModal,
  ) { }

  ngOnInit(): void {
    this.apiService.post(PRODUCT_CATEGORIES_FILTER_URL, {}).subscribe(category =>{
        this.families = category
      })
  }
  sendProduct(data:any, index:number){
    this.order = data[index].id
  }
  openModalAlert(){
    const modalRef = this.modalService.open(ModalAlertComponent, {
      size: 'sm'
    });
    modalRef.componentInstance.content = 'Se va a iniciar la optimización ¿Desea continuar?', 
    modalRef.componentInstance.item = {
      app_product_categories_id: this.order
    }
    modalRef.componentInstance.buttonMain = 'Continuar'
    modalRef.componentInstance.url = OPTIMIZE_CYCLE_URL
  }

}
