import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tooltip-active',
  templateUrl: './tooltip-active.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .my-custom-class .tooltip-inner {
      background-color: blue;
    }
    .my-custom-class.bs-tooltip-end .tooltip-arrow::before {
      border-right-color: blue;
    }
    .my-custom-class.bs-tooltip-start .tooltip-arrow::before {
      border-left-color: blue;
    }
    .my-custom-class.bs-tooltip-top .tooltip-arrow::before {
      border-top-color: blue;
    }
    .my-custom-class.bs-tooltip-bottom .tooltip-arrow::before {
      border-bottom-color: blue;
    }
  `]
})
export class TooltipActiveComponent implements OnInit {

  @Input() title:string = '';
  @Input() subtitle:string = '';
  @Input() src:string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
