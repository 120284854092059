import { Component, OnInit } from '@angular/core';
import { WAREHOUSES_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { PRODUCT_CATEGORIES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { categoryEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { stock } from '../../constants/StockConstants';

@Component({
  selector: 'app-stock-template-filter-list-products',
  templateUrl: './stock-template-filter-list-products.component.html',
  styleUrls: ['./stock-template-filter-list-products.component.scss']
})
export class StockTemplateFilterListProductsComponent implements OnInit {

  listCat:any;
  listBod:any;
  public stock = stock
  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.post(PRODUCT_CATEGORIES_FILTER_URL,{}).subscribe(categories => {
      this.listCat = <categoryEntity[]>categories
    })
    this.apiService.post(WAREHOUSES_FILTER_URL,{}).subscribe(bodega => {
      this.listBod = bodega
    })
  }
  filterKeyCat($event:any){

  }
  updateKeyCat($event:any){

  }
  updateAllCat(){

  }
  filterKeyBod($event:any){

  }
  updateKeyBod($event:any){

  }
  updateAllBod(){
    
  }

}
