import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PROVIDER_FILTER_URL, termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { providerEntity } from 'src/app/modules/app-common/domain/entities/ProviderEntity';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { categoryEntity, packageEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { tableOrder } from '../../constants/TablesIncome';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { IncomeTemplateModalConfirmComponent } from '../income-template-modal-confirm/income-template-modal-confirm.component';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { income, INCOMES_URL } from '../../constants/IncomeConstants';
import { selectCloseModals } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { PRODUCT_CATEGORIES_FILTER_URL, PRODUCT_PACKAGES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';



@Component({
  selector: 'app-income-template-modal-new-order',
  templateUrl: './income-template-modal-new-order.component.html',
  styleUrls: ['./income-template-modal-new-order.component.scss']
})
export class IncomeTemplateModalNewOrderComponent implements OnInit {
  public model: any;
  orderForm!:FormGroup
  providers: any
  listProv: providerEntity[] = []
  listCat: categoryEntity[] = []
  prov?:any
  prod?:any
  cat?:any
  active:boolean=false
  packages:any
  packagesList
  head = tableOrder
  loading:boolean = false
  loader:boolean = false
  alert:boolean = false
  nameFilter:any
  nameFilt:any
  order:any[] = []
  bus:any
  idSelect:any
  icons = ICONS_CONSTANTS
  public income = income
  public termConstants = termConstants
  time = { hour: 13, minute: 30 };
  modelDate: any;
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    private store: Store<AppState>,
    private apiService: ApiService,
  ) {
    this.packagesList = this.packages

    this.store.select(selectCloseModals).subscribe(data => {
      if(data) this.activeModal.close()
    })
  }

  ngOnInit(): void {
    this.loading = true

    this.apiService.post(PROVIDER_FILTER_URL).subscribe((providers) => {
      this.listProv = <providerEntity[]>providers
      this.providers = <providerEntity[]>providers
      this.apiService.post(PRODUCT_CATEGORIES_FILTER_URL).subscribe(categories => {
        this.listCat = <categoryEntity[]>categories
        this.loader= true
      })
    })
  }
  searchProduct(keyword: string){
    if(!keyword) keyword = ''
    this.packagesList = this.packages.filter((item: packageEntity) => 
      item.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
      || item.appProducts.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
      || item.code.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
    )
  }

  filterProviders(item:any, $event:KeyboardEvent){
    if($event.key==='Enter'){
      if(item === ''){
        this.providers=this.listProv
      }else{
        this.providers= this.listProv.filter((el:any)=>el.name === item)
        this.filterPackage(this.providers)
      }
    }
  }
  
  filterPackage(id:number){
    const filPack={"app_providers_id":id}
    this.apiService.post(PRODUCT_PACKAGES_FILTER_URL, filPack).subscribe(data => {
      this.idSelect = id
      this.packagesList = <packageEntity[]>data
      this.packages = <packageEntity[]>data
      this.loading = false
      this.alert = false
      this.order = []
    })
  }

  openModalConfirm(){
    if(this.order.length===0){
      this.alert = true
    }else{
      const modalRef = this.modalService.open(IncomeTemplateModalConfirmComponent,{                                                                
        size: 'xl'
      });
      modalRef.componentInstance.packages = this.order,
      modalRef.componentInstance.url = INCOMES_URL
    }
  }

  sendProduct(data:packageEntity[], index:number, amount:number){
    if(amount > 0){
      let cos = this.order.find(el => el.id ===data[index].id)
      this.bus = this.order.includes(data[index])
      this.alert = false
      if(cos===undefined){
        this.packages[index].active = true
        this.order.push(data[index])
      }else{
        let pos = this.order.indexOf(data[index])
        this.packages[index].active = false
        this.order.splice(pos)
      }
    }
  }
}
