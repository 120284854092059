import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CLIENT_FILTER_URL, CLIENT_HEADQUARTERS_URL } from '../../constants/TermsConstants';
import { ApiService } from '../../services/api.service';
import { AppState } from '../../state.ts/app.state';
import { fetchList, rechargePage } from '../../state.ts/common.actions';
import { animate } from '@angular/animations';

@Component({
  selector: 'app-modal-edit-client-headquarters',
  templateUrl: './modal-edit-client-headquarters.component.html',
  styleUrls: ['./modal-edit-client-headquarters.component.scss']
})
export class ModalEditClientHeadquartersComponent implements OnInit {

  idForm!:FormGroup
  cli:any
  clients:any
  @Input() item?:any
  title:string= ''
  @Input() id:number = 0;
  loading$ = false
  model:any
  LatLng:any
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService: ApiService,
    public store:Store<AppState>,
    public router: Router
  ) { 
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),
      app_clients_id:new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.apiService.post(CLIENT_FILTER_URL).subscribe((res:any)=>{
      this.clients=res
    })
    this.title=this.item ? 'Edición': 'Crear'
    if(this.item){
      this.idForm.setValue({
        name:this.item.name,
        app_clients_id:this.item.appClients.id,
      })
      this.model = { lat:this.item.lat, lng: this.item.lng }
  }
    }
    
  addLatLng(pos:any){
    this.LatLng = pos[0];
    const source = this.idForm.value;
    const returnedTarget = Object.assign(this.LatLng, source);
  }
  sendProviders(){
    if(this.item){
      this.apiService.put(CLIENT_HEADQUARTERS_URL+this.item.id,this.LatLng || this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
      
    }else{
      this.apiService.post(CLIENT_HEADQUARTERS_URL, this.LatLng || this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
    }
    this.store.dispatch(rechargePage({loading:this.loading$}))

   
  }

}