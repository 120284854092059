import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api.service';
import { AppState } from '../../state.ts/app.state';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CLIENT_FILTER_URL, CLIENT_HEADQUARTERS_URL, VEHICLE_FILTER_URL, VEHICLE_URL } from '../../constants/TermsConstants';
import { fetchList, rechargePage } from '../../state.ts/common.actions';
@Component({
  selector: 'app-modal-edit-vehicles',
  templateUrl: './modal-edit-vehicles.component.html',
  styleUrls: ['./modal-edit-vehicles.component.scss']
})
export class ModalEditVehiclesComponent implements OnInit {

  idForm!:FormGroup
  cli:any
  clients:any
  @Input() item?:any
  title:string= ''
  @Input() id:number = 0;
  loading$ = false
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService: ApiService,
    public store:Store<AppState>,
    public router: Router
  ) { 
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),
      dimension_width:new FormControl(''),
      dimension_height:new FormControl(''),
      dimension_depth:new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.title=this.item ? 'Edición': 'Crear'
    if(this.item){
      this.idForm.setValue({
        name:this.item.name,
        dimension_width:this.item.dimensionWidth,
        dimension_height:this.item.dimensionHeight,
        dimension_depth:this.item.dimensionDepth,
      })
    }
  }
  sendProviders(){
    if(this.item){
      this.apiService.put(VEHICLE_URL+this.item.id, this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
      
    }else{
      this.apiService.post(VEHICLE_URL, this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
    }
    this.store.dispatch(rechargePage({loading:this.loading$}))

   
  }

}