import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { usersListParamsEmpty, usersListParamsEntity } from 'src/app/modules/users/domain/entities/UsersListParamsEntity';
import { updateUsersListParams } from 'src/app/modules/users/domain/state/users.actions';
import { INCOMES_URL } from '../../constants/IncomeConstants';

@Component({
  selector: 'app-income-template-modal-income',
  templateUrl: './income-template-modal-income.component.html',
  styleUrls: ['./income-template-modal-income.component.scss']
})
export class IncomeTemplateModalIncomeComponent implements OnInit {

  public termConstants = termConstants
  icons = ICONS_CONSTANTS
  listParams: usersListParamsEntity = usersListParamsEmpty
  listOperator:any
  @Input() id:any;
  constructor(
    public activeModal: NgbActiveModal,
    private store :Store<AppState>,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    
  }
  updateList(keyword: string){
    this.store.dispatch(updateUsersListParams({keyUser:{
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: keyword
      }
    }}))
  }
  sendOperators(){
    this.apiService.put(INCOMES_URL+this.id,{"users":this.listOperator}).subscribe((rs)=>{
      this.activeModal.dismiss('Cross click')
    })
  }
  setUsers($event:any){
    this.listOperator = $event
  }

}
