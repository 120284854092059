<div class="card p-2">
    <div class="text-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="text-center p-3">
        <app-title [title]="'Atención'" [fs]="'3'" [colorText]="'danger'"></app-title>
        <app-title [title]="content" [fs]="'5'"></app-title>
        <app-title [title]="code" [fs]="'5'" [colorText]="'primary'"></app-title>
        
    </div>
    <div class="d-flex align-items-center justify-content-center mt-3">
        <button class="btn button-blue btn-sm" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="action()">{{buttonMain}}</button>
    </div>
</div>