import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalService } from 'src/app/modules/auth/domain/services/local.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public http:HttpClient, public localService : LocalService,) { }

  get(url: string) {
    return this.http.get(environment.API_URL + url, {
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('token') || ''
      }
    })
  }

  post(url: string, data:any = {}) {
    return this.http.post(environment.API_URL + url,data,{
      headers:{
        "Authorization": 'Bearer ' + localStorage.getItem('token') || ''
      }
    })
    
  }
  put(url: string, data:any = {}) {
    return this.http.put(environment.API_URL + url,data,{
      headers:{
        "Authorization": 'Bearer ' + localStorage.getItem('token') || ''
      }
    })
  }
  delete(url: string) {
    return this.http.delete(environment.API_URL + url,{
      headers:{
        "Authorization": 'Bearer ' + localStorage.getItem('token') || ''
      }
    })
  }
}
