import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/modules/app-common/domain/state.ts/app.state";
import { CommonState } from "../entities/CommonState";

 
export const selectCommonFeature = (state:AppState) => state.common;
 
export const selectFilterProviders = createSelector(
  selectCommonFeature,
  (state: CommonState) => state.fetch
);
export const selectGetProviders = createSelector(
    selectCommonFeature,
    (state: CommonState) => state.provider
  );

export const selectrechargePage = createSelector(
    selectCommonFeature,
    (state: CommonState) => state.loading
  );

export const selectCloseModals = createSelector(selectCommonFeature, (state: CommonState) => state.closeModals )
export const selectFetch = createSelector(selectCommonFeature, (state: CommonState) => state.fetch )