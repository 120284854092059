import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['./form-input-text.component.scss']
})
export class FormInputTextComponent implements OnInit {

  @Input() title:string = '';
  @Input() colorText:string = '';
  @Input() placeholder:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
