import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor( public store:Store) { }
  getItem() {
    let dataUser:any = localStorage.getItem("isUserLoggedIn")
    let json = JSON.parse(dataUser)
    return json
  }
}
