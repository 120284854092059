import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ApiService } from '../../services/api.service';
import { AppState } from '../../state.ts/app.state';
import { closeModals, fetchList } from '../../state.ts/common.actions';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {

  @Input() content:string = '';
  @Input() buttonMain:string = '';
  @Input() url:string = '';
  @Input() id:string = '';
  @Input() item:any;
  @Input() actionHandler: any
  @Input() code: any

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }
  
  action(){
    if(this.buttonMain==='Crear'){
      this.apiService.post(this.url, this.item).subscribe((rs)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(closeModals())
        this.store.dispatch(fetchList())
      })
    }
    if(this.buttonMain==='Eliminar'){
      this.apiService.delete(this.url+this.id).subscribe((rs)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(closeModals())
        this.store.dispatch(fetchList())
      })
    }
    if(this.buttonMain==='Guardar'){
      this.apiService.put(this.url, this.item).subscribe((rs)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(closeModals())
        this.store.dispatch(fetchList())
      })
    }
    if(this.buttonMain==='Continuar'){
      this.apiService.post(this.url, this.item).subscribe((rs)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(closeModals())
        this.store.dispatch(fetchList())
      })
    }
  }
}


