export const API_CONST = {
  providers: '/data/providers/',
  product: '/products/products/',
  packing: '/products/packages/',
  family: '/products/categories/',
  client: '/clients/',
  headquarter: '/client_headquarters/',
  cellar: '/warehouses/',
  type: '/users/document_types/',
  zone: '/enlistment_zones/',
  vehicle: '/vehicles/',
  distribution: '/distribution_zones/'
}
export const TITLE_CONST = {
  providers: 'Proveedor',
  product: 'Producto',
  packing: 'Paquete',
  family: 'Categoria',
  client: 'Cliente',
  headquarter:'Sede',
  cellar: 'Bodega',
  type: 'Tipo de cedula',
  zone: 'Zona',
  vehicle: 'Vehículo',
  distribution:'Zona de distribución'

}