import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/domain/guards/auth.guard';
import { FeatureIncomeComponent } from './feature-income/feature-income.component';

const routes: Routes = [
  {path: 'ingresos', component: FeatureIncomeComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IncomeRoutingModule { }
