import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { Store } from '@ngrx/store';
import { PATH_LOGIN } from 'src/app/modules/app-common/domain/constants/PathConstants';

import { SideBarService } from 'src/app/modules/nav/domain/services/sidebar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( private router:Router,public store:Store,  private sideBarService:SideBarService) {
  }


  async canActivate() {
    const isUserLoggedIn = await localStorage.getItem('token');
    if(isUserLoggedIn)
    {
      this.sideBarService.show()
      return true;
    }else {
      this.router.navigateByUrl(PATH_LOGIN)
    }
    return false;
  }
}
