import { createReducer, on } from "@ngrx/store";
import { StockState } from "./StockState";
import { loadStock, updateStockListParams } from "./stock.actions";
import { stockListParamsEmpty } from "../entities/StockListParamsEntity";

export const initialState: StockState = {dates: [], 
  listParams: stockListParamsEmpty,
  
}

export const stockReducer = createReducer(
  initialState,
  on(loadStock, (state,{dates}) => ({ ...state, dates})),
  on(updateStockListParams, (state, listParams) => ({
    ...state,
    listParams
  })),
);
