import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { SLOTS_FILTER_URL, STOCK_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { stock } from '../../constants/StockConstants';
import { SLOT_EMPTY } from '../../constants/StockEmpty';
import { slotEntity } from '../../entities/StockEntities';
import { selectStockListParams } from '../../state/stock.selectors';
import { StockTemplateModalSlotComponent } from '../../templates/stock-template-modal-slot/stock-template-modal-slot.component';

@Component({
  selector: 'app-slot-card-accordeon',
  templateUrl: './slot-card-accordeon.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .card.disabled {
      opacity: 0.5;
    }
    .custom-header::after {
      content: none;
    }
  `]
  
})
export class SlotCardAccordeonComponent implements OnInit {

  loading:boolean = false
  public stock = stock
  disabled = false;
  dateSlotsA:any
  @Input() dateSlot:slotEntity= SLOT_EMPTY
  icons = ICONS_CONSTANTS
  constructor(
    public modalService: NgbModal,
    private apiService : ApiService,
    private store: Store<AppState>
    ) { }

  ngOnInit(): void {
    this.store.select(selectStockListParams).subscribe((params:any)=>{
      this.apiService.post(SLOTS_FILTER_URL,params).subscribe((slot:any)=>{
        this.dateSlotsA = slot
        this.loading = true
      })
    })
    
  }
  openModalSlot(item:any) {
    const modalRef = this.modalService.open(StockTemplateModalSlotComponent,{
      size: 'xxl'
    });
    modalRef.componentInstance.item = item
  }

}
