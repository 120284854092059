import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { stock } from '../../constants/StockConstants';
import { slotEntity } from '../../entities/StockEntities';
import { SLOT_EMPTY } from '../../constants/StockEmpty';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { SLOTS_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-stock-modal-max-types',
  templateUrl: './stock-modal-max-types.component.html',
  styleUrls: ['./stock-modal-max-types.component.scss']
})
export class StockModalMaxTypesComponent implements OnInit {

  idForm!:FormGroup
  model:any
  public stock = stock
  @Input() class:string = ''
  @Input() max:number = 0
  @Input() id:number = 0
  @Input() dateSlot:slotEntity = SLOT_EMPTY
  constructor(
    public apiService: ApiService,
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
  ) {
    this.idForm = this.formBuilder.group({
      max_product_types: new FormControl(''),

    })
   }

  ngOnInit(): void {
    this.idForm.setValue({
      max_product_types:this.max
    })
  }
  changeMaxType(){
    this.apiService.put(SLOTS_URL+this.id,this.idForm.value).subscribe((res:any)=>{})
    this.activeModal.dismiss('Cross click')
  }

}
