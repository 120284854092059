<div class="card p-3">
    <div class="d-flex justify-content-between">
        <app-title [title]="'Optimizacion de llenado manual'" [class]="'fw-bolder mb-2'" ></app-title>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="d-flex">
        <div class="col-3 p-2 mt-2">
            <app-title [title]="'Slot a optimizar'" [fs]="'6 fw-bolder mb-3'" [colorText]="'secondary'"></app-title>
            <div class="card-1">
                <app-optimize-level-accordeon [optimize]="true" (sendItemSlot)="showProducts($event)"></app-optimize-level-accordeon>
            </div>
        </div>
        <div class="col ps-3 pt-1 mt-2">
            <app-title [title]="'Selecciona los productos a trasladar'" [fs]="'6 fw-bolder mb-3'" [colorText]="'secondary'"></app-title>
            <table class="table" >
                <tr class="d-flex">
                  <app-item-table *ngFor="let item of tableHead" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
                </tr>
                <div *ngIf="products">
                    <tr class="row" *ngFor="let Table of products; index as i">
                        <app-row-item [src]="'/assets/icon3-blue.png'" [active]="Table.active" class="bg-white col-1 border border-light" ></app-row-item>
                        <app-row-item [title]="Table.appProducts.appProductCategories.name" class="bg-white col-2 border border-light" ></app-row-item>
                        <app-row-item [title]="Table.appProducts.name" [src]="'/assets/image.png'" class="bg-white col border border-light" ></app-row-item>
                        <td class="col-2">
                            <div class="input-group input-group-sm">
                              <input class="form-control" value="" type="text" [(ngModel)]="Table.amount" (change)="sendProduct(products, i, Table.amount)">
                            </div>                  
                          </td>
                      </tr>
                </div>
              </table>
        </div>
        <div class="col-3 p-2 mt-2">
            <app-title [title]="'Slot a optimizar'" [fs]="'6 fw-bolder mb-3'" [colorText]="'secondary'"></app-title>
            <div class="card-1">
                <app-optimize-level-accordeon-opt (slotId)="addSlotId($event)"></app-optimize-level-accordeon-opt>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-center mt-3">
        <button class="btn button-blue btn-sm me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button class="btn button-red-strong btn-sm" (click)="openModalSelectOpers()">Iniciar</button>
    </div>  
</div>