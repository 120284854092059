export const codes = {
    codesCard: {
        img: './assets/code.png',
        code: 'Código',
        product: 'Producto',
        imgBox: './assets/caja.png',
        box: 'Caja',
        asign: 'Asignar producto',
        delete: 'Eliminar código'

    },
    filter: {
        code: 'Codigo',
        category: 'Categoría'

    },
    modal: {
        products: 'Productos',
        description: 'Selecciona el Producto para matricularlo al código'
    }

}