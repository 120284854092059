import { createSelector } from '@ngrx/store';
import { NavState } from './NavState';
import { navStateEntity } from '../entities/navStateEntity';

 
export const selectShowFeature = (state:NavState) => state.nav;
 
export const selectshowSide = createSelector(
  selectShowFeature,
  (state: navStateEntity) => state.showSidebar
);
export const selectshowHead = createSelector(
  selectShowFeature,
  (state: navStateEntity) => state.showHeader
);
export const selectCenterContent= createSelector(
  selectShowFeature,
  (state: navStateEntity) => state.centerContent
);