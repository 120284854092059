import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IncomeRoutingModule } from './income-routing.module';
import { FeatureIncomeComponent } from './feature-income/feature-income.component';
import { AppCommonModule } from '../app-common/app-common.module';
import { IncomeTemplateFilterComponent } from './domain/templates/income-template-filter/income-template-filter.component';
import { IncomeTemplateListComponent } from './domain/templates/income-template-list/income-template-list.component';
import { CardIncomeComponent } from './domain/organisms/card-income/card-income.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IncomeTemplateModalIncomeComponent } from './domain/templates/income-template-modal-income/income-template-modal-income.component';
import { UsersModule } from '../users/users.module';
import { IncomeTemplateModalAsignComponent } from './domain/templates/income-template-modal-asign/income-template-modal-asign.component';
import { OfficesModule } from '../offices/offices.module';
import { IncomeTemplateModalNewOrderComponent } from './domain/templates/income-template-modal-new-order/income-template-modal-new-order.component';
import { CardSupplierComponent } from './domain/organisms/card-supplier/card-supplier.component';
import { IncomeTemplateModalProductsComponent } from './domain/templates/income-template-modal-products/income-template-modal-products.component';
import { NavModule } from '../nav/nav.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IncomeTemplateModalConfirmComponent } from './domain/templates/income-template-modal-confirm/income-template-modal-confirm.component';
import { CardOperatorComponent } from './domain/organisms/card-operator/card-operator.component';


@NgModule({
  declarations: [
    FeatureIncomeComponent,
    IncomeTemplateFilterComponent,
    IncomeTemplateListComponent,
    CardIncomeComponent,
    IncomeTemplateModalIncomeComponent,
    IncomeTemplateModalAsignComponent,
    IncomeTemplateModalNewOrderComponent,
    CardSupplierComponent,
    IncomeTemplateModalProductsComponent,
    IncomeTemplateModalConfirmComponent,
    CardOperatorComponent,
  ],
  imports: [
    CommonModule,
    IncomeRoutingModule,
    AppCommonModule,
    NgbModule,
    UsersModule,
    OfficesModule,
    NavModule,
    ReactiveFormsModule,
    FormsModule,
    
  ]
})
export class IncomeModule { }
