import { ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

export const stock = {
    productCard: {
        code: 'Código',
        product: 'Producto',
        units: 'Unidades',
        boxs: 'Cajas',
        imgBoxs: './assets/caja-out.png',
        sachets: 'Sachets',
        imgSachets: './assets/sachets-out.png',
        packing: 'Empaque',
        imgPacking: './assets/empaque-out.png',
        ocupation: 'Ocupación',
        slot: 'Slot',
        actions: 'Acciones'
    },
    slotCard: {
        slot: 'Slot',
        products: 'Productos:',
        volumen: 'Volumen:',
        date: 'Dato:',
        level: 'Nivel',
        manage: 'Administrar',
    },
    subSlotCard: {
        units: 'Unidades',
        code: 'Código'
    },
    filter: {
        newSlot: 'Crear slot',
        category: 'Categoria',
        cellar: 'Bodega',
        updateFile: 'Actualizar slots'
    },
    listSlots: {
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D'
    },
    modal: {
        slot: 'Slot',
        level: 'Nivel:',
        imgSlot: './assets/slot.png',
        volumenSlot: 'Volumen del slot',
        lastOptimization: 'Ultima optimización',
        cantProducts: 'Cantidad de productos',
        date: 'Dato:',
        actions: 'Accioneas',
        optimizeSlot: 'Optimizar slot',
        levelSubSlots: 'Nivel de sub slots',
        products: 'Productos',
        category: 'Categoria'
    }
}
export const letters = [
    {
        title: 'A'
    },
    {
        title: 'B'
    },
    {
        title: 'C'
    },
    {
        title: 'D'
    },
]