import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { typesPackages } from '../../constants/ProductsConstants';
import { selectProductsListParams } from '../../state/products.selectors';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { PRODUCT_URL, PROVIDER_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { providerEntity } from 'src/app/modules/app-common/domain/entities/ProviderEntity';
import { PRODUCT_CATEGORIES_FILTER_URL, PRODUCT_PACKAGES_FILTER_URL, PRODUCT_PACKAGES_URL } from '../../constants/ApiConstants';
import { categoryEntity } from '../../entities/ProductsEntities';
import { fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { tablePackagesModal } from '../../constants/TableProducts';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { productsListParamsEmpty, productsListParamsEntity } from '../../entities/ProductsListParamsEntity';
import { ModalNewPackingComponent } from 'src/app/modules/app-common/domain/templates/modal-new-packing/modal-new-packing.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-product-modal-new-product',
  templateUrl: './product-modal-new-product.component.html',
  styleUrls: ['./product-modal-new-product.component.scss']
})
export class ProductModalNewProductComponent implements OnInit {

  packingForm!:FormGroup
  productForm!:FormGroup
  @Input() item?:any
  head = tablePackagesModal
  title:string= ''
  @Input() id:number = 0;
  product:any
  listCat:any
  packages:any
  listProv:any
  i:any
  image:any
  imageObj: any
  modelProv:any
  modelCat:any
  file:any
  loading = false
  types = typesPackages
  listParams: productsListParamsEntity = productsListParamsEmpty
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    private store:Store<AppState>,
     public apiService:ApiService,
     public modalService:NgbModal,
     private sanitizer:DomSanitizer
  ) { 
    this.productForm = this.formBuilder.group({
      name: new FormControl(''),
      image: new FormControl(''),
      quantity:new FormControl(''),
      dimension_depth:new FormControl(''),
      dimension_width:new FormControl(''),
      dimension_height:new FormControl(''),
    })
    this.packingForm = this.formBuilder.group({
      name: new FormControl(''),
      quantity:new FormControl(''),      
    })
    
  }

  ngOnInit(): void {
      this.apiService.post(PROVIDER_FILTER_URL,{}).subscribe((data:any) => this.listProv = <providerEntity[]>data)
      this.apiService.post(PRODUCT_CATEGORIES_FILTER_URL,{}).subscribe((data:any) => this.listCat = <categoryEntity[]>data)
      this.apiService.post(PRODUCT_PACKAGES_FILTER_URL,{
        ...this.listParams,
        filters: {
          ...this.listParams.filters,
          keyword: this.item.name
        }
      }).subscribe((ress)=>this.packages = ress)
    this.title=this.item ? 'Edición': 'Creación'
    if(this.item){
      this.productForm.setValue({
        name:this.item.name,
        quantity:this.item.quantity,
        image: this.item.imageUrl || null,
        dimension_depth:this.item.appProducts?.dimensionDepth,
        dimension_width:this.item.appProducts?.dimensionWidth,
        dimension_height:this.item.appProducts?.dimensionHeight,
      })
      this.packingForm.setValue({
        name:this.item.name,
        quantity:this.item.quantity,
      })
      this.modelProv = this.item.appProducts?.appProviders?.name
      this.modelCat = this.item.appProducts?.appProductCategories?.name
    }
    
  }
  AddIdProv(id:number){
  }
  AddIdCat(id:number){
    
  }
  openModalEdit(item:any, index:number){
    const modalRef = this.modalService.open(ModalNewPackingComponent,{
      size: 'lg'
    });
    modalRef.componentInstance.item = item, modalRef.componentInstance.id = index
  }
  sendProviders(){
  }
  newProduct(){
    if(this.item){
      this.apiService.put(PRODUCT_URL+this.item.id, this.productForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(fetchList())
      })
      
    }else{
      this.apiService.post(PRODUCT_URL, this.productForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(fetchList())
      })
      
    }

  }
  openAlert(index:number){
    const modalRef = this.modalService.open(ModalAlertComponent,{
      size: 'sm'
    });
    modalRef.componentInstance.content = '¿Desea eliminar el paquete?',
    modalRef.componentInstance.code = this.item.name,
    modalRef.componentInstance.id = index,
    modalRef.componentInstance.url = PRODUCT_PACKAGES_URL,
    modalRef.componentInstance.buttonMain = 'Eliminar'
  }
  onFileSelect(event:any) {
    this.imageObj = event.target
    this.image = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.target.files[0]))
  }

}
