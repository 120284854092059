export const STOCK_PRODUCT_EMPTY = {
    cod: '',
    image: '',
    name: '',
    unit: '',
    box: '',
    sachets: '',
    packing: '',
    cellar: '',
    slot: '',
    
  }

export const SLOT_EMPTY = {
    id:0,
    name: '',
    slot: '',
    product: '',
    vol: '',
    date: '',
    level: 0,
    value: 0,
    dimensionDepth:0,
    dimensionHeight:0,
    dimensionWidth:0,
    appSlots:[],
    appProducts:[],
    quantity:0,
    appStock:[],
    maxProductTypes:0,
    productLevels:{}
  }
  export const SLOT_PRODUCT_EMPTY = {
    id:0,
    code: '',
    image: '',
    name: '',
    unit: '',
    
  }