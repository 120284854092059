import { createReducer, on } from '@ngrx/store';
import { navStateEntity } from '../entities/navStateEntity';
import {centerContent, hideHeader, hideSidebar, normalContent, showHeader, showSidebar} from './nav.actions';

export const initialState: navStateEntity = {showSidebar:false, showHeader:false, centerContent:false}

export const navReducer = createReducer(
  initialState,
  on(showSidebar, state => ({ ...state, showSidebar:true})),
  on(hideSidebar, state => ({ ...state, showSidebar:false})),
  on(showHeader, state => ({ ...state, showHeader:true})),
  on(hideHeader, state => ({ ...state, showHeader:false})),
  on(centerContent, state => ({ ...state, centerContent: true})),
  on(normalContent, state => ({ ...state, centerContent:false})),
);


