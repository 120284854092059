import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { PRODUCT_PACKAGES_FILTER_URL, PRODUCT_PACKAGES_URL } from '../../constants/ApiConstants';
import { tableProducts } from '../../constants/TableProducts';
import { packageEntity } from '../../entities/ProductsEntities';
import { selectProductsListParams } from '../../state/products.selectors';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductModalNewProductComponent } from '../product-modal-new-product/product-modal-new-product.component';

@Component({
  selector: 'app-products-template-table',
  templateUrl: './products-template-table.component.html',
  styleUrls: ['./products-template-table.component.scss']
})
export class ProductsTemplateTableComponent implements OnInit {

  loading:boolean=false
  head = tableProducts
  products:packageEntity[]=[]
  constructor(
   private store: Store<AppState>,
   public apiService:ApiService,
   public modalService:NgbModal,
  ) { }

  ngOnInit(): void {
    this.store.select(selectProductsListParams).subscribe(params => {
      this.apiService.post(PRODUCT_PACKAGES_FILTER_URL, params).subscribe((data:any) => {
        this.products = <packageEntity[]>data
        this.loading = true
      })
    })
  }
  openModalEdit(item:any, index:number){
    const modalRef = this.modalService.open(ProductModalNewProductComponent,{
      size: 'lg'
    });
    modalRef.componentInstance.item = item, modalRef.componentInstance.id = index
  }
  openAlert(index:number){
    const modalRef = this.modalService.open(ModalAlertComponent,{
      size: 'sm'
    });
    modalRef.componentInstance.content = '¿Desea eliminar el producto?', modalRef.componentInstance.id = index, modalRef.componentInstance.url = PRODUCT_PACKAGES_URL,
    modalRef.componentInstance.buttonMain = 'Eliminar'
  }

}
