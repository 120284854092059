export interface stockListParamsEntity{
    filters: {
      keyword: string,
    },
    page: number,
    size: number
  }
  
  export const stockListParamsEmpty: stockListParamsEntity = {
    filters: {
      keyword: '',
    },
    page: 1,
    size: 10,
  }