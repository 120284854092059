import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { STOCK_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { PRODUCT_PACKAGES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { tableProducts } from 'src/app/modules/products/domain/constants/TableProducts';
import { packageEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { selectProductsListParams } from 'src/app/modules/products/domain/state/products.selectors';

@Component({
  selector: 'app-stock-template-list-products',
  templateUrl: './stock-template-list-products.component.html',
  styleUrls: ['./stock-template-list-products.component.scss']
})
export class StockTemplateListProductsComponent implements OnInit {
  loading:boolean=false
  head = tableProducts
  products:packageEntity[]=[]
  dateProducts:any
  constructor(
    private store: Store<AppState>,
    public apiService:ApiService,
  ) { }

  ngOnInit(): void {
    this.store.select(selectProductsListParams).subscribe(params => {
      this.apiService.post(STOCK_FILTER_URL, params).subscribe((data:any) => {
        this.products = <packageEntity[]>data
        this.loading = true
      })
    })
  }

}
