<app-title [title]="'Inventario cíclico'"  [fs]="'3 fw-bolder mb-3'"></app-title> 
<div class="bg-white">  
  <div class="mt-3">
      <ul ngbNav ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink class="d-flex align-items-center bg-white">
              <img src="./assets/Grupo 63-.png" width="15">
              <app-title [title]="'Por producto'" [class]="'fw-bolder'" [fs]="'6 ms-3'" [colorText]="'secondary'"></app-title>
            </a>
            <ng-template ngbNavContent>
              <div class="bg-white">
                  <app-optimize-inventory-card></app-optimize-inventory-card>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center bg-white">
              <img src="./assets/Grupo 12.png" width="25">
              <app-title [title]="'Por familia'" [class]="'fw-bolder'" [fs]="'6 ms-3'" [colorText]="'secondary'"></app-title>
            </a>
            <ng-template ngbNavContent>
              <div class="bg-white">
                  <app-optimize-inventory-card-for-family></app-optimize-inventory-card-for-family>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center bg-white">
              <img src="./assets/Grupo 25-.png" width="15">
              <app-title [title]="'Por slot'" [class]="'fw-bolder'" [fs]="'6 ms-3'" [colorText]="'secondary'"></app-title>
            </a>
              <ng-template ngbNavContent>
                <div class="bg-white">
                  <app-optimize-inventory-card-for-slot></app-optimize-inventory-card-for-slot>
                </div>
              </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
  </div>
  
</div>
