<div class="d-flex justify-content-center w-100" *ngIf="!loading">
    <div class="spinner-border text-danger" role="status">
    </div>
</div>
<div class="d-flex" *ngIf="loading">
  <div class="card shadow-sm me-4" *ngFor="let item of dateProfile">
    <div class="card-2  mb-3 text-center">
        <div class="card-adm shadow" *ngIf="item.name==='Administrador'">
            <app-title [title]="item.name" [colorText]="'light fw-bolder p-2'" ></app-title>
        </div>
        <div class="card-jef shadow" *ngIf="item.name==='Jefe de bodega'">
            <app-title [title]="item.name" [colorText]="'light fw-bolder p-2'" ></app-title>
        </div>
        <div class="card-op shadow" *ngIf="item.name==='Operario'">
            <app-title [title]="item.name" [colorText]="'light fw-bolder p-2'" ></app-title>
        </div>
        <div class="card-red shadow" *ngIf="item.name!=='Operario' && item.name!=='Jefe de bodega' && item.name!=='Administrador'">
            <app-title [title]="item.name" [colorText]="'light fw-bolder p-2'" ></app-title>
        </div>
    </div>
    <div class="card-1 d-flex align-items-center justify-content-center mt-1">
      <img src="{{item.imageUrl}}" width="80" height="80">
    </div>   
    <div class="d-flex justify-content-center pt-3">
        <button class="btn button-red btn-sm" type="button" (click)="openModalNewProfile(item)">{{users.cardProfile.edit}}</button>
    </div>

</div>
</div>
