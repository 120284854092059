import { createAction, props } from "@ngrx/store";
import { optimizeListParamsEntity } from "../entities/OptimizeListParamsEntity";

export const loadOptimize = createAction('[Auth Login] load Api',
props<{dates:any}>()
)
export const updateOptimizeListParamsTwo = createAction('[Incomes] Update list params', 
props<optimizeListParamsEntity>())


