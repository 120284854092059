<div class="d-flex justify-content-center w-100" *ngIf="!loading">
    <div class="spinner-border text-danger" role="status">
    </div>
</div>
<div class="mt-3 h-100" *ngIf="loading">
    <div class="card-over-red">
        <app-codes-card [item]="item" *ngFor="let item of codesList"></app-codes-card>
    </div>
    <div class="mt-3">
        <ngb-pagination
            [collectionSize]="collectionSize"
            [(page)]="listParams.page"
            [pageSize]="listParams.size"
            (pageChange)="updateListParams()">
          </ngb-pagination>
    </div>
</div>
