import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  @Input() title:any;
  @Input() colorText:string = '';
  @Input() fs:string = '';
  @Input() class:string = '';
  @Input() colorBg:string = '';
  
  constructor() { }

  ngOnInit(): void {
  }

}
