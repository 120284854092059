import { createReducer, on } from "@ngrx/store";
import { incomeListParamsEmpty } from "../entities/IncomeListParamsEntity";
import { IncomeState } from "./IncomeState";
import { updateIncomeListParams } from "./income.actions";

export const initialState: IncomeState = {
  listParams: incomeListParamsEmpty
}

export const incomeReducer = createReducer(
  initialState,
  on(updateIncomeListParams, (state, listParams) => ({
    ...state,
    listParams
  })),

);