<div class="card p-3 bg-white">
  <div class="d-flex justify-content-between">
      <app-title [title]="'Confirmación de la orden de compra'" [class]="'fw-bolder mb-2'" ></app-title>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <app-title [title]="'Productos seleccionados'" [colorText]="'secondary'" [class]="'fw-bolder mb-2'"></app-title>
  <div class="d-flex mb-4" *ngIf="order">
    <div class="border-end pe-5 ps-5">
      <app-title [title]="'Cliente'" [colorText]="'secondary'" [class]="'fw-bolder mb-2'"></app-title>
      <app-title [title]="order[1]?.clientName" [class]="'fw-bolder mb-2'" [colorText]="'info'"></app-title>
    </div>
    <div class="border-end pe-5 ps-5">
      <app-title [title]="'Fecha'" [colorText]="'secondary'" [class]="'fw-bolder mb-2'"></app-title>
      <app-title [title]="order[2]?.date" [class]="'fw-bolder mb-2'" [colorText]="'info'"></app-title>
    </div>
    <div class="pe-5 ps-5">
      <app-title [title]="'Hora'" [colorText]="'secondary'" [class]="'fw-bolder mb-2'"></app-title>
      <app-title [title]="order[3]?.hour" [class]="'fw-bolder mb-2'" [colorText]="'info'"></app-title>
    </div>

  </div>
<table class="table" >
  <tr class="d-flex">
    <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
  </tr>
  
  <div>
    <tr class="head d-flex" *ngFor="let Table of packages || order[4].packages" (keyup)="update($event)">
      <app-row-item [src]="'./assets/Grupo 67.png'" [active]="active" class="col-1"></app-row-item>             
      <app-row-item [title]="Table.appProducts?.code" class="bg-white col-1 border border-light" ></app-row-item>
      <app-row-item [title]="Table.appProducts?.appProductCategories?.name" class="bg-white col-4 border border-light" ></app-row-item>
      <app-row-item [title]="Table.appProducts?.name" [src]="'./assets/image.png'" class="bg-white col border border-light" ></app-row-item>
      <td class="col-1">
        <div class="input-group input-group-sm">
          <input class="form-control" value="" type="text" [(ngModel)]="Table.amount">
        </div>                  
      </td>
    </tr>
  </div>
</table>
<app-title [title]="'Totales'" [class]="'fw-bolder mb-2'" ></app-title>
<div class="d-flex">
  <div class="col-3">
    <app-title [title]="'Unidades solicitadas'" [class]="'mb-2'" ></app-title>
    <app-title [title]="'Productos solicitados'" [class]="'mb-2'" ></app-title>
  </div>
  <div class="col">
    <app-title [title]="units" [class]="'fw-bolder mb-2'" ></app-title>
    <app-title [title]="products" [class]="'fw-bolder mb-2'" ></app-title>
  </div>
</div>
<div class="border border-danger text-center text-danger" *ngIf="loading">
  No hay ninguna orden seleccionada
</div>
<div class="d-flex justify-content-center mt-4">
  <button class="btn button-blue btn-sm me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
  <button class="btn button-red-strong btn-sm" (click)=" confirmOrder()">Crear</button>
</div> 
