<div class="card opacity-{{opacity}} border-0 mb-3">
    <div class="d-flex align-items-center">
        <div class="border-end pe-3">
            <app-title [title]="optimize.optimizeCard.code" [colorText]="'secondary'"></app-title>
            <app-title [title]="dateProduct.code" [fs]="'6 fw-bolder'" [colorText]="'secondary'"></app-title>
        </div>
        <div class="d-flex align-items-center border-end">
            <div class="card-1 border rounded ms-3 me-3 ">
                <img src="{{dateProduct.image}}">
            </div>
            <div class="pe-2">
                <app-title [title]="dateProduct.product" [fs]="'6 fw-bolder'" [colorText]="'secondary'"></app-title>
            </div>
        </div>
        
        <div class="ps-2 pe-2">
            <app-title [title]="optimize.optimizeCard.units" [colorText]="'secondary'"></app-title>
            <app-title [title]="dateProduct.units" [fs]="'6 fw-bolder'" [colorText]="'secondary'"></app-title>
        </div>
    </div>

</div>