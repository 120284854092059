import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { PACKAGE_CODES_URL } from '../../constants/ApiConstants';
import { codes } from '../../constants/CodesConstants';
import { CodeEntity } from '../../entities/CodesEntity';
import { CodesTemplateModalComponent } from '../../templates/codes-template-modal/codes-template-modal.component';
import { selectProductPackageCodesListParams } from '../../state/codes.selectors';
import { PRODUCT_PACKAGES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { packageEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { ProductPackageCodesEmpty, ProductPackageCodesEntity } from '../../entities/ProductPackageCodesEntity';
import { updateProductPackageCodesListParams } from '../../state/codes.actions';

@Component({
  selector: 'app-codes-card',
  templateUrl: './codes-card.component.html',
  styleUrls: ['./codes-card.component.scss']
})
export class CodesCardComponent implements OnInit {

  codesList:any
  public codes = codes
  packages:any
  @Input() dateCode:CodeEntity[] = []
  @Input() item:any
  @Input() showButtons:boolean = false
  listParams: ProductPackageCodesEntity = ProductPackageCodesEmpty
  constructor(
    public modalService:NgbModal,
    private store:Store<AppState>,
    public apiService:ApiService,
  ) { }

  ngOnInit(): void {
    
  }
  asignPackage(id:number, item:any) {
    const modalRef = this.modalService.open(CodesTemplateModalComponent,{
      size: ''
    });modalRef.componentInstance.id = id,
    modalRef.componentInstance.item = item
  }
  openAlert(id:number, code:string){
    const modalRef = this.modalService.open(ModalAlertComponent,{
      size: 'sm'
    });modalRef.componentInstance.content = '¿Desea eliminar el codigo?',
    modalRef.componentInstance.buttonMain = 'Eliminar',
    modalRef.componentInstance.url = PACKAGE_CODES_URL
    modalRef.componentInstance.id = id,
    modalRef.componentInstance.code = code

  }

}
