<div class="mt-3">
  <div class="d-flex justify-content-center w-100" *ngIf="!loading">
    <div class="spinner-border text-danger" role="status">
    </div>
  </div>
  <div class="card bg-white border-0 p-3" *ngIf="loading">
      <table class="table" >
        <tr class="d-flex">
          <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
        </tr>
        <tr class="row" *ngFor="let Table of products">
          <app-row-item [title]="Table.appProducts.code" class="bg-white col-1 border border-light" ></app-row-item>
          <app-row-item [title]="Table.appProducts.appProductCategories.name" class="bg-white col border border-light" ></app-row-item>
          <app-row-item [title]="Table.appProducts.name" [src]="'/assets/image.png'" class="bg-white col border border-light" ></app-row-item>
          <app-row-item [title]="Table.appProducts.id" class="bg-white col-1 border border-light" ></app-row-item>
          <app-row-item [title]="Table.appProducts.appProviders.name" class="bg-white col-1 border border-light" ></app-row-item>
          <app-row-item [title]="Table.quantity" class="bg-white col-1 border border-light" ></app-row-item>
          <td class="d-flex align-items-center border border-light col">
            <div>
              <button class="btn button-blue btn-sm ps-3 pe-3" type="button" (click)="openModalEdit(Table, Table.id)">Editar</button>
            </div>
            <div>
              <button class="btn button-red-strong btn-sm ps-3 pe-3" (click)="openAlert(Table.id)">Eliminar</button>
            </div>
          </td>
        </tr>
      </table>
      <div class="mt-3">
        <ngb-pagination [collectionSize]="20" aria-label="Default pagination"></ngb-pagination>
    </div> 
  </div>   
  
</div>
