import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { selectFetch } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { PACKAGE_CODES_FILTER_URL, PACKAGE_CODES_LENGTH_URL } from '../../constants/ApiConstants';
import { codes } from '../../constants/CodesConstants';
import { CodeEntity } from '../../entities/CodesEntity';
import { ProductPackageCodesEmpty, ProductPackageCodesEntity } from '../../entities/ProductPackageCodesEntity';
import { loadCodes, updateCodesListParams, updateProductPackageCodesListParams } from '../../state/codes.actions';
import { selectCodesListParams, selectProductPackageCodesListParams } from '../../state/codes.selectors';
import { codesListParamsEmpty, codesListParamsEntity } from '../../entities/CodesListParamsEntity';

@Component({
  selector: 'app-codes-template-filter',
  templateUrl: './codes-template-filter.component.html',
  styleUrls: ['./codes-template-filter.component.scss']
})
export class CodesTemplateFilterComponent implements OnInit {

  @Input() title:string='';
  cod :any
  listCod :any
  loading:boolean = false
  public codes = codes
  public termConstants = termConstants
  listParams: codesListParamsEntity = codesListParamsEmpty
  constructor(
    private apiService : ApiService,
    private store:Store<AppState>
    ) {
      this.store.select(selectFetch).subscribe(fetch => {
        if(fetch){
          this.loading = false
        }
      })
     }

  ngOnInit(): void {
    this.store.dispatch(updateCodesListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: "",
        assigned:true
      }
    }))
  }
  updateList(keyword: string){
    if(this.title==='Codigos por asignar'){
      this.store.dispatch(updateCodesListParams({
        ...this.listParams,
        filters: {
          ...this.listParams.filters,
          keyword: keyword,
          assigned:false
        }
      }))
    }else{
      this.store.dispatch(updateCodesListParams({
        ...this.listParams,
        filters: {
          ...this.listParams.filters,
          keyword: keyword,
          assigned:true
        }
      }))
    }
    
  }
  updateCodes(){
    this.loading = true
    this.store.dispatch(fetchList())
  }

}
