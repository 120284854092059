<div class="card p-3 mb-3" >
    <div class="d-flex  align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <img src="./assets/code.png">
            <div class="code-section ps-3 pe-3" *ngIf="!showButtons">
                <app-title [title]="codes.codesCard.code" [colorText]="'primary'"></app-title>
                <app-title [title]="item.code" [class]="'fw-bolder'"></app-title>
            </div>
            <div class="ps-3 pe-3" *ngIf="showButtons">
                <app-title [title]="codes.codesCard.code" [colorText]="'primary'"></app-title>
                <app-title [title]="item.code" [class]="'fw-bolder'"></app-title>
            </div>
            <div>
                <div class="d-flex border-start" *ngIf="item?.appProductPackages?.appProducts?.name!==null">
                    <div class="card-1 border rounded ms-3 mt-1" *ngIf="!showButtons">
                      <img src="{{item?.appProductPackages?.appProducts?.image}}">
                    </div>
                    <div class="card-2 border-end pe-3 ms-3">
                        <app-title [title]="codes.codesCard.product" [colorText]="'primary'"></app-title>
                        <app-title [title]="item?.appProductPackages?.appProducts?.name" [fs]="'5 fw-bolder'"></app-title>
                    </div>
                    <div class="text-center border-end ps-3 pe-3" *ngIf="!showButtons">
                      <img src="{{codes.codesCard.imgBox}}">
                      <app-title [title]="codes.codesCard.box" [colorText]="'primary'"></app-title>
                    </div>
                </div>
            </div>
        </div>       
        <div *ngIf="!showButtons">
            <button class="btn button-red btn-sm ms-3" type="button"  (click)="asignPackage(item.id, item)" *ngIf="item?.appProductPackages?.appProducts?.name===null">{{codes.codesCard.asign}}</button>
            <button class="btn button-red btn-sm ms-3" type="button"  (click)="asignPackage(item.id, item)" *ngIf="item?.appProductPackages?.appProducts?.name!==null">Editar paquete</button>
            <button class="btn button-blue btn-sm ms-3" type="button"  (click)="openAlert(item.id, item.code)">{{codes.codesCard.delete}}</button>
        </div>
    </div>
</div>