export interface FiltersEntity{
    keyword: string
  }

export const FILTER_EMPTY = {
  keyword: ''
}
   

export interface idEntity {
  word: number
}

  