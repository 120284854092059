import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination-select',
  templateUrl: './pagination-select.component.html',
  styleUrls: ['./pagination-select.component.scss']
})
export class PaginationSelectComponent implements OnInit {

  @Input() collectionSize:number = 0;
  @Input() page:number = 0;
  @Input() pageSize:number = 0;
  constructor() { }

  ngOnInit(): void {
  }
  refreshCountries() {
    
  }

}
