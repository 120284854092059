import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { API_CONST, TITLE_CONST } from '../domain/constants/ApiConstants';
import { MODAL_CONST } from '../domain/constants/ModalConstants';
import { HEAD_CONST } from '../domain/constants/TableCommon';

@Component({
  selector: 'app-feature-document-types',
  templateUrl: './feature-document-types.component.html',
  styleUrls: ['./feature-document-types.component.scss']
})
export class FeatureDocumentTypesComponent implements OnInit {

  const = API_CONST
  modalConst = MODAL_CONST
  head = HEAD_CONST
  des= TITLE_CONST

  constructor(
    public modalService:NgbModal
  ) {
    
  }

  ngOnInit(): void {

  }
}

