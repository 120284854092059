<app-title [title]="'Despachos'"  [fs]="'3 fw-bolder mb-3'"></app-title> 
<div class="d-flex mt-3">
    <div class="me-3">
        <button class="btn button-red-strong btn-sm d-flex align-items-center ps-4 pe-4" (click)="openModalNewOrder()">{{offices.filter.newOrder}}</button>
    </div>
    <div class="me-2">
        <button class="btn button-red btn-sm" type="button" (click)="openModalIncome()">{{offices.filter.newList}}</button>
    </div>
    <div class="ps-2">
      <div class="d-flex">
        <app-input-search (search)="updateList($event)"></app-input-search>
        <app-dropdown
          class="ms-2"
          [list]="listCust"
          [nameDefault]="'Clientes'"
          (searchKeyWord)="filterKeyWordCli($event)"
          (searchIdItem)="filterIdCli($event)">
        </app-dropdown>
        <app-dropdown
          class="ms-2"
          [list]="listCat"
          [nameDefault]="'Categorias'"
          (searchKeyWord)="filterKeyWordCat($event)"
          (searchIdItem)="filterIdCat($event)">
        </app-dropdown>
      </div>         
    </div>
</div> 