<div class="p-3">
    <app-title [title]="'Selecciona la familia a inventariar'"></app-title>
    <div class="d-flex align-items-center">
        <div class="d-flex col-10">
            <div class="me-3">
              <button class="btn button-red-strong btn-sm"(click)="openModalAlert()">{{optimize.filter.start}}</button>
            </div>
            <div class="me-3">
                <app-input-button></app-input-button>
            </div>
        </div>
        <div class="col">
            <div class="d-flex justify-content-between">
              <div class="col-2">
                <img src="./assets/icon1-blue.png" class="w-75">
              </div>
              <div class="col-2">
                <img src="./assets/icon2-blue.png" class="w-75">
              </div>            
            </div>
            <div class="col-10">
                <app-progress-bar [value]="0"></app-progress-bar>
            </div>
        </div>
    </div>
    <div class="d-flex mt-3">
        <div class="card p-3 me-3" *ngFor="let item of families ; index as i" (click)="sendProduct(families, i)">
            <div class="text-center">
                <div class="d-flex justify-content-center">
                    <div class="card-1 d-flex align-items-center justify-content-center rounded-circle bg-danger">
                        <img src="/assets/Grupo 98.png" width="50">
                    </div>
                </div>
                
                <div>
                    <app-title [title]="item.name"></app-title>
                </div>
               
            </div>
           
        </div>
    </div>
    
</div>