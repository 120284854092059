<div class="d-flex mt-3 ms-3">
  <button class="rounded shadow d-flex align-items-center justify-content-center me-3" (click)="filterA()">
    <app-title [title]="stock.listSlots.a" [class]="'fw-bolder'" [fs]="'5'"></app-title>
  </button>
  <button class="rounded shadow d-flex align-items-center justify-content-center me-3" (click)="filterB()">
    <app-title [title]="stock.listSlots.b" [class]="'fw-bolder'" [fs]="'5'"></app-title>
  </button>
  <button class="rounded shadow d-flex align-items-center justify-content-center me-3" (click)="filterC()">
    <app-title [title]="stock.listSlots.c" [class]="'fw-bolder'" [fs]="'5'"></app-title>
  </button>
  <button class="rounded shadow d-flex align-items-center justify-content-center" (click)="filterD()">
    <app-title [title]="stock.listSlots.d" [class]="'fw-bolder'" [fs]="'5'"></app-title>
  </button>
</div>
<div class="d-flex mt-3">
  <app-slot-card-accordeon></app-slot-card-accordeon>
</div>
