<!DOCTYPE html>
<html>
  <head>
    <title>Drawing Tools</title>
    <script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
    
    <link rel="stylesheet" type="text/css" href="./map-marker.component.scss" />
    <script type="module" src="./index.js"></script>
  </head>
  <body>
    <div id="map" (click)="sendLatLng()"></div>
    
  </body>
</html>
