import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalService } from 'src/app/modules/app-common/domain/services/local.service';
import { loginApi } from '../constants/ApiConstants';
import { LoginResponse } from '../entities/LoginResponse';
import { LoginUser } from '../entities/LoginUser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  @Output() errorUpdate = new EventEmitter()
  constructor(
    public httpClient: HttpClient,
    public router: Router,
    public localService : LocalService,
    public store: Store,
  ) { }

  loginUser(credentials: LoginUser) {
    return this.httpClient.post(loginApi, credentials)
    
  }

  getUser(){
    return this.localService.getItem('Usuario')
  }

}

