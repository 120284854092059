import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_FILTER_URL, termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { OPTIMIZE_LEVELS_FILTER_URL, OPTIMIZE_LEVELS_URL } from '../../constants/OptimizeConstants';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { selectCloseModals } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { closeModalsDone, fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';

@Component({
  selector: 'app-optimize-template-modal-select',
  templateUrl: './optimize-template-modal-select.component.html',
  styleUrls: ['./optimize-template-modal-select.component.scss']
})
export class OptimizeTemplateModalSelectComponent implements OnInit {

  public termConstants = termConstants
  @Input() slotsOptimize:any
  users:any
  listOperator:any [] = []
  constructor( 
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService:ApiService,
    public store: Store<AppState>
    ) { 
      this.store.select(selectCloseModals).subscribe(close => {
        if(close){
          this.activeModal.close()
          this.store.dispatch(fetchList())
          this.store.dispatch(closeModalsDone())
        }
      })
    }

  ngOnInit(): void {
    this.apiService.post(USER_FILTER_URL, {}).subscribe((response:any) =>{
      let key = response.filter((el:any)=>el.userProfiles.name === 'Operario')
      this.users = key
    })
  }
  filterOperators(item:any){

  }
  addOperator(id:number){
    let bus = this.listOperator.indexOf(id)
    if (bus === -1){
      this.listOperator.push(id)
    }else{
      this.listOperator.splice(bus, 1)
    }
  }
  confirmOptimize(){
    const asignOrder = {'users':this.listOperator}
    const modalRef = this.modalService.open(ModalAlertComponent, {
      size: 'sm'
    });
    modalRef.componentInstance.content = 'Se va a iniciar la optimización ¿Desea continuar?', 
    modalRef.componentInstance.item = {
      app_slots_origin_id:[this.slotsOptimize[0].appSlots.id],
      app_products_id:[this.slotsOptimize[0].appProducts.id],
      app_slots_destination_id: [this.slotsOptimize[1].slot_id],
      quantity:this.slotsOptimize[0].amount,
      users: asignOrder.users
    } 
    modalRef.componentInstance.buttonMain = 'Continuar'
    modalRef.componentInstance.url = OPTIMIZE_LEVELS_URL
  }

}
