import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-login',
  templateUrl: './feature-login.component.html',
  styleUrls: ['./feature-login.component.scss']
})
export class FeatureLoginComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  

  }
    
}
