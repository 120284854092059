import { Component, OnInit } from '@angular/core';
import { productEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { OPTIMIZE_LEVELS_FILTER_URL, optimize } from '../../constants/OptimizeConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { INCOMES_URL } from 'src/app/modules/income/domain/constants/IncomeConstants';

@Component({
  selector: 'app-optimize-template-level-list',
  templateUrl: './optimize-template-level-list.component.html',
  styleUrls: ['./optimize-template-level-list.component.scss']
})
export class OptimizeTemplateLevelListComponent implements OnInit {

  public optimize = optimize
  dateOptimizes:any

  dateProducts:productEntity[]=[]
  constructor(
    public apiService:ApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.post(OPTIMIZE_LEVELS_FILTER_URL,{}).subscribe((response:any)=>{
     this.dateOptimizes = response 
    })
  }

}
