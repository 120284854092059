<div class="card bg-white shadow p-5">
  <div class="text-center">
    <img src="{{terms.login.image}}" class="w-75">
  </div>
  <div class="text-center mt-4">
    <app-title [title]="terms.forgotPassword.title" [colorText]="'secondary'"></app-title>
    <app-subtitle [title]="terms.forgotPassword.subtitle" [colorText]="'secondary'"></app-subtitle>
  </div>
  <div class="text-start mt-5">
    <app-form-input-email [title]="terms.forgotPassword.userLabel" [colorText]="'secondary'"></app-form-input-email>          
  </div>
  <div class="mt-2">
    <app-link [title]="terms.forgotPassword.login" [colorLink]="'secondary'" [url]="terms.forgotPassword.url"></app-link>
  </div>
    <div class="text-end">
      <app-link-button [title]="terms.forgotPassword.send" [url]="terms.forgotPassword.sendUrl"></app-link-button>
    </div>
</div>
