import { Component, OnInit } from '@angular/core';
import { optimize } from '../../constants/OptimizeConstants';

@Component({
  selector: 'app-optimize-template-transfered-filter',
  templateUrl: './optimize-template-transfered-filter.component.html',
  styleUrls: ['./optimize-template-transfered-filter.component.scss']
})
export class OptimizeTemplateTransferedFilterComponent implements OnInit {

  public optimize = optimize
  constructor() { }

  ngOnInit(): void {
  }

}
