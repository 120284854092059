<div class="card p-3">
  <div class="d-flex justify-content-between">
    <app-title [title]="'Selecciona los operarios'" [class]="'fw-bolder mb-2'" ></app-title>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="mb-3">
    <app-input-search (search)="filterOperators($event)"></app-input-search>
  </div>
<div class="d-flex align-items-center mt-1" *ngFor="let item of users">
  <div class="form-check m-0" (change)="addOperator(item.id)">
    <input class="form-check-input" type="checkbox" value="">     
  </div>
  <img class="border border-2 border-danger rounded-circle p-1 ms-2 me-2" src="{{item.imageUrl}}" width="40" height="40">
  <app-title [title]="item.name"></app-title> 
</div>
  <div class="d-flex justify-content-center mt-3">
    <button class="btn button-blue btn-sm me-3" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
    <button class="btn button-red-strong btn-sm" (click)="confirmOptimize()">{{termConstants.modal.continue}}</button>
</div>  