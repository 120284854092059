<app-title [title]="'Ingresos'"  [fs]="'3 fw-bolder mb-3'"></app-title> 
<div>
  <div class="d-flex">
    <div class="d-inline-block border-2 border-end pe-2">
      <button class="btn button-red-strong me-2 btn-sm" (click)="openModalNewOrder()">{{ income.filter.newOrder }}</button>
      <button class="btn button-red-strong btn-sm" (click)="openModalAsign()">{{ income.filter.asign }}</button>
    </div>
    <div class="ps-2">
      <div class="d-flex">
        <app-input-search (search)="updateList($event)"></app-input-search>
        <app-dropdown
          class="ms-2"
          [list]="listProv"
          [nameDefault]="'Proveedores'"
          (searchKeyWord)="filterKeyWordProv($event)"
          (searchIdItem)="filterIdProv($event)">
        </app-dropdown>
      </div>         
    </div>
  </div>
</div>