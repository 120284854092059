import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IncomeTemplateModalProductsComponent } from 'src/app/modules/income/domain/templates/income-template-modal-products/income-template-modal-products.component';
import { ICONS_CONSTANTS } from '../../constants/IconsConstants';
import { ModalAlertComponent } from '../../organisms/modal-alert/modal-alert.component';
import { ModalEditProviderComponent } from '../../templates/modal-edit-provider/modal-edit-provider.component';

export interface Table {
  row1: string,
  row2: string,
  row3: string,
  row4: string,
  row5: string,
  row6: string,

}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() title:string = '';
  @Input() check:string = '';
  @Input() zone:string = '';
  @Input() buttonBlue:string = '';
  @Input() buttonRed:string = '';
  @Input() buttonProv:string = '';
  @Input() col:string = '';
  @Input() row1:string = '';
  @Input() col1:string = '';
  @Input() row2:string = '';
  @Input() col2:string = '';
  @Input() row3:string = '';
  @Input() col3:string = '';
  @Input() row4:string = '';
  @Input() col4:string = '';
  @Input() row5:string = '';
  @Input() col5:string = '';
  @Input() row6:string = '';
  @Input() col6:string = '';
  @Input() tableHead:any = []
  @Input() tableBody:any = []

  page = 1;
  pageSize = 10;
  collectionSize = 0

  
  icons = ICONS_CONSTANTS
  constructor(
    public modalService:NgbModal
  ) {}

  ngOnInit(): void {
  }
  openModalProduct(){
    const modalRef = this.modalService.open(IncomeTemplateModalProductsComponent,{
      size: 'xl'
    })
  }
  openModalEdit(){
    const modalRef = this.modalService.open(ModalEditProviderComponent,{
      size: ''
    })
  }
  openAlert(){
    const modalRef = this.modalService.open(ModalAlertComponent,{
      size: 'sm'
    });modalRef.componentInstance.content = '¿Desea eliminar la información?',
    modalRef.componentInstance.buttonMain = 'Eliminar'
  }

}