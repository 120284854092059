import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SLOTS_FILTER_URL, STOCK_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { updateIncomeListParams } from 'src/app/modules/income/domain/state/income.actions';
import { stock } from '../../constants/StockConstants';
import { stockListParamsEmpty, stockListParamsEntity } from '../../entities/StockListParamsEntity';
import { updateStockListParams } from '../../state/stock.actions';

@Component({
  selector: 'app-stock-template-list-slots',
  templateUrl: './stock-template-list-slots.component.html',
  styleUrls: ['./stock-template-list-slots.component.scss']
})
export class StockTemplateListSlotsComponent implements OnInit {

  public stock = stock
  dateSlotsA:any
  dateSlotsB:any
  dateSlotsC:any
  dateSlotsD:any
  listParams: stockListParamsEntity= stockListParamsEmpty
  constructor(
    private store:Store<AppState>
  ) { }

  ngOnInit(): void {
  }
  filterA(){
    this.store.dispatch(updateStockListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: 'A'
      }
    }))
  }
  filterB(){
    this.store.dispatch(updateStockListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: 'B'
      }
    }))
  }
  filterC(){
    this.store.dispatch(updateStockListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: 'C'
      }
    }))
  }
  filterD(){
    this.store.dispatch(updateStockListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: 'D'
      }
    }))
  }

}
