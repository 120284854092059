import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-icon-text',
  templateUrl: './button-icon-text.component.html',
  styleUrls: ['./button-icon-text.component.scss']
})
export class ButtonIconTextComponent implements OnInit {

  @Input() title:string = '';
  @Input() icon:string = '';
  @Input() class:string = '';
  @Input() colorType:string = '';
  @Input() position:string = '';
  @Input() colorText:string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
