export const products = {
    filter: {
        newProduct: 'Crear producto',
        category: 'Categoria',
        cellar: 'Bodega'
    }
}
export const typesPackages = [
  {
    title: 'Individual',
    image:'./assets/Individual_empaque.png',
    active:false
  },
  {
    title: 'Sachets',
    image:'./assets/Sachet_empaque.png',
    active:false
  },
  {
    title: 'Caja',
    image:'./assets/Caja_empaque.png',
    active:false
  },
  {
    title: 'Pallet',
    image:'./assets/Palet_empaque.png',
    active:false
  }
]