import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { INCOME_FILTER_URL, PROVIDER_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { providerEntity } from 'src/app/modules/app-common/domain/entities/ProviderEntity';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { income } from '../../constants/IncomeConstants';
import { incomeEntity } from '../../entities/IncomeEntities';
import { incomeListParamsEmpty, incomeListParamsEntity } from '../../entities/IncomeListParamsEntity';
import { updateIncomeListParams } from '../../state/income.actions';
import { selectIncomeListParams } from '../../state/income.selectors';
import { IncomeTemplateModalAsignComponent } from '../income-template-modal-asign/income-template-modal-asign.component';
import { IncomeTemplateModalNewOrderComponent } from '../income-template-modal-new-order/income-template-modal-new-order.component';

@Component({
  selector: 'app-income-template-filter',
  templateUrl: './income-template-filter.component.html',
  styleUrls: ['./income-template-filter.component.scss']
})
export class IncomeTemplateFilterComponent implements OnInit {

  ord:any
  item:any
  listOrders:any
  listProv:any
  listCat:any
  prov:any
  nameDefault:any
  cat:any
  public income = income

  listParams: incomeListParamsEntity= incomeListParamsEmpty

  constructor(
    public modalService:NgbModal,
    private apiService: ApiService,
    private store:Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.select(selectIncomeListParams).subscribe(params => this.listParams = params)
    this.apiService.post(PROVIDER_FILTER_URL,{}).subscribe(providers => this.listProv = <providerEntity[]>providers)
  }
  
  filterKeyWordProv(nameProv: any){
    console.log(nameProv)
    this.store.dispatch(updateIncomeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: nameProv
      }
    }))
  }
  filterIdProv(id:number){
    this.store.dispatch(updateIncomeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        app_providers_id: id
      }
    }))
  }
  openModalAsign(){
    const modalRef = this.modalService.open(IncomeTemplateModalAsignComponent,{
      size: 'lg'
    })

  }
  openModalNewOrder(){
    const modalRef = this.modalService.open(IncomeTemplateModalNewOrderComponent,{
      size: 'xxl'
    })
  }

  updateList(keyword: string){

    this.store.dispatch(updateIncomeListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: keyword
      }
    }))
  }
}

