import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-input-text-icon',
  templateUrl: './text-input-text-icon.component.html',
  styleUrls: ['./text-input-text-icon.component.scss']
})
export class TextInputTextIconComponent implements OnInit {

  @Input() title:string = '';
  @Input() placeholder:string = '';
  @Input() icon:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
