import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PRODUCT_CATEGORIES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { categoryEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { PRODUCT_URL, PROVIDER_FILTER_URL } from '../../constants/TermsConstants';
import { providerEntity } from '../../entities/ProviderEntity';
import { ApiService } from '../../services/api.service';
import { AppState } from '../../state.ts/app.state';
import { fetchList, rechargePage } from '../../state.ts/common.actions';

@Component({
  selector: 'app-modal-edit-product',
  templateUrl: './modal-edit-product.component.html',
  styleUrls: ['./modal-edit-product.component.scss']
})
export class ModalEditProductComponent implements OnInit {

  idForm!:FormGroup
  @Input() item?:any
  title:string= ''
  @Input() id:number = 0;
  loading$ = false
  providers:providerEntity[]=[]
  prov?:any
  categories:categoryEntity[]=[]
  cat?:any
  img:any
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService: ApiService,
    public store:Store<AppState>,
    public router: Router
  ) { 
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),
      image: new FormControl(''),
      code: new FormControl(''),
      app_product_categories_id:! new FormControl(''||null),
      app_providers_id:! new FormControl(''||null),
      
    })
    
  }

  ngOnInit(): void {
    this.apiService.post(PROVIDER_FILTER_URL).subscribe(providers => this.providers = <providerEntity[]>providers)
    this.apiService.post(PRODUCT_CATEGORIES_FILTER_URL).subscribe(categories => this.categories = <categoryEntity[]>categories)
    this.title=this.item ? 'Edición': 'Crear'
    if(this.item){
      this.idForm.setValue({
        name:this.item.name,
        image:this.item.image,
        code:this.item.code,
        app_product_categories_id:this.item.appProductCategories.id,
        app_providers_id:this.item.appProviders.id
      })
    }
  }
  sendProviders(){
    
    if(this.item){
      this.apiService.put(PRODUCT_URL+this.item.id, this.idForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(fetchList())
      })
      
    }else{
      this.apiService.post(PRODUCT_URL, this.idForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
        this.store.dispatch(fetchList())
      })
      
    }
    this.store.dispatch(rechargePage({loading:this.loading$}))

   
  }


}