import { Component, OnInit } from '@angular/core';
import { API_CONST, TITLE_CONST } from '../domain/constants/ApiConstants';
import { MODAL_CONST } from '../domain/constants/ModalConstants';
import { HEAD_CONST } from '../domain/constants/TableCommon';

@Component({
  selector: 'app-feature-zones-distribution',
  templateUrl: './feature-zones-distribution.component.html',
  styleUrls: ['./feature-zones-distribution.component.scss']
})
export class FeatureZonesDistributionComponent implements OnInit {

  const = API_CONST
  modalConst = MODAL_CONST
  head = HEAD_CONST
  des = TITLE_CONST
  
  constructor() { }

  ngOnInit(): void {
  }

}