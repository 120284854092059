import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { API_CONST, TITLE_CONST } from '../domain/constants/ApiConstants';
import { MODAL_CONST } from '../domain/constants/ModalConstants';
import { HEAD_CONST } from '../domain/constants/TableCommon';

import { AppState } from '../domain/state.ts/app.state';


@Component({
  selector: 'app-feature-products',
  templateUrl: './feature-products.component.html',
  styleUrls: ['./feature-products.component.scss']
})
export class FeatureProductsComponent implements OnInit {
  const = API_CONST
  modalConst = MODAL_CONST
  head = HEAD_CONST
  des= TITLE_CONST

  constructor(
    private store: Store<AppState>,
    public modalService:NgbModal
  ) {
    
  }

  ngOnInit(): void {

  }
}

