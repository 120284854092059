import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { KEYBOARD_CONSTANTS } from 'src/app/modules/app-common/domain/constants/KeyboardConstants';
import { termConstants } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { fetchList } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { PRODUCT_PACKAGES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { packageEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { PACKAGE_CODES_URL } from '../../constants/ApiConstants';
import { codes } from '../../constants/CodesConstants';
import { ProductPackageCodesEmpty, ProductPackageCodesEntity } from '../../entities/ProductPackageCodesEntity';
import { updateProductPackageCodesListParams } from '../../state/codes.actions';
import { selectProductPackageCodesListParams } from '../../state/codes.selectors';

@Component({
  selector: 'app-codes-template-modal',
  templateUrl: './codes-template-modal.component.html',
  styleUrls: ['./codes-template-modal.component.scss']
})
export class CodesTemplateModalComponent implements OnInit {

  name:any
  id_product:any
  public termConstants = termConstants
  public codes = codes
  dateCodes:any
  filters = {
    keyword: ''
  }
  @Input() packages: packageEntity[]=[]
  listParams: ProductPackageCodesEntity = ProductPackageCodesEmpty
  @Input() id:number = 0
  @Input() item:any
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private store:Store<AppState>,
    private apiService : ApiService,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(updateProductPackageCodesListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: ''
      }
    }))
  }

  openAlert(){
    const modalRef = this.modalService.open(ModalAlertComponent,{
      size: 'sm'
    });modalRef.componentInstance.content = '¿Desea guardar la información?'
    modalRef.componentInstance.buttonMain = 'Guardar'
  }

  updateList(keyword: string){
    this.store.dispatch(updateProductPackageCodesListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: keyword
      }
    }))
  }
  selectProduct(id:any){
    this.id_product = id
  }
  sendAddProduct(){
    this.apiService.put(PACKAGE_CODES_URL + this.id,{
      'app_product_packages_id': this.id_product
    }).subscribe((ress)=>{})
    this.activeModal.dismiss('Cross click')
    this.store.dispatch(fetchList())
  }
}
