import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-checkbox-labeled',
  templateUrl: './form-checkbox-labeled.component.html',
  styleUrls: ['./form-checkbox-labeled.component.scss']
})
export class FormCheckboxLabeledComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
