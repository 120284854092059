import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/domain/guards/auth.guard';
import { FeatureCodesAsignComponent } from './feature-codes-asign/feature-codes-asign.component';
import { FeatureCodesComponent } from './feature-codes/feature-codes.component';

const routes: Routes = [
  {path: 'codigos-asignados', component: FeatureCodesComponent, canActivate: [AuthGuard]},
  {path: 'codigos-por-asignar', component: FeatureCodesAsignComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CodesRoutingModule { }
