<app-title [title]="'Productos'"  [fs]="'3 fw-bolder mb-3'"></app-title> 
<div class="d-flex">
    <div class="me-3">
        <button class="btn button-red btn-sm" type="button" (click)="openModalNewProduct()">Crear Producto</button>
    </div>
    <div class="me-3">
      <app-input-search (search)="updateList($event)"></app-input-search>
    </div>
     
      
</div>
