<form class="row row-cols-sm-auto">
	<div class="col-12">
		<div class="input-group input-group-sm">
			<input
				class="form-control rounded-start border-end-0"
				name="dp"
				ngbDatepicker
				#d="ngbDatepicker"
			/>
			<button class="btn button-blue rounded bi bi-calendar3" (click)="d.toggle()" type="button"></button>
		</div>
	</div>
</form>