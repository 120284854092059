import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { fetchProviders } from 'src/app/modules/products/domain/state/products.actions';
import { PROVIDER_URL } from '../../constants/TermsConstants';
import { ApiService } from '../../services/api.service';
import { AppState } from '../../state.ts/app.state';
import { fetchList } from '../../state.ts/common.actions';

@Component({
  selector: 'app-modal-edit-provider',
  templateUrl: './modal-edit-provider.component.html',
  styleUrls: ['./modal-edit-provider.component.scss']
})
export class ModalEditProviderComponent implements OnInit {

  idForm!:FormGroup
  @Input() item?:any
  title:string= ''
  @Input() id:number = 0;
  loading$ = false
  constructor(
    public formBuilder:FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public apiService: ApiService,
    public store:Store<AppState>,
    public router: Router
  ) { 
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),
      logo: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.title=this.item ? 'Edición': 'Crear'
    if(this.item){
      this.idForm.setValue({
        name:this.item.name,
        logo:this.item.logo,
      })
    }
  }
  sendProviders(){
    if(this.item){
      this.apiService.put(PROVIDER_URL + this.item.id, this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
    }else{
      this.apiService.post(PROVIDER_URL, this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(fetchList())
        this.activeModal.dismiss('Cross click')
      })
    }

   
  }

}
