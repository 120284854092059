<div>
    <div class="col-12">
      <div class="d-flex justify-content-center" *ngIf="!loading">
        <div class="spinner-border text-danger" role="status">
        </div>
      </div>
      <div  *ngIf="loading">
        <div class="card-over-red">
          <ngb-accordion  [closeOthers]="true" >
            <ngb-panel id="{{item.id}}" *ngFor="let item of data;index as i">
              <ng-template  ngbPanelHeader let-opened="opened">
                <div class="rounded-0 w-100 custom-header justify-content-between m-0 p-0 ">
                  <div class="d-flex align-items-center">
                    <button class="btn bg-white d-flex align-items-center border rounded-0 m-0 p-0 w-100 ms-3" ngbPanelToggle (click)="rotated(i)">
                      <img src="./assets/filter.png" class="ms-2" *ngIf="!opened">
                      <img src="./assets/filter.png" class="rotate ms-2" *ngIf="opened">
                      <div class="card border-0 rounded-0 p-2 w-100">
                        <div class="d-flex align-items-center">
                          <div class="col-7">
                            <div class="border-bottom text-start ms-4 ps-3 col-9">
                              <app-title [title]="item.packages[0]?.appProductPackages?.appProducts?.appProviders?.name" [class]="'fw-bolder'" [colorText]="'secondary'"></app-title>
                            </div>  
                            <div class="d-flex ">
                              <div class="card-1 border-end text-center">
                                <app-title [title]="income.cardProgress.order" [colorText]="'secondary'"></app-title>
                                <app-title [title]="item.id" [colorText]="'color-3 fw-bolder'"></app-title>
                              </div>
                              <div class="card-1 border-end text-center">
                                <app-title [title]="income.cardProgress.numberProducts" [colorText]="'secondary'"></app-title>
                                <app-title [title]="item.packages.length" [colorText]="'color-3 fw-bolder'"></app-title>
                              </div>
                              <!-- <div class="card-1 d-flex align-items-center justify-content-center">
                                <button class="btn button-red btn-sm" type="button" *ngIf="item.team.length=== 0" (click)="openModalIncome(item.id)">{{income.cardProgress.asign}}</button>
                              </div> -->
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="d-flex">
                              <div class="col">
                                <button type="button" class="btn border-0" ngbPopover="{{item.team[0]?.user?.name}}, {{item.team[1]?.user?.name}}, {{item.team[2]?.user?.name}} 3:30pm" triggers="mouseenter:mouseleave" popoverTitle="Asignado a" popoverClass="my-custom-class" *ngIf="item.team.length!== 0">
                                  <img src="./assets/icon1-green.png" class="w-25">
                                </button>            
                                <button type="button" class="btn border-0" ngbPopover="" triggers="mouseenter:mouseleave" popoverTitle="Sin Asignar" *ngIf="item.team.length=== 0">
                                  <img src="./assets/icon-alert.png" class="w-25">
                                </button>
                              </div>
                              <div class="col">
                                <button type="button" class="btn border-0" ngbPopover="{{item.team[0]?.user?.name}} 3:30pm" triggers="mouseenter:mouseleave" popoverTitle="En distribucion" *ngIf="item.appIncomeStatus.id===2">
                                  <img src="./assets/icon3-green.png" class="w-25">
                                </button>
                                <button class="btn border-0" *ngIf="item.appIncomeStatus.id<2">
                                  <img src="./assets/icon3-blue.png" class="w-25">
                                </button>
                              </div>
                              <div class="col">
                                <button type="button" class="btn border-0" ngbPopover="{{item.team[0]?.user?.name}} 3:30pm" triggers="mouseenter:mouseleave" popoverTitle="Distribuido en bodega" *ngIf="item.appIncomeStatus.id === 3">
                                  <img src="./assets/icon6-green.png" class="w-25">
                                </button>
                                <button class="btn border-0" *ngIf="item.appIncomeStatus.id<3">
                                  <img src="./assets/icon6-blue.png" class="w-25">
                                </button>
                              </div>
                            </div>
                            <div class="ps-5 pe-5 me-2">
                              <app-progress-bar [colorBar]="'success'" [value]="item.appIncomeStatus.id"></app-progress-bar>
                            </div>
                          </div>
                          <div class="col"></div>
                        </div>
                    </div>
                    </button> 
                  </div>                
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="p-3">
                  <table class="table" >
                    <tr class="d-flex">
                      <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
                    </tr>
                    <div>
                      <tr class="head d-flex" *ngFor="let Table of packages">
                              
                        <app-row-item [title]="Table.appProductPackages.code" class="bg-white col-2 border border-light" ></app-row-item>
                        <app-row-item [title]="Table.appProductPackages.appProducts.name" class="bg-white col border border-light" ></app-row-item>
                        <app-row-item [title]="Table.appProductPackages.name"  class="bg-white col border border-light" ></app-row-item>
                        <app-row-item [title]="Table.amount"  class="bg-white col-2 border border-light" ></app-row-item>
                        <app-row-item [title]="Table.appProductPackages.quantity*Table.amount"  class="bg-white col-1 border border-light" ></app-row-item>
                      </tr>
                    </div> 
                  </table>
                </div>         
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

        </div>
       
        <div class="pagination mt-3">
          <ngb-pagination
            [collectionSize]="collectionSize"
            [(page)]="listParams.page"
            [pageSize]="listParams.size"
            (pageChange)="updateListParams()">
          </ngb-pagination>
        </div>  
      </div>
    </div>
</div>
  

  
  