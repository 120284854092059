import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { DOCUMENT_URL, PROFILE_URL, termConstants, USER_URL, WAREHOUSES_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { rechargePage } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { users } from '../../constants/UsersConstants';
import { profileEntity } from '../../entities/UsersEntities';
import { updateUsersList, updateUsersListParams } from '../../state/users.actions';

@Component({
  selector: 'app-users-template-modal-newuser',
  templateUrl: './users-template-modal-newuser.component.html',
  styleUrls: ['./users-template-modal-newuser.component.scss']
})
export class UsersTemplateModalNewuserComponent implements OnInit {

  @Input() user:any
  idForm!:FormGroup
  title:any
  listProf:any
  listBod:any
  listType:any
  item:any
  image:any
  imageObj: any
  model:any
  loading$ = false
  @Input() id:any
  public users = users
  public termConstants = termConstants
  icons = ICONS_CONSTANTS
  listWarehouses:any [] = []
  constructor(
    public activeModal: NgbActiveModal,
    public modalService:NgbModal,
    public formBuilder:FormBuilder,
    private apiService:ApiService,
    private store: Store<AppState>,
    private sanitizer:DomSanitizer
  ) {
    this.idForm = this.formBuilder.group({
      name: new FormControl(''),
      image: new FormControl(''),
      email: new FormControl(''),
      password: new FormControl(''),
      document_number:new FormControl(''),
      user_profiles_id: new FormControl(''),
      user_document_types_id:new FormControl('')
    })
   }

  ngOnInit(): void {
    
    this.apiService.post(PROFILE_URL+'filter',{}).subscribe((res:any)=>{
      this.listProf= <profileEntity[]>res
    })
    this.apiService.post(WAREHOUSES_URL+'filter',{}).subscribe((res:any)=>{
      this.listBod= <profileEntity[]>res
    })
    this.apiService.post(DOCUMENT_URL+'filter',{}).subscribe((res:any)=>{
      this.listType= <profileEntity[]>res
    })
    this.title=this.item ? 'Edición': 'Creación'
    if(this.item){
      this.image = this.item.imageUrl
      this.idForm.setValue({
        name: this.item.name || null,
        image: this.item.imageUrl || null,
        email: this.item.email || null,
        password: this.item?.password || null,
        user_profiles_id: this.item?.userProfiles?.id || null,
        document_number: this.item?.documentNumber || null,
        user_document_types_id: this.item?.userDocumentTypes?.id || null
      })
    }else{
      this.image = './assets/image-default.png'
    }
  }
  sendProfile($event:any){    
  }
  sendBodega($event:number){
   
  }
  createUser(){
    if(this.item){
      this.apiService.put(USER_URL + this.item.id, this.idForm.value).subscribe((ress)=>{
        this.activeModal.dismiss('Cross click')
        
        var formData = new FormData();
        if(this.imageObj){
          formData.append('image', this.imageObj.files[0])
          this.apiService.post(USER_URL + this.item.id, formData).subscribe(ress => {})
          this.store.dispatch(updateUsersList())
        }else{
          this.store.dispatch(updateUsersList())
        }
      })
    }else{
      this.apiService.post(USER_URL+'register', this.idForm.value).subscribe((ress)=>{
        this.store.dispatch(updateUsersList())
        this.activeModal.dismiss('Cross click')
      })
    }
  }
  addListWarehouses(id:number){
    let bus = this.listWarehouses.indexOf(id)
    if (bus === -1){
      this.listWarehouses.push(id)
    }else{
      this.listWarehouses.splice(bus, 1)
    }
  }
  onFileSelect(event:any) {
    this.imageObj = event.target
    this.image = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.target.files[0]))
  }
}
