export const sections = [
  {
    img: './assets/Usuarios.png',
    title: 'Usuarios',
    bg:'',
    bg1:'',
    bg2:'',
    bg3:'',
    url: '/usuarios/admin',
    subtitle1: 'Usuarios',
    url1: '/usuarios/admin',
    subtitle2: 'Perfiles',
    url2: '/usuarios/perfiles',
    profile: 'Administrador'
   },
  {
    img: './assets/Ingresos.png',
    title: 'Ingresos',
    bg:'',
    bg1:'',
    bg2:'',
    bg3:'',
    url: '/ingresos',
    subtitle1: 'Ingreso de productos',
    url1: '/ingresos',
    profile: 'Administrador',
    profile2: 'Jefe de bodega',
   },
  {
    img: './assets/Salidas.png',
    title: 'Despachos',
    bg:'',
    bg1:'',
    bg2:'',
    bg3:'',
    url: '/despachos',
    subtitle1: 'Despacho de productos',
    url1: '/despachos',
    subtitle2: 'Listas de separación',
    url2: '/listas',
    profile: 'Administrador',
    profile2: 'Jefe de bodega',
  },
  {
    img: './assets/Códigos.png',
    title: 'Códigos',
    bg:'',
    bg1:'',
    bg2:'',
    bg3:'',
    url: '/codigos-por-asignar',
    subtitle1: 'Codigos por asignar',
    url1: '/codigos-por-asignar',
    subtitle2: 'Codigos asignados',
    url2: '/codigos-asignados',
    profile: 'Administrador',
    profile2: 'Jefe de bodega',
  },
  {
    img: './assets/Stock.png',
    title: 'Stock',
    bg:'',
    bg1:'',
    bg2:'',
    bg3:'',
    url: '/stock/administracion',
    subtitle1: 'Vista de slots',
    url1: '/stock/administracion',
    subtitle2: 'Listado de productos',
    url2: '/stock/lista',
    profile: 'Administrador',
    profile2: 'Jefe de bodega',
  },
  {
    img: './assets/Productos.png',
    title: 'Productos',
    bg:'',
    bg1:'',
    bg2:'',
    bg3:'',
    url: '/productos',
    subtitle1: 'Productos',
    url1: '/productos',
    profile: 'Administrador',
    profile2: 'Jefe de bodega',
  },
  {
    img: './assets/Optimizar.png',
    title: 'Optimizar',
    bg:'',
    bg1:'',
    bg2:'',
    bg3:'',
    url: '/optimizar/nivel',
    subtitle1: 'Nivel de llenado',
    url1: '/optimizar/nivel',
    subtitle2: 'Inventario cíclico',
    url2: '/optimizar/inventario',
    subtitle3: 'Traslados',
    url3: '/optimizar/traslado',
    profile: 'Administrador',
    profile2: 'Jefe de bodega',
   },
   {
    img: '',
    title: 'Administracion',
    icon: 'gear',
    bg:'',
    bg1:'',
    bg2:'',
    bg3:'',
    bg4:'',
    bg5:'',
    bg6:'',
    bg7:'',
    bg8:'',
    url: '/optimizar/proveedores',
    subtitle1: 'Proveedores',
    url1: '/optimizar/proveedores',
    subtitle2: 'Productos',
    url2: '/optimizar/productos',
    subtitle3: 'Paquetes',
    url3: '/optimizar/paquetes',
    subtitle4: 'Categorias',
    url4: '/optimizar/familias',
    subtitle5: 'Clientes',
    url5: '/optimizar/clientes',
    subtitle6: 'Sede de clientes',
    url6: '/optimizar/sedes',
    subtitle7: 'Bodegas',
    url7: '/optimizar/bodegas',
    subtitle8: 'Tipos de documentos',
    url8: '/optimizar/tipos',
    subtitle9: 'Zonas de alistamiento',
    url9: '/optimizar/zonas',
    subtitle10: 'Vehiculos',
    url10: '/optimizar/vehiculos',
    subtitle11: 'Zona de distribución',
    url11: '/optimizar/distribucion',
    profile: 'Administrador' 


   },
]
