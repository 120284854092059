export const VEHICLE_EMPTY = {
    id:0,
    vol: 0,
    select: 0,
    restant: 0,
    percentage: 0,
    dimensionDepth:0,
    dimensionHeight:0,
    dimensionWidth:0
  }

export const CLIENT_EMPTY = {
  id:'',
    image: '',
    name: '',
    code: 0,
  }

export const ORDER_EMPTY = {
    name: '',
    order: '',
    cant:'',
    zone: '',
    value:0
  }