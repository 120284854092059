import { Component, Input, OnInit } from '@angular/core';
import { stock } from '../../constants/StockConstants';
import { SLOT_PRODUCT_EMPTY } from '../../constants/StockEmpty';
import { slotProductEntity } from '../../entities/StockEntities';

@Component({
  selector: 'app-sub-slot-card',
  templateUrl: './sub-slot-card.component.html',
  styleUrls: ['./sub-slot-card.component.scss']
})
export class SubSlotCardComponent implements OnInit {

  public stock = stock
  @Input() dateProduct:slotProductEntity= SLOT_PRODUCT_EMPTY
  constructor() { }

  ngOnInit(): void {
  }

}
