<div class="mt-3 ms-3">
  <app-title [title]="'Selecciona la familia a inventariar'"></app-title>
  <div class="d-flex align-items-center">
        <div class="d-flex col-10">
            <div class="me-3">
              <button class="btn button-red-strong btn-sm" (click)="openModalAlert()">{{optimize.filter.start}}</button>
            </div>
            <div>
              <app-input-search (search)="filterPackage($event)"></app-input-search>
            </div>
        </div>
        <div class="col">
            <div class="d-flex justify-content-between">
              <div class="col-2">
                <img src="./assets/icon1-blue.png" class="w-75">
              </div>
              <div class="col-2">
                <img src="./assets/icon2-blue.png" class="w-75">
              </div>            
            </div>
            <div class="col-10">
                <app-progress-bar [value]="0"></app-progress-bar>
            </div>
        </div>
  </div>
  <div class="d-flex mt-3">
    <app-optimize-level-accordeon (sendItemSlot)="addIdSlot($event)"></app-optimize-level-accordeon>
  </div>
  
</div>
