import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {

  image:any
  idForm!:FormGroup
  @Output() selected = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }
  onFileSelect($event:any) {
   this.selected.emit($event)
  }

}
