<div class="d-flex">
        <div>
            <input class="rounded-start" type="text" [(ngModel)]="model" (ngModelChange)="updateModel($event)" (keyup)="checkKeyCode($event)">
        </div>
        <div>
            <button class="rounded-end ps-3 pe-3">
                <app-title [title]="termConstants.filter.search"></app-title>
            </button>
        </div>
    </div>

