import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import { stock } from '../../constants/StockConstants';
import { StockModalMaxTypesComponent } from '../stock-modal-max-types/stock-modal-max-types.component';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

@Component({
  selector: 'app-stock-template-modal-slot',
  templateUrl: './stock-template-modal-slot.component.html',
  styleUrls: ['./stock-template-modal-slot.component.scss']
})
export class StockTemplateModalSlotComponent implements OnInit {

  public stock = stock
  @Input() item?:any
  dateProducts:any
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    const ctx:any = document.getElementById('myChart');
    const myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: this.item.productLevels?.labels,
        datasets: [{
            data: this.item.productLevels?.data,
            backgroundColor: [
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }
});
    
  }
  openModalChangeMaxLimite(max:any,id:number){
    const modalRef = this.modalService.open(StockModalMaxTypesComponent,{
      size: 'sm'
    });
    modalRef.componentInstance.max = max
    modalRef.componentInstance.id = id
  }


}
