import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/modules/app-common/domain/state.ts/app.state";
import { StockState } from "./StockState";

 
export const selectStockFeature = (state:AppState) => state.stock;
 
export const selectloadStock = createSelector(
  selectStockFeature,
  (state: StockState) => state.dates
);
export const selectStockListParams = createSelector(
  selectStockFeature,
  (state: StockState) => state.listParams
)