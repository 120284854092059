import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-template-login',
  templateUrl: './auth-template-login.component.html',
  styleUrls: ['./auth-template-login.component.scss']
})
export class AuthTemplateLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
