import { createAction, props } from "@ngrx/store";
import { packageEntity } from "../entities/ProductsEntities";
import { productsListParamsEntity } from "../entities/ProductsListParamsEntity";

export const loadPackages = createAction('[load Packages] load packages',
props<{pack:packageEntity[]}>()
)
export const updateProductsListParams = createAction('[Products] Update list params', 
props<productsListParamsEntity>())

export const fetchProviders = createAction('[Providers] Fetch')