import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { PROVIDER_FILTER_URL, SLOTS_FILTER_URL, STOCK_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { providerEntity, PROVIDER_EMPTY } from 'src/app/modules/app-common/domain/entities/ProviderEntity';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { income } from 'src/app/modules/income/domain/constants/IncomeConstants';
import { stock } from 'src/app/modules/stock/domain/constants/StockConstants';
import { stockListParamsEmpty, stockListParamsEntity } from 'src/app/modules/stock/domain/entities/StockListParamsEntity';
import { updateStockListParams } from 'src/app/modules/stock/domain/state/stock.actions';
import { selectStockListParams } from 'src/app/modules/stock/domain/state/stock.selectors';

@Component({
  selector: 'app-optimize-level-accordeon',
  templateUrl: './optimize-level-accordeon.component.html',
  styleUrls: ['./optimize-level-accordeon.component.scss']
})
export class OptimizeLevelAccordeonComponent implements OnInit {

  public income = income
  public stock = stock
  @Input() dateSupplier:providerEntity[] = [PROVIDER_EMPTY]
  listSlot:any
  listSlotOptimize:any
  activeId:any
  listProv:any
  @Input() idSelect:any
  loading:boolean = false
  @Input() optimize:boolean = false
  listParams: stockListParamsEntity= stockListParamsEmpty
  @Output() sendItemSlot = new EventEmitter()
  @Output() sendId = new EventEmitter()
  constructor(
    private apiService:ApiService,
    private store:Store<AppState>
  ) { 
    
  }

  ngOnInit(): void {
    this.store.select(selectStockListParams).subscribe((params:any)=>{
      this.apiService.post(SLOTS_FILTER_URL,params).subscribe((slot:any)=>{
        this.listSlot = slot
        this.loading = true
      })
    })
  }
  sendStockItem(id:number, item:any){
    this.idSelect = id
    this.sendItemSlot.emit(item)
  }
  filterPackage($event:any){
    this.loading = true
    let indexof = this.listSlot.filter((el:any)=>el.name.toLowerCase().indexOf($event.toLowerCase()) !== -1)
    this.sendId.emit(indexof[0].id)
    this.idSelect = indexof[0].id
  }
  filterA(){
    let listParamA =  {filters: {keyword: 'A',}, page: 1, size: 10,}
    this.apiService.post(SLOTS_FILTER_URL,listParamA).subscribe((slot:any)=>{
      this.listSlot = slot
      this.loading = true
  })
  }
  filterB(){
    let listParamB =  {filters: {keyword: 'B',}, page: 1, size: 10,}
    this.apiService.post(SLOTS_FILTER_URL,listParamB).subscribe((slot:any)=>{
      this.listSlot = slot
      this.loading = true
  })
  }
  filterC(){
    let listParamC =  {filters: {keyword: 'C',}, page: 1, size: 10,}
    this.apiService.post(SLOTS_FILTER_URL,listParamC).subscribe((slot:any)=>{
      this.listSlot = slot
      this.loading = true
  })
    
  }
  filterD(){
    let listParamD =  {filters: {keyword: 'D',}, page: 1, size: 10,}
    this.apiService.post(SLOTS_FILTER_URL,listParamD).subscribe((slot:any)=>{
      this.listSlot = slot
      this.loading = true
  })
    
  }


}
