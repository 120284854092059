import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/modules/app-common/domain/state.ts/app.state";
import { UsersState } from "../entities/UsersState";

 
export const selectLoadUsers = (state:AppState) => state.users;
 
export const selectloadUsers = createSelector(
  selectLoadUsers,
  (state: UsersState) => state.user
);
export const selectloadProfile = createSelector(
  selectLoadUsers,
  (state: UsersState) => state.profile
);
export const selectUsersListParams = createSelector(
  selectLoadUsers,
  (state: UsersState) => state.keyUser
)
export const selectProfilesListParams = createSelector(
  selectLoadUsers,
  (state: UsersState) => state.keyProfile
)

export const selectFetchUsers = createSelector(
  selectLoadUsers,
  (state: UsersState) => state.fetch
)

