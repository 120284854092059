import { createReducer, on } from "@ngrx/store";
import { OptimizeState } from "../entities/OptimizeState";
import { loadOptimize, updateOptimizeListParamsTwo } from "./optimize.actions";
import { optimizeListParamsEmpty } from "../entities/OptimizeListParamsEntity";

export const initialState: OptimizeState = {dates: [],
  listParams: optimizeListParamsEmpty,}

export const optimizeReducer = createReducer(
  initialState,
  on(loadOptimize, (state,{dates}) => ({ ...state, dates})),
  on(updateOptimizeListParamsTwo, (state, listParams) => ({
    ...state,
    listParams
  })),
);