import { createAction, props } from '@ngrx/store';

 
export const showSidebar = createAction('[Nav Sidebar] show Sidebar',);
export const hideSidebar = createAction('[Nav Sidebar] hide Sidebar',);
export const showHeader = createAction('[Nav Header] show Header',);
export const hideHeader = createAction('[Nav Header] hide Header',);
export const centerContent = createAction('[Nav Content] center Content',);
export const normalContent = createAction('[Nav Content] normal Content',);

