import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { FeatureLoginComponent } from './feature-login/feature-login.component';
import { AuthFormLoginComponent } from './domain/organisms/auth-form-login/auth-form-login.component';
import { AppCommonModule } from '../app-common/app-common.module';
import { AuthFormForgotPasswordComponent } from './domain/organisms/auth-form-forgot-password/auth-form-forgot-password.component';
import { FeatureForgotPasswordComponent } from './feature-forgot-password/feature-forgot-password.component';
import { AuthTemplateLoginComponent } from './domain/templates/auth-template-login/auth-template-login.component';
import { AuthTemplateForgotPasswordComponent } from './domain/templates/auth-template-forgot-password/auth-template-forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    FeatureLoginComponent,
    AuthFormLoginComponent,
    AuthFormForgotPasswordComponent,
    FeatureForgotPasswordComponent,
    AuthTemplateLoginComponent,
    AuthTemplateForgotPasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    
  ],
  exports: [
    FeatureLoginComponent,
    AuthTemplateForgotPasswordComponent,
    FeatureForgotPasswordComponent,
  ]
})
export class AuthModule { }
