import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {

  model:any
  @Output() updateModelEvent = new EventEmitter()
  @Output() filterByKeyEvent = new EventEmitter()
  @Output() search = new EventEmitter()
  @Output() filterByKeyTwoEvent = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }
  updateModel($event: string){
    this.model = $event
    this.updateModelEvent.emit($event)

  }
  checkKeyCode($event: KeyboardEvent){
    if($event.key === 'Enter'){
      this.filterByKeyEvent.emit($event)
      this.search.emit(this.model)
    }
  }
  checkKeyCod(){
      this.filterByKeyTwoEvent.emit()
      this.search.emit(this.model)
  }

}
