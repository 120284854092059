<div class="d-flex justify-content-center w-100" *ngIf="!loading">
    <div class="spinner-border text-danger" role="status">
    </div>
</div>
<div class="mt-3" *ngIf="loading">
    <app-product-card [dateProduct]="item" [active]="true" *ngFor="let item of products"></app-product-card>
    <div class="mt-3">
        <ngb-pagination [collectionSize]="20" aria-label="Default pagination"></ngb-pagination>
    </div>
</div>
