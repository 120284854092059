<app-title [title]="'Administración de slots'"  [fs]="'3 fw-bolder mb-3'"></app-title> 
<div class="d-flex ms-3">
    <div class="me-3">
        <button class="btn button-red btn-sm" type="button" (click)="openModalNewSlot()">{{stock.filter.newSlot}}</button>
        <button class="btn button-red btn-sm ms-2" type="button" (click)="openModalUpdateSlots()">{{ stock.filter.updateFile }}</button>
    </div>
    <div>
        <app-input-button></app-input-button>
    </div>
</div>
