<div class="mb-4">
    <app-input-search (search)="filterPackage($event)"></app-input-search>
</div>
<div class="card-over-red pe-3">
    <ngb-accordion  [closeOthers]="true" activeIds="{{idSelect}}">
      <ngb-panel id="{{item.id}}" *ngFor="let item of dateClients;index as i">
        <ng-template  ngbPanelHeader let-opened="opened">
          <button class="btn border-0 p-0 w-100 h-100" ngbPanelToggle (click)="sendCustomerName(item.name, item.id, item)">
              <div class="card p-3 mb-3" [ngClass]="{'active':!opened}">
                  <div class="d-flex justify-content-between">
                    <img src="./assets/supp3.png" height="50">
                      <div>
                          <app-title [title]="'Código'"></app-title>
                          <app-title [title]="item.id" [class]="'fw-bolder'" [colorText]="'info'"></app-title>
                      </div>
                  </div>
                  <app-title [title]="item.name" [class]="'text-over fw-bolder text-start'"></app-title>
              </div>
          </button>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
</div>
  