import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { PACKAGE_CODES_FILTER_URL, PACKAGE_CODES_LENGTH_URL } from '../../constants/ApiConstants';
import { codesListParamsEmpty, codesListParamsEntity } from '../../entities/CodesListParamsEntity';
import { updateCodesListParams } from '../../state/codes.actions';
import { selectCodesListParams } from '../../state/codes.selectors';
import { CodeEntity } from '../../entities/CodesEntity';
import { selectFetch } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { fetchListDone } from 'src/app/modules/app-common/domain/state.ts/common.actions';

@Component({
  selector: 'app-codes-template-list-asign',
  templateUrl: './codes-template-list-asign.component.html',
  styleUrls: ['./codes-template-list-asign.component.scss']
})
export class CodesTemplateListAsignComponent implements OnInit {
  codesList: CodeEntity[]
  pageSize = 7;
  collectionSize: number

  loading:boolean = false
  listParams: codesListParamsEntity = codesListParamsEmpty
  constructor(
    private store:Store<AppState>,
    public apiService:ApiService,
  ) {
    this.codesList = []
    this.collectionSize = 0
    this.store.select(selectFetch).subscribe(fetch => {
      if(fetch){
        this.loadCodes()
        this.store.dispatch(fetchListDone())
      }
    })
   }

  ngOnInit(): void {
   this.loadCodes()
  }
  loadCodes(){
    this.store.select(selectCodesListParams).subscribe(params => {
      this.listParams = params
      this.apiService.post(PACKAGE_CODES_FILTER_URL, {
        ...params,
        filters: {
          ...params.filters,
          assigned: false
        }
      }).subscribe((data:any) => {
        this.codesList = data
        this.apiService.post(PACKAGE_CODES_LENGTH_URL, {
          ...params,
          filters: {
            ...params.filters,
            assigned: false
          }
        }).subscribe(length => {
          this.collectionSize = <number>length
          this.loading = true
        })
      })
    })
  }
  updateListParams(){
    window.scroll(0, 0)
    this.store.dispatch(updateCodesListParams({...this.listParams}))
  }
}
