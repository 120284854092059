import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule } from './modules/auth/auth.module';
import { AppCommonModule } from './modules/app-common/app-common.module';
import { NavModule } from './modules/nav/nav.module';
import { AuthRoutingModule } from './modules/auth/auth-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { navReducer } from './modules/nav/domain/state/nav.reducer';
import { authReducer } from './modules/auth/domain/state/auth.reducer';
import { IncomeModule } from './modules/income/income.module';
import { IncomeRoutingModule } from './modules/income/income-routing.module';
import { StoreModule } from '@ngrx/store';
import { OfficesModule } from './modules/offices/offices.module';
import { OfficesRoutingModule } from './modules/offices/offices-routing.module';
import { CodesModule } from './modules/codes/codes.module';
import { CodesRoutingModule } from './modules/codes/codes-routing.module';
import { StockModule } from './modules/stock/stock.module';
import { StockRoutingModule } from './modules/stock/stock-routing.module';
import { ProductsModule } from './modules/products/products.module';
import { ProductsRoutingModule } from './modules/products/products-routing.module';
import { OptimizeModule } from './modules/optimize/optimize.module';
import { OptimizeRoutingModule } from './modules/optimize/optimize-routing.module';
import { UsersModule } from './modules/users/users.module';
import { UsersRoutingModule } from './modules/users/users-routing.module';
import { ROOT_REDUCERS } from './modules/app-common/domain/state.ts/app.state';
import { ClientsModule } from './modules/clients/clients.module';
import { ZonesModule } from './modules/zones/zones.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    AppCommonModule,
    StoreModule.forRoot(ROOT_REDUCERS),
    EffectsModule.forRoot([]),
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    AuthModule,
    NavModule,
    AuthRoutingModule,
    NavModule,
    IncomeModule,
    IncomeRoutingModule,
    OfficesRoutingModule,
    CodesModule,
    CodesRoutingModule,
    StockModule,
    StockRoutingModule,
    ProductsModule,
    ProductsRoutingModule,
    OptimizeModule,
    OptimizeRoutingModule,
    UsersModule, 
    UsersRoutingModule,
    ClientsModule,
    ZonesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
