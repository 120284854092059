export const tableAsign = [
    {
        title: 'Orden',
        col: 'col-2'
    },
    {
        title: 'Proovedor',
        col: 'col'

    },
    {
        title: 'Productos',
        col: 'col-2'
    },
]
export const tableIncome = [
    {
        title: 'Codigo',
        col: 'col-2'
    },
    {
        title: 'Producto',
        col: 'col'
    },
    {
        title: 'Unidad de empaque',
        col: 'col'
    },
    {
        title: 'Cantidad (unidades de empaque)',
        col: 'col-2'
    },
    {
        title: 'Unidades',
        col: 'col-1'
    },
]
export const tableOrder = [
    {
        title: 'Seleccionado',
        col: 'col-1'
    },
    {
        title: 'Código',
        col: 'col-1'
    },
    {
        title: 'Categoria',
        col: 'col-4'
    },
    {
        title: 'Producto',
        col: 'col'
    },
    {
        title: 'Cantidad',
        col: 'col-1'
    },
]
export const tableProduct = [
    {
        title: 'Código',
        col: 'col-1'
    },
    {
        title: 'Producto',
        col: 'col'
    },
    {
        title: 'Unidad de empaque',
        col: 'col'
    },
    {
        title: 'Cantidad (unidades de empaque)',
        col: 'col-3'
    },
   
]
