import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PROVIDER_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { providerEntity, PROVIDER_EMPTY } from 'src/app/modules/app-common/domain/entities/ProviderEntity';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { income } from '../../constants/IncomeConstants';

@Component({
  selector: 'app-card-supplier',
  templateUrl: './card-supplier.component.html',
  styleUrls: ['./card-supplier.component.scss']
})
export class CardSupplierComponent implements OnInit {

  public income = income
  @Input() dateSupplier:providerEntity[] = [PROVIDER_EMPTY]
  providers:any
  activeId:any
  listProv:any
  @Input() idSelect:any
  cargued:boolean = false
  @Output() providerId = new EventEmitter()
  @Output() sendId = new EventEmitter()
  constructor(
    private apiService:ApiService
  ) { 
    
  }

  ngOnInit(): void {
    this.apiService.post(PROVIDER_FILTER_URL).subscribe((providers) => {
      this.providers = <providerEntity[]>providers
      this.listProv = <providerEntity[]>providers
    })
  }
  sendProviderId(id:number){
    this.idSelect = id
    this.providerId.emit(id)
  }
  sendAllId(){
    this.providerId.emit('')
  }
  openEvent(id:number){
    this.idSelect = id
    this.providerId.emit(id)
    this.cargued = true
  }
  filterPackage($event:any){
    this.cargued = true
    let indexof = this.providers.filter((el:any)=>el.name.toLowerCase().indexOf($event.toLowerCase()) !== -1)
    this.sendId.emit(indexof[0].id)
    this.idSelect = indexof[0].id
  }

}
