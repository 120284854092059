export interface ProductPackageCodesEntity{
  filters: {
    keyword: string
  }
  page: number,
  size: number
}

export const ProductPackageCodesEmpty: ProductPackageCodesEntity = {
  filters: {
    keyword: ''
  },
  page: 1,
  size: 10
}