import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { letters, stock } from '../../constants/StockConstants';
import { StockTemplateModalNewSlotComponent } from '../stock-template-modal-new-slot/stock-template-modal-new-slot.component';
import { StockTemplateModalStockFileUpdateComponent } from '../stock-template-modal-stock-file-update/stock-template-modal-stock-file-update.component';

@Component({
  selector: 'app-stock-template-filter-admin',
  templateUrl: './stock-template-filter-admin.component.html',
  styleUrls: ['./stock-template-filter-admin.component.scss']
})
export class StockTemplateFilterAdminComponent implements OnInit {

  public stock = stock
  public letters = letters
  constructor(
    public modalService:NgbModal,
  ) { }

  ngOnInit(): void {
  }
  openModalNewSlot(){
    const modalRef = this.modalService.open(StockTemplateModalNewSlotComponent,{
      size: 'lg'
    });
  }

  openModalUpdateSlots(){
    const modalRef = this.modalService.open(StockTemplateModalStockFileUpdateComponent, {
      size: 'md'
    });
  }
}
