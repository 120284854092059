import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/modules/app-common/domain/state.ts/app.state";
import { IncomeState } from "./IncomeState";

 
export const selectloadIncomes = (state: AppState) => state.income;
 
export const selectIncomeListParams = createSelector(
  selectloadIncomes,
  (state: IncomeState) => state.listParams
)

