import { LOCALSTORAGE_TOKEN_KEYNAME } from "src/app/modules/auth/domain/constants/AuthConstants"

export const termConstants = {
    filter: {
        search: 'Buscar',
    },
    modal: {
        save: 'Guardar',
        cancel: 'Cancelar',
        select: 'Selecionar',
        continue: 'Continuar'
    }
}

export const PROVIDER_FILTER_URL = "/data/providers/filter"
export const PRODUCT_URL = "/products/products/"
export const PRODUCT_FILTER_URL = "/products/products/filter"
export const PROVIDER_URL = "/data/providers/"
export const USER_FILTER_URL = "/users/filter"
export const USER_URL = "/users/"
export const INCOME_FILTER_URL = "/incomes/filter"
export const INCOME_ASSIGN_URL = "/incomes/assign"
export const INCOME_URL = "/incomes/"
export const OFFICE_FILTER_URL = "/dispatches/filter"
export const OFFICE_URL = "/dispatches/"
export const OFFICE_ASSIGN_URL = "/dispatch_separation_lists/"
export const PROFILE_FILTER_URL = "/users/profiles/filter"
export const PROFILE_URL = "/users/profiles/"
export const CLIENT_FILTER_URL = "/clients/filter"
export const CLIENT_URL = "/clients/"
export const CLIENT_HEADQUARTERS_FILTER_URL = "/client_headquarters/filter"
export const CLIENT_HEADQUARTERS_URL = "/client_headquarters/"
export const WAREHOUSES_FILTER_URL = "/warehouses/filter"
export const WAREHOUSES_URL = "/warehouses/"
export const DOCUMENT_FILTER_URL = "/users/document_types/filter"
export const DOCUMENT_URL = "/users/document_types/"
export const AREA_FILTER_URL = "/users/document_types/filter"
export const AREA_URL = "/users/document_types/"
export const STOCK_FILTER_URL = "/stock/filter"
export const STOCK_URL = "/stock/"
export const SLOTS_FILTER_URL = "/slots/filter"
export const SLOTS_URL = "/slots/"
export const VEHICLE_FILTER_URL = "/vehicles/filter"
export const VEHICLE_URL = "/vehicles/"
export const ZONE_FILTER_URL = "/enlistment_zones/filter"
export const ZONE_URL = "/enlistment_zones/"
export const DISTRIBUTION_FILTER_URL = "/distribution_zones/filter"
export const DISTRIBUTION_URL = "/distribution_zones/"
export const SEPARATION_FILTER_URL = "/dispatch_separation_lists/filter"
export const SEPARATION_URL = "/dispatch_separation_lists/"