<div class="p-3 border-end">
    <app-title [title]="offices.calculeVolumen.calcule" [fs]="'5'"></app-title>
    <div class="d-flex">
        <div class="col">
            <app-title [title]="offices.calculeVolumen.vehicle" class="mt-1 mb-1"></app-title>
            <div class="form-group">
                <select class="form-select form-select-sm" (change)="sendIdVehicle()" [(ngModel)]="model">
                  <option value="" selected="selected">Selecciona el vehiculo</option>
                    <option *ngFor="let item of listVeh" value="{{item.id}}" >
                      {{item.name}}
                    </option>
                </select>
            </div>
            <app-title [title]="offices.calculeVolumen.volumenVehicle" class="mt-1 mb-1"></app-title>
            <app-title [title]="dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth + ' m3'" [colorText]="'info fw-bolder'"></app-title>
            <app-title [title]="offices.calculeVolumen.volumenSelect"></app-title>
            <app-title [title]="select+ ' m3'" [colorText]="'info fw-bolder'" class="mt-1 mb-1"></app-title>
            <app-title [title]="offices.calculeVolumen.volumenRest"></app-title>
            <div class="card shadow col-6 mt-1">
                <div class="d-flex justify-content-center" >
                    <app-title [title]="dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select + ' m3 / '" [colorText]="'info fw-bolder'" *ngIf="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth))>=0"></app-title>
                    <app-title [title]="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth)) + '%'" [colorText]="'info fw-bolder'" *ngIf="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth))>=0"></app-title>
                </div>            
            </div>
        </div>
        <div class="col">
            <div class="text-center" *ngIf="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth))>=0">
                <app-title [title]="offices.calculeVolumen.carge"></app-title>
                <img src="./assets/Camion-1.png" class="w-100" *ngIf="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth))=== 100">
                <img src="./assets/Camion-2.png" class="w-100" *ngIf="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth)) <= 99 && ((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth)) > 66">
                <img src="./assets/Camion-3.png" class="w-100" *ngIf="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth)) <= 66 && ((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth)) > 33">
                <img src="./assets/Camion-4.png" class="w-100" *ngIf="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth)) <= 33 && ((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth)) > 0">
                <img src="./assets/Camion-5.png" class="w-100" *ngIf="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth)) === 0">
                <img src="./assets/Camion-6.png" class="w-100" *ngIf="((dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth-select)*100/(dateVehicle.dimensionDepth*dateVehicle.dimensionDepth*dateVehicle.dimensionWidth)) <0">
            </div>
            
        </div>
    </div>
</div>
