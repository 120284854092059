export const tableClient = [
    {
        title: 'Seleccionado',
        col: 'col-1'
    },
    {
        title: 'Código',
        col: 'col-1'
    },
    {
        title: 'Producto',
        col: 'col'
    },
    {
        title: 'Cantidad comercial',
        col: 'col-2'
    },
    {
        title: 'Multiplo',
        col: 'col-2'
    },
    {
        title: 'Cantidad final',
        col: 'col-2'
    },
]
export const tableListSeparation = [
    {
        title: 'Zona',
        col: 'col-1'
    },
    {
        title: 'Orden',
        col: 'col'
    },
    {
        title: 'Cliente',
        col: 'col'
    },
    {
        title: 'Volumen(m3)',
        col: 'col'
    },
    {
        title: 'Productos',
        col: 'col'
    },
    {
        title: 'Vehículo',
        col: 'col'
    },
    {
        title: 'Acciones',
        col: 'col'
    },
   
]

export const tableItems = [
    {
        title: 'Codigo',
        col: 'col-3'
    },
    {
        title: 'Producto',
        col: 'col'
    },
    {
        title: 'Cantidad',
        col: 'col-3'
    },
]
export const tableModal = [
    {
        title: 'Zona',
        col: 'col'
    },
    {
        title: 'Orden',
        col: 'col'
    },
    {
        title: 'Cliente',
        col: 'col'
    },
    {
        title: 'Volumen(m3)',
        col: 'col'
    },
    {
        title: 'Productos',
        col: 'col'
    },
    {
        title: 'Faltantes',
        col: 'col'
    },
    {
        title: 'Información',
        col: 'col-2'
    },
    

]