import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { USER_URL, WAREHOUSES_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { LocalService } from 'src/app/modules/auth/domain/services/local.service';
import { userEntity } from 'src/app/modules/users/domain/entities/UsersEntities';
import { UsersTemplateModalNewuserComponent } from 'src/app/modules/users/domain/templates/users-template-modal-newuser/users-template-modal-newuser.component';
import { SideBarService } from '../../services/sidebar.service';
import { fetchList, fetchListDone } from 'src/app/modules/app-common/domain/state.ts/common.actions';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss']
})
export class HeaderProfileComponent implements OnInit {

  dateUser:any
  listBod:any
  icons = ICONS_CONSTANTS
  constructor( public store:Store, 
    private modalService: NgbModal,
    private router:Router,
    private sideBarService:SideBarService,
    public local:LocalService,
    public apiService:ApiService
    
    ) { }

  ngOnInit(): void {
    this.apiService.get(USER_URL+'me').subscribe((me:any)=>{
      localStorage.setItem('user', JSON.stringify(me))
      this.dateUser = <userEntity[]>me
      this.store.dispatch(fetchList())
    })
    this.apiService.post(WAREHOUSES_FILTER_URL,{}).subscribe(response => this.listBod = <any[]>response)
  }

  closeProfile() {
    this.router.navigateByUrl("/auth/login")
    localStorage.clear()
    this.sideBarService.hide()
    this.store.dispatch(fetchList())
    this.store.dispatch(fetchListDone())
    
  }
  openModalEditUser(data:any, id:number) {
    const modalRef = this.modalService.open(UsersTemplateModalNewuserComponent,{
      size: 'sm'
    });modalRef.componentInstance.item = data, modalRef.componentInstance.id = id
  }

}
