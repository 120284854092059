<div class="d-flex justify-content-center" *ngIf="!loading">
  <div class="spinner-border text-danger" role="status">
  </div>
</div>
<ngb-accordion #a="ngbAccordion" [closeOthers]="true" activeIds="custom-panel-1" class="d-flex flex-wrap border-0 p-0" *ngIf="loading">
    <ngb-panel id="{{item.id}}" *ngFor="let item of dateSlotsA;index as i">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="m-3">
          <div class="accordion-button custom-header justify-content-between p-0" [class.collapsed]="!opened">
            <div class="card shadow-sm bg-white">
                <div class="mb-4">
                    <app-slot-card [dateSlot]="item"></app-slot-card>
                    <div class="d-flex justify-content-center">
                        <button class="btn button-red btn-sm" type="button" (click)="openModalSlot(item)">{{stock.slotCard.manage}}</button>
                    </div>
                    
                </div>
            </div>
            <div class="position-absolute top-100 start-50 translate-middle text-center">
                <button ngbPanelToggle class="btn btn-sm btn-primary rounded-circle">
                  <i class="bi bi-{{icons.FILTER}}"></i>     
                </button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
            <div class="text-center ms-4 mt-4 w-100" *ngIf="item.appStock[0] !== undefined">
                <app-sub-slot-card [dateProduct]="item.appStock[0]?.appProducts"></app-sub-slot-card>
            </div>
            <div class="text-center ms-4 bg-white mt-4 w-100" *ngIf="item.appStock[0] === undefined">
              <app-title [title]="'No hay productos'" [colorText]="'danger'"></app-title>
          </div>              
      </ng-template>
    </ngb-panel>
</ngb-accordion>