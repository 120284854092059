import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { FeatureUsersComponent } from './feature-users/feature-users.component';
import { FeatureProfilesComponent } from './feature-profiles/feature-profiles.component';
import { AppCommonModule } from '../app-common/app-common.module';
import { UsersTemplateListComponent } from './domain/templates/users-template-list/users-template-list.component';
import { UsersTemplateFilterComponent } from './domain/templates/users-template-filter/users-template-filter.component';
import { CardUserComponent } from './domain/organisms/card-user/card-user.component';
import { UsersTemplateFilterProfileComponent } from './domain/templates/users-template-filter-profile/users-template-filter-profile.component';
import { UsersTemplateListProfileComponent } from './domain/templates/users-template-list-profile/users-template-list-profile.component';
import { CardProfileComponent } from './domain/organisms/card-profile/card-profile.component';
import { UsersTemplateModalNewuserComponent } from './domain/templates/users-template-modal-newuser/users-template-modal-newuser.component';
import { UsersTemplateModalNewprofileComponent } from './domain/templates/users-template-modal-newprofile/users-template-modal-newprofile.component';
import { NavModule } from '../nav/nav.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    FeatureUsersComponent,
    FeatureProfilesComponent,
    UsersTemplateListComponent,
    UsersTemplateFilterComponent,
    CardUserComponent,
    UsersTemplateFilterProfileComponent,
    UsersTemplateListProfileComponent,
    CardProfileComponent,
    UsersTemplateModalNewuserComponent,
    UsersTemplateModalNewprofileComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    AppCommonModule,
    NavModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CardUserComponent,
  ]
})
export class UsersModule { }
