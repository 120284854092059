export const PRODUCT_EMPTY={
    code: '',
    image: '',
    product: '',
    units: ''
  }

export const OPTIMIZE_EMPTY= {
    sectionOpt: '',
    sectionObj: '',
    valueOpt: 0,
    valueObj: 0,
    value: 0,  
  }

export const TRANSFERED_EMPTY = {
    cod: '',
    image: '',
    name: '',
    unit: '',
    box: '',
    sachets: '',
    packing: '',
    slot: '',
    date: '',
    day: '',
    
  }