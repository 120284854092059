import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Input() title:string = '';
  @Input() url:string = '';
  @Input() link:string = '';
  @Input() colorLink:string = '';
  @Input() icon:string = '';
  @Input() class:string = '';
  @Input() active:string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
