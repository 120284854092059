<div class="card-over-red pe-3">
  <ngb-accordion  [closeOthers]="true" activeIds="{{idSelect}}">
    <ngb-panel id="{{item.id}}" *ngFor="let item of packages;index as i">
      <ng-template  ngbPanelHeader let-opened="opened">
        <button class="btn border-0 p-0 w-100 h-100" ngbPanelToggle (click)="sendProductId(item.id)">
          <div class="card shadow mb-3 p-3" [ngClass]="{'active':!opened}">
            <div class="d-flex">
                <div class="card-1 border rounded ms-3 me-3 mt-1">
                  <img src="{{item.appProducts.image}}">
                </div>
                <div class="card-2 border-end pe-3">
                  <app-title [title]="codes.codesCard.product" [colorText]="'primary'"></app-title>
                  <app-title [title]="item.appProducts.name" [fs]="'6 fw-bolder'"></app-title>
                  <app-title [title]="item.name" [fs]="'6 fw-bolder'" [colorText]="'secondary'"></app-title>
                </div>
                <div class="text-center border-end ps-3 pe-3">
                  <img src="{{codes.codesCard.imgBox}}">
                    <app-title [title]="codes.codesCard.box" [colorText]="'primary'"></app-title>
                </div>
            </div>
        </div>
        </button>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
