import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICONS_CONSTANTS } from 'src/app/modules/app-common/domain/constants/IconsConstants';
import { sections } from '../../constants/NavConstants';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { selectFetch } from 'src/app/modules/app-common/domain/state.ts/common.selectors';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { fetchListDone } from 'src/app/modules/app-common/domain/state.ts/common.actions';
import { SideBarService } from '../../services/sidebar.service';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss']
})
export class SidebarItemComponent implements OnInit {


  @Input() route:string = '';
  icons=ICONS_CONSTANTS
  @Input() icon:string = '';
  @Input() src:string = '';
  @Input() img:string = '';
  @Input() title:string = '';
  bg:string = '';
  bg1:string = '';
  bg3:string = '';
  bg2:string = '';
  bg4?:string = '';
  bg5?:string = '';
  bg6?:string = '';
  bg7?:string = '';
  bg8?:string = '';
  bg9?:string = '';
  bg10?:string = '';
  bg11?:string = '';
  subtitle1:string = '';
  subtitle2:string = '';
  subtitle3:string = '';
  subtitle4:string = '';
  subtitle5:string = '';
  subtitle6:string = '';
  subtitle7:string = '';
  subtitle8:string = '';
  subtitle9:string = '';
  subtitle10:string = '';
  subtitle11:string = '';
  colorText:string = '';
  url:string = '';
  url1:string = '';
  url2:string = '';
  url3:string = '';
  url4:string = '';
  url5:string = '';
  url6:string = '';
  url7?:string = '';
  menu:string = '';
  show:any;
  userProfile:string | null = '';
  arProfiles:any
  cargueMenu:boolean = false
  public sections = sections
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private apiService:ApiService,
    private sideBarService:SideBarService
    
  ) {
    this.store.select(selectFetch).subscribe((fetch)=>{
      if(fetch){
        let user:any =  localStorage.getItem('user')
        let jsonUser = JSON.parse(user)
        this.userProfile = jsonUser.userProfiles.name
        this.sideBarService.show()
        this.store.dispatch(fetchListDone())
        this.cargueMenu = true
      }
      else{
      }
    })
   }

  ngOnInit(): void {
    this.routeSelector();
  }
  routeSelector(){
    this.router.events.subscribe((event:any) => {   
    switch(event.url){
      case '/usuarios/admin':
        this.sections[0].bg1 = 'danger';
        this.sections[0].bg2 = 'none';   
      break;
      case '/usuarios/perfiles':
        this.sections[0].bg1 = 'none';
        this.sections[0].bg2 = 'danger';
      break
      case '/ingresos':
        this.sections[1].bg1= 'danger'
     
      break;
      case '/despachos':
        this.sections[2].bg1 = 'danger';
        this.sections[2].bg2 = 'none';   
      break;
      case '/listas':
        this.sections[2].bg1 = 'none';
        this.sections[2].bg2 = 'danger';
      break
      case '/codigos-por-asignar':
        this.sections[3].bg1= 'danger'
        this.sections[3].bg2= 'none'
    
      break
      case '/codigos-asignados':
        this.sections[3].bg1= 'none'
        this.sections[3].bg2= 'danger'
    
      break
      case '/stock/administracion':
        this.sections[4].bg1= 'danger'
        this.sections[4].bg2= 'none'
      
      break
      case '/stock/lista':
        this.sections[4].bg1 = 'none';
        this.sections[4].bg2 = 'danger';
      break
      case '/productos':
        this.sections[5].bg1= 'danger'
      
      break
      case '/optimizar/nivel':
        this.sections[6].bg1= 'danger'
        this.sections[6].bg2= 'none'
        this.sections[6].bg3= 'none'
       
      break
      case '/optimizar/inventario':
        this.sections[6].bg1 = 'none';
        this.sections[6].bg2 = 'danger';
        this.sections[6].bg3 = 'none';
      break
      case '/optimizar/traslado':
        this.sections[6].bg1 = 'none';
        this.sections[6].bg2 = 'none';
        this.sections[6].bg3 = 'danger';
      break
      case '/optimizar/proveedores':
        this.sections[7].bg1 = 'danger';
        this.sections[7].bg2 = 'none';
        this.sections[7].bg3 = 'none';
        this.sections[7].bg4 = 'none';
        this.sections[7].bg5 = 'none';
        this.sections[7].bg6 = 'none';
        this.sections[7].bg7 = 'none';
        this.sections[7].bg8 = 'none';
      break
      case '/optimizar/productos':
        this.sections[7].bg1 = 'none';
        this.sections[7].bg2 = 'danger';
        this.sections[7].bg3 = 'none';
        this.sections[7].bg4 = 'none';
        this.sections[7].bg5 = 'none';
        this.sections[7].bg6 = 'none';
        this.sections[7].bg7 = 'none';
        this.sections[7].bg8 = 'none';
      break
      case '/optimizar/paquetes':
        this.sections[7].bg1 = 'none';
        this.sections[7].bg2 = 'none';
        this.sections[7].bg3 = 'danger';
        this.sections[7].bg4 = 'none';
        this.sections[7].bg5 = 'none';
        this.sections[7].bg6 = 'none';
        this.sections[7].bg7 = 'none';
        this.sections[7].bg8 = 'none';
      break
      case '/optimizar/familias':
        this.sections[7].bg1 = 'none';
        this.sections[7].bg2 = 'none';
        this.sections[7].bg3 = 'none';
        this.sections[7].bg4 = 'danger';
        this.sections[7].bg5 = 'none';
        this.sections[7].bg6 = 'none';
        this.sections[7].bg7 = 'none';
        this.sections[7].bg8 = 'none';
      break
      case '/optimizar/clientes':
        this.sections[7].bg1 = 'none';
        this.sections[7].bg2 = 'none';
        this.sections[7].bg3 = 'none';
        this.sections[7].bg4 = 'none';
        this.sections[7].bg5 = 'danger';
        this.sections[7].bg6 = 'none';
        this.sections[7].bg7 = 'none';
        this.sections[7].bg8 = 'none';
      break
      case '/optimizar/sedes':
        this.sections[7].bg1 = 'none';
        this.sections[7].bg2 = 'none';
        this.sections[7].bg3 = 'none';
        this.sections[7].bg4 = 'none';
        this.sections[7].bg5 = 'none';
        this.sections[7].bg6 = 'danger';
        this.sections[7].bg7 = 'none';
        this.sections[7].bg8 = 'none';
      break
      case '/optimizar/bodegas':
        this.sections[7].bg1 = 'none';
        this.sections[7].bg2 = 'none';
        this.sections[7].bg3 = 'none';
        this.sections[7].bg4 = 'none';
        this.sections[7].bg5 = 'none';
        this.sections[7].bg6 = 'none';
        this.sections[7].bg7 = 'danger';
        this.sections[7].bg8 = 'none';
      break
      case '/optimizar/tipos':
        this.sections[7].bg1 = 'none';
        this.sections[7].bg2 = 'none';
        this.sections[7].bg3 = 'none';
        this.sections[7].bg4 = 'none';
        this.sections[7].bg5 = 'none';
        this.sections[7].bg6 = 'none';
        this.sections[7].bg7 = 'none';
        this.sections[7].bg8 = 'danger';
      break
    }
  })
  }

}
