import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-row-item',
  templateUrl: './row-item.component.html',
  styleUrls: ['./row-item.component.scss']
})
export class RowItemComponent implements OnInit {

  @Input() title:any = '';
  @Input() src:string = '';
  @Input() colorText:string = '';
  @Input() button:string = '';
  @Input() placeholder:string = '';
  @Input() active:boolean=false;
  @Input() textWar:boolean=false;
  constructor() { }

  ngOnInit(): void {
  }

}
