import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './modules/app-common/domain/state.ts/app.state';
import { selectshowHead } from './modules/nav/domain/state/nav.selector';

@Component({
  selector: 'app-root',
  animations: [
    trigger('showNavSection', [
      state('open', style({
        paddingLeft: 230,
        paddingTop: 76
      })),
      state('close', style({
        
      })),
      // ...
      transition('* => *', [
        animate('1s', keyframes ( [
          style({ offset: 0.1 }),
        ]))
      ])
    ])

  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showNavSection: boolean = false

  constructor(
    private store: Store<AppState>
  ){
    this.store.select(selectshowHead).subscribe(show => this.showNavSection = show)
  }
}

