<div class="card p-3 bg-light">
    <div class="text-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <form class="text-center" [formGroup]="idForm">
        <app-title [title]="'Digita el número de productos máximos para el slot'"></app-title>
        <div class="form-group form-group-sm ps-5 pe-5">
            <input class="form-control form-control-sm" type="text" formControlName="max_product_types">
        </div>
        <div class="d-flex align-items-center justify-content-center mt-3">
            <button class="btn button-blue btn-sm me-2" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
            <button class="btn button-red-strong  btn-sm" (click)="changeMaxType()">Guardar</button>
        </div>
    </form>
</div>

