export interface providerEntity {
    id: number,
    logo:string,
    name: string,
    active:boolean,
}

export const PROVIDER_EMPTY = {
    id: 0,
    name: '',
    logo: '',
    active:false,
}

