import { Component, OnInit } from '@angular/core';
import { OPTIMIZE_CYCLE_URL, optimize } from '../../constants/OptimizeConstants';
import { tableInventory } from '../../constants/TablesOptimize';
import { packageEntity } from 'src/app/modules/products/domain/entities/ProductsEntities';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { selectProductsListParams } from 'src/app/modules/products/domain/state/products.selectors';
import { PRODUCT_PACKAGES_FILTER_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { STOCK_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';

@Component({
  selector: 'app-optimize-inventory-card',
  templateUrl: './optimize-inventory-card.component.html',
  styleUrls: ['./optimize-inventory-card.component.scss']
})
export class OptimizeInventoryCardComponent implements OnInit {

  public optimize = optimize
  public tableHead = tableInventory
  active:boolean = false
  order:any
  bus:any
  alert:boolean = false
  products:any[]=[]
  constructor(
    private store: Store<AppState>,
    public apiService:ApiService,
    public modalService:NgbModal,
  ) { }

  ngOnInit(): void {
    this.store.select(selectProductsListParams).subscribe(params => {
      this.apiService.post(STOCK_FILTER_URL, params).subscribe((data:any) => {
        this.products = <packageEntity[]>data
      })
    })
  }
  sendProduct(data:packageEntity[], index:number, amount:number){
    this.order = data[index].appProducts    
  }
  openModalAlert(){
    const modalRef = this.modalService.open(ModalAlertComponent, {
      size: 'sm'
    });
    modalRef.componentInstance.content = 'Se va a iniciar la optimización ¿Desea continuar?', 
    modalRef.componentInstance.item = {
      app_products_id: this.order
    }

    modalRef.componentInstance.id = this.order.id,
    modalRef.componentInstance.code = this.order.name,
    modalRef.componentInstance.buttonMain = 'Continuar'
    modalRef.componentInstance.url = OPTIMIZE_CYCLE_URL
  }
 

}