import { Component, OnInit } from '@angular/core';
import { tableListSeparation } from '../../constants/TableOffices';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductModalNewProductComponent } from 'src/app/modules/products/domain/templates/product-modal-new-product/product-modal-new-product.component';
import { ModalAlertComponent } from 'src/app/modules/app-common/domain/organisms/modal-alert/modal-alert.component';
import { PRODUCT_PACKAGES_URL } from 'src/app/modules/products/domain/constants/ApiConstants';
import { CLIENT_FILTER_URL, SEPARATION_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { OfficesTemplateModalComponent } from '../offices-template-modal/offices-template-modal.component';

@Component({
  selector: 'app-offices-template-list-separation',
  templateUrl: './offices-template-list-separation.component.html',
  styleUrls: ['./offices-template-list-separation.component.scss']
})
export class OfficesTemplateListSeparationComponent implements OnInit {

  head = tableListSeparation
  loading:boolean = false
  listSeparation:any
  constructor(
    public modalService:NgbModal,
    private apiService : ApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.post(SEPARATION_FILTER_URL, {}).subscribe(list => this.listSeparation = list)
  }
  openModalEdit(item:any, index:number){
    const modalRef = this.modalService.open(OfficesTemplateModalComponent,{
      size: 'xl'
    });
    modalRef.componentInstance.item = item, modalRef.componentInstance.id = index
  }
  openAlert(index:number){
    const modalRef = this.modalService.open(ModalAlertComponent,{
      size: 'sm'
    });
    modalRef.componentInstance.content = '¿Desea eliminar el producto?', modalRef.componentInstance.id = index, modalRef.componentInstance.url = PRODUCT_PACKAGES_URL,
    modalRef.componentInstance.buttonMain = 'Eliminar'
  }

}
