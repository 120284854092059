import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureCellarComponent } from '../app-common/feature-cellar/feature-cellar.component';
import { FeatureClientHeadquartersComponent } from '../app-common/feature-client-headquarters/feature-client-headquarters.component';
import { FeatureClientsComponent } from '../app-common/feature-clients/feature-clients.component';
import { FeatureDocumentTypesComponent } from '../app-common/feature-document-types/feature-document-types.component';
import { FeatureFamiliesComponent } from '../app-common/feature-families/feature-families.component';
import { FeaturePackingComponent } from '../app-common/feature-packing/feature-packing.component';
import { FeatureProductsComponent } from '../app-common/feature-products/feature-products.component';
import { FeatureProvidersComponent } from '../app-common/feature-providers/feature-providers.component';
import { AuthGuard } from '../auth/domain/guards/auth.guard';
import { FeatureInventoryComponent } from './feature-inventory/feature-inventory.component';
import { FeatureLevelComponent } from './feature-level/feature-level.component';
import { FeatureTransferedComponent } from './feature-transfered/feature-transfered.component';
import { FeatureZonesComponent } from '../app-common/feature-zones/feature-zones.component';
import { FeatureVehiclesComponent } from '../app-common/feature-vehicles/feature-vehicles.component';
import { FeatureZonesDistributionComponent } from '../app-common/feature-zones-distribution/feature-zones-distribution.component';

const routes: Routes = [
  {path: 'optimizar', children: [
    {path: 'nivel', component: FeatureLevelComponent, canActivate: [AuthGuard]},
    {path: 'inventario', component: FeatureInventoryComponent, canActivate: [AuthGuard]},
    {path: 'traslado', component: FeatureTransferedComponent, canActivate: [AuthGuard]},
    {path: 'proveedores', component: FeatureProvidersComponent, canActivate: [AuthGuard]},
    {path: 'productos', component: FeatureProductsComponent, canActivate: [AuthGuard]},
    {path: 'paquetes', component: FeaturePackingComponent, canActivate: [AuthGuard]},
    {path: 'familias', component: FeatureFamiliesComponent, canActivate: [AuthGuard]},
    {path: 'clientes', component: FeatureClientsComponent, canActivate: [AuthGuard]},
    {path: 'sedes', component: FeatureClientHeadquartersComponent, canActivate: [AuthGuard]},
    {path: 'bodegas', component: FeatureCellarComponent, canActivate: [AuthGuard]},
    {path: 'tipos', component: FeatureDocumentTypesComponent, canActivate: [AuthGuard]},
    {path: 'zonas', component: FeatureZonesComponent, canActivate: [AuthGuard]},
    {path: 'vehiculos', component: FeatureVehiclesComponent, canActivate: [AuthGuard]},
    {path: 'distribucion', component: FeatureZonesDistributionComponent, canActivate: [AuthGuard]},
    ]},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OptimizeRoutingModule { }
