<app-title [title]="'Traslados'"  [fs]="'3 fw-bolder mb-3'"></app-title> 
<div class="d-flex">
    <div class="me-3">
        <app-input-button></app-input-button>
    </div>
    <div>
      <div class="form-group">
        <select class="form-select form-select-sm">
          <option value="{{optimize.filter.category}}" selected></option>
          <option >Name</option>
        </select>
      </div>
    </div>
</div>
