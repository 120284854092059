export interface codesListParamsEntity{
    filters: {
      keyword: string,
      assigned:boolean
    },
    page: number,
    size: number,
    
  }
  
  export const codesListParamsEmpty: codesListParamsEntity = {
    filters: {
      keyword: '',
      assigned:false
    },
    page: 1,
    size: 10,
   
  }
    