export const offices = {
    cardProgress: {
        order: 'Orden de compra',
        numberProducts: 'No. de productos',
        zone: 'Zona',
        asign: 'Asignar'
    },
    assignOperators: {
        operators: 'Operarios',
        hour: 'Hora de alistamiento',
        zone: 'Zona de alistamiento',
        comments: 'Comentarios'
    },
    calculeVolumen: {
        calcule: 'Cálculo de volumen',
        vehicle: 'Vehículo',
        volumenVehicle: 'Volumen del vehículo',
        volumenSelect: 'Volumen seleccionado',
        volumenRest: 'Volumen restante',
        carge: 'Carga del vehículo'
    },
    cardClient: {
        code: 'Código'
    },
    filter: {
        newOrder: 'Crear orden',
        newList: 'Crear lista de separación',
        clients: 'Clientes',
        category: 'Categoria'
    },
    listSeparation: {
        list: 'Listas de separación',
        orders: 'Ordenes de despachos',
        category: 'Categoria',
        zone: 'Zona',
        pickup: 'Recogida',

    },
    newOrder: {
        newOrder: 'Crear orden de compra',
        select: 'Selecciona los productos',
        client: 'Cliente',
        sed: 'Sede',
        products: 'Productos',
        category: 'Categoría'
    }
}