import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PROVIDER_FILTER_URL } from 'src/app/modules/app-common/domain/constants/TermsConstants';
import { providerEntity } from 'src/app/modules/app-common/domain/entities/ProviderEntity';
import { ApiService } from 'src/app/modules/app-common/domain/services/api.service';
import { AppState } from 'src/app/modules/app-common/domain/state.ts/app.state';
import { PRODUCT_CATEGORIES_FILTER_URL, PRODUCT_PACKAGES_FILTER_URL } from '../../constants/ApiConstants';
import { products } from '../../constants/ProductsConstants';
import { categoryEntity, packageEntity } from '../../entities/ProductsEntities';
import { productsListParamsEmpty, productsListParamsEntity } from '../../entities/ProductsListParamsEntity';
import { loadPackages, updateProductsListParams } from '../../state/products.actions';
import { selectProductsListParams } from '../../state/products.selectors';
import { ProductModalNewProductComponent } from '../product-modal-new-product/product-modal-new-product.component';

@Component({
  selector: 'app-products-template-filter',
  templateUrl: './products-template-filter.component.html',
  styleUrls: ['./products-template-filter.component.scss']
})
export class ProductsTemplateFilterComponent implements OnInit {

  cat:any
  prov:any
  listCat:any
  listProv:any
  packages:packageEntity[]=[]
  listParams: productsListParamsEntity = productsListParamsEmpty
  public products = products
  constructor(public modalService:NgbModal, private apiService:ApiService, private store:Store<AppState>) { }

  ngOnInit(): void {
    this.store.select(selectProductsListParams).subscribe(params => this.listParams = params)  
    this.apiService.post(PROVIDER_FILTER_URL, {}).subscribe(providers => this.listProv = <providerEntity[]>providers)
    this.apiService.post(PRODUCT_CATEGORIES_FILTER_URL, {}).subscribe(categories => this.listCat = <categoryEntity[]>categories)
  }

  filterProviders(item:any,$event:KeyboardEvent){
    if($event.key==='Enter'){
      if(item !== ''){
        let key = this.packages.filter((el:any)=>el.appProducts.appProviders.name === item)
        this.store.dispatch(loadPackages({pack:key}))
      }else{
        this.apiService.post(PRODUCT_PACKAGES_FILTER_URL, {}).subscribe((res:any)=>{
          this.packages = <packageEntity[]>res
          this.store.dispatch(loadPackages({pack:res}))
        })
      }
      

    }
   
  }
  filterCategory(item:any,$event:KeyboardEvent){
    if($event.key==='Enter'){
      if(item !== ''){
        let key = this.packages.filter((el:any)=>el.appProducts.appProductCategories.name === item)
        this.store.dispatch(loadPackages({pack:key}))
      }else{
        this.apiService.post(PRODUCT_PACKAGES_FILTER_URL, {}).subscribe((res:any)=>{
          this.packages = <packageEntity[]>res
          this.store.dispatch(loadPackages({pack:res}))
        })
      }
     
    }
   
  }
  openModalNewProduct(){
    const modalRef = this.modalService.open(ProductModalNewProductComponent, {
      size: 'lg'
    })
  }
  updateList(keyword: string){
    this.store.dispatch(updateProductsListParams({
      ...this.listParams,
      filters: {
        ...this.listParams.filters,
        keyword: keyword
      }
    }))
  }

}
