import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalService } from 'src/app/modules/auth/domain/services/local.service';
import { NavState } from '../../state/NavState';
import { selectshowHead } from '../../state/nav.selector';

@Component({
  selector: 'app-nav-header',
  animations: [
    trigger('showHeader', [
      state('open', style({
        marginTop: 0
      })),
      state('close', style({
        marginTop: -70
      })),
      // ...
      transition('* => *', [
        animate('0.3s')
      ])
    ])

  ],
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnInit {
  showHeader$: boolean = false

  constructor(
    private store:Store<NavState>,
    public local:LocalService,
  ) { }

  ngOnInit(): void {
    this.store.select(selectshowHead).subscribe(data => {
      this.showHeader$ = data
    })
  }
}


