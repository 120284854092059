<div class="input-group ">
   <input type="text" class="form-control" placeholder="{{placeholder}}" formControlname="formcontrol">
      <div class="card border-0">
         <button class="btn-{{colorType}} text-{{colorText}} rounded">
            <div class="d-flex p-0 m-0">
              <i class="bi bi-{{ icon }}"></i>
              <p>{{title}}</p>
            </div>  
          </button>       
      </div>           
</div>
