import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-trash',
  templateUrl: './button-trash.component.html',
  styleUrls: ['./button-trash.component.scss']
})
export class ButtonTrashComponent implements OnInit {

  @Input() icon:string = '';
  @Input() class:string = '';
  @Input() colorType:string = '';
  @Input() position:string = '';
  @Input() colorText:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
