<div class="card shadow p-3 mb-3 ">
    <div class="d-flex">
        <div class="code-card text-center border-end pe-3">
            <app-title [title]="stock.productCard.code" [colorText]="'primary'"></app-title>
            <app-title [title]="dateProduct.appProducts?.code" [colorText]="'info'" [fs]="'6 fw-bolder'"></app-title>
        </div>
        <div class="card-1 border rounded ms-3 me-3 mt-1">
          <img src="{{dateProduct.appProducts?.image}}">
        </div>
        <div class="product-card border-end pe-3">
            <app-title [title]="stock.productCard.product" [colorText]="'primary'"></app-title>
            <app-title [title]="dateProduct.appProducts?.name" [fs]="'6 fw-bolder text-over'"></app-title>
        </div>
        <div class="ps-3 pe-3">
            <app-title [title]="stock.productCard.units" [colorText]="'primary'"></app-title>
            <app-title [title]="dateProduct?.quantity" [fs]="'6 fw-bolder'" [colorText]="'info'"></app-title>
        </div>
        <div class="pe-3" *ngIf="active">
            <app-title [title]="stock.productCard.boxs" [colorText]="'primary'"></app-title>
            <div class="d-flex">
                <app-title [title]="" [fs]="'6 fw-bolder me-2'"></app-title>
                <img src="{{stock.productCard.imgBoxs}}" class="w-50">
            </div>
           
        </div>
        <div class="pe-3" *ngIf="active">
            <app-title [title]="stock.productCard.sachets" [colorText]="'primary'"></app-title>
            <div class="d-flex">
                <app-title [title]="" [fs]="'6 fw-bolder me-2'"></app-title>
                <img src="{{stock.productCard.imgSachets}}" class="w-50">
            </div>
          
        </div>
        <div class="border-end pe-3" *ngIf="active">
            <app-title [title]="stock.productCard.packing" [colorText]="'primary'"></app-title>
            <div class="d-flex">
                <app-title [title]="" [fs]="'6 fw-bolder me-2'"></app-title>
                <img src="{{stock.productCard.imgPacking}}" class="w-50">
            </div>
            
        </div>
        <div class="border-end ps-3 pe-3">
            <app-title [title]="stock.productCard.ocupation" [colorText]="'primary'"></app-title>
            <app-title [title]="" [fs]="'6 fw-bolder'"></app-title>
        </div>
        <div class="ps-3 pe-3" *ngIf="active">
            <app-title [title]="stock.productCard.slot" [colorText]="'primary'"></app-title>
            <app-title [title]="dateProduct.appSlots?.name" [fs]="'3 fw-bolder'" [colorText]="'info'"></app-title>
        </div>
        <div class="ps-3 pt-3">
            <button class="btn button-red btn-sm" type="button" (click)="openModalSlot()">{{stock.productCard.actions}}</button>
        </div>
    </div>
</div>