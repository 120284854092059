export interface dateUser {
  name: string,
  password: string,
  id: string,
  img:string,
  address?: string,
  birthdate?: any,
  identity?: string,
  lastname?:string,
  phone?: string,
  registered?: string,
  state?: string,
  token?: any,
  trash?: string,
  username?: string,
}
export const dateUserEMPTY:dateUser = {
  name: '',
  password: '',
  id: '',
  img:'',
  address: '',
  birthdate: '',
  identity: '',
  lastname:'',
  phone: '',
  registered: '',
  state: '',
  token: '',
  trash: '',
  username: '',
}