<div class="card border-0 p-3">
    <div class="d-flex ">
        <div class="col">
            <app-title [title]="stock.slotCard.slot" [class]="'fw-bolder'"></app-title>
            <app-title [title]="dateSlot.name" [fs]="'1 fw-bolder'" [colorText]="'info'"></app-title>
            <div class="d-flex">
                <div class="col">
                    <app-title [title]="stock.slotCard.products" [class]="'fw-bolder'"></app-title>
                    <app-title [title]="stock.slotCard.volumen" [class]="'fw-bolder'"></app-title>
                    <app-title [title]="stock.slotCard.date" [class]="'fw-bolder'"></app-title>
                </div>
                <div class="col ms-3">
                    <app-title [title]="dateSlot.appStock.length" [colorText]="'secondary fw-bolder'"></app-title>
                    <app-title [title]="dateSlot.dimensionDepth * dateSlot.dimensionHeight * dateSlot.dimensionWidth" [colorText]="'secondary fw-bolder'"></app-title>
                    <app-title [title]="dateSlot.quantity" [colorText]="'secondary fw-bolder'"></app-title>
                </div>            
            </div>
        </div>
        <div class="col">
            <app-slot [value]="dateSlot.productLevels.data"></app-slot>
        </div>
    </div>
</div>