<div class="card d-flex mb-3">
  <div class="d-flex align-items-center justify-content-between p-2">
    <div class="ps-3">
        <app-subtitle [title]="title" [colorText]="'secondary'"></app-subtitle>
    </div>
    <div class="">
      <input class="form-check-input color-white" type="checkbox" role="switch" id="flexSwitchCheckDefault" style="font-size: 1.5em;">  
    </div>    
  </div>
</div>

