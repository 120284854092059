<div class="card border-0 shadow-sm bg-white p-2 mb-3">
    <div>
        <app-title [title]="dateProduct.name" [class]="'fw-bolder'"></app-title>
    </div>
    <div class="d-flex mt-2">
        <div class="card-1 border rounded">
          <img src="{{dateProduct.image}}">
        </div>
        <div class="ps-3 pe-3">
            <app-title [title]="stock.subSlotCard.units"></app-title>
            <app-subtitle [title]="dateProduct.id"[colorText]="'info'"></app-subtitle>
        </div>
        <div class="border- pe-3">
            <app-title [title]="stock.subSlotCard.code"></app-title>
            <app-subtitle [title]="dateProduct.code" [colorText]="'info'"></app-subtitle>
        </div>
    </div>
</div>