<div class="card p-4">
  <div class="d-flex justify-content-between">
      <h5>{{title}} de productos</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <form id="idForm" [formGroup]="productForm">
    <div class="d-flex">
      <div class="col-5 p-3">
        <app-title [title]="'Foto del producto'" [colorText]="'secondary'"></app-title>
        <div class="card">
          <div class="text-center">
            <img class="border border-3 rounded p-2" [src]="image" width="200" height="200">
            <div class="input-group">
              <input type="file" class="form-control" id="inputGroupFile01" name="profile" (change)="onFileSelect($event)" style="display: none;">
              <label class="label button-blue d-flex align-items-center justify-content-center rounded-circle input-group-text position-absolute top-100 start-50 translate-middle p-0" for="inputGroupFile01">
                <img src="./assets/Trazado 23.png" class="button-img p-1 clickable" width="28">
              </label>
            </div>    
          </div>
              
        </div>
      
      </div>
      <div class="col p-3">
        <div class="d-flex align-items-center">
          <div class="form-group me-3">
            <label class="control-label text-secondary">Nombre del producto</label>
            <div class="input-group mb-3">
              <input class="form-control form-control-sm" type="text" formControlName="name">
            </div>         
          </div>
          <div class="form-group ms-3">
            <label class="control-label text-secondary">Unidad comercial</label>
            <div class="input-group mb-3">
              <input class="form-control form-control-sm" type="text" formControlName="quantity">
            </div>         
          </div>
        </div>
        <div class="d-flex mb-2">
          <div>
            <app-dropdown
            [list]="listProv"
            [nameDefault]="'Proveedores'"
            (searchIdItem)="AddIdProv($event)"
            [model]="modelProv">
            </app-dropdown>
          </div>
          <div>
            <app-dropdown
            [list]="listProv"
            [nameDefault]="'Categorias'"
            (searchIdItem)="AddIdCat($event)"
            [model]="modelCat">
            </app-dropdown>
          </div>
        </div>
        <app-title [title]="'Tamaño'" class="mt-3" [colorText]="'secondary'"></app-title>
        <div class="d-flex align-items-center">
          <div class="form-group me-3">
            <label class="control-label text-primary ms-4">Largo</label>
            <div class="input-group mb-3">
              <input class="form-control form-control-sm" type="text" formControlName="dimension_depth">
            </div>         
          </div>
          <div class="form-group ms-3">
            <label class="control-label text-primary ms-4">Ancho</label>
            <div class="input-group mb-3">
              <input class="form-control form-control-sm" type="text" formControlName="dimension_width">
            </div>         
          </div>
          <div class="form-group ms-3">
            <label class="control-label text-primary ms-4">Alto</label>
            <div class="input-group mb-3">
              <input class="form-control form-control-sm" type="text" formControlName="dimension_height">
            </div>         
          </div>
        </div>
      </div>
    </div>
  </form>
  <app-title [title]="'Tipo de empaque'" class="mt-3"></app-title>
  <button class="btn button-blue btn-sm col-3 mt-3">Nuevo empaque</button>
  <form [formGroup]="packingForm" class="row">
    <table class="table mt-3" >
      <tr class="d-flex">
        <app-item-table *ngFor="let item of head" [title]="item.title" [class]="item.col" class="bg-light"></app-item-table>
      </tr>
      <tr class="row" *ngFor="let Table of packages">
        <td class="col">
          <div class="input-group input-group-sm">
            <input class="form-control" value="" type="text" placeholder="Nombre del Empaque" formControlName="name">
          </div>                  
        </td>
        <td class="col">
          <div class="input-group input-group-sm">
            <input class="form-control" value="" type="text" formControlName="quantity">
          </div>                  
        </td>
        <td class="d-flex align-items-center border border-light col">
          <div>
            <button class="btn button-blue btn-sm ps-3 pe-3" type="button" (click)="openModalEdit(Table, Table.id)">Editar</button>
          </div>
          <div>
            <button class="btn button-red-strong btn-sm ps-3 pe-3" (click)="openAlert(Table.id)">Eliminar</button>
          </div>
        </td>
      </tr>
    </table>
</form>

  
  <div class="d-flex align-items-center justify-content-center mt-5">
      <button class="btn button-blue btn-sm" type="button" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
      <button class="btn button-red-strong btn-sm ms-3" (click)="newProduct()">Confirmar</button>
  </div>
</div>